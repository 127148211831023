/* eslint-disable complexity */
import { GROUP_BY_FILTERS, MULTI_FILTERS, RANGE_FILTERS } from "./constant";
import { isNestedFilter } from "./is-nested-filter";

const createFilters = (appliedFiltersKey, filters, source) => {
    const { category, filters: keyFilters } = appliedFiltersKey;
    const parentFilterData = filters.find((f) => {
        if (f.children.length) {
            return f.children.some((fc) => fc.category === category);
        }
        return f.category === category;
    });

    let filterData = null;

    if (parentFilterData) {
        if (parentFilterData.children.length) {
            filterData = parentFilterData.children.find((fc) => fc.category === category);
        } else {
            filterData = parentFilterData;
        }
    }

    if (!filterData) return null;

    const filterOptions = keyFilters.map(({ key, value, child }) => {
        // eslint-disable-next-line no-nested-ternary
        const optionValue = isNestedFilter(category, source) || MULTI_FILTERS.includes(category) || GROUP_BY_FILTERS.includes(category) ? value[0] : value;
        const preFilters = [
            {
                key,
                value: optionValue,
                operator: filterData.operator,
                isComplexFilter: !!filterData.conditionalOperator,
                ...(RANGE_FILTERS.includes(category) && { isRangeFilter: true })
            }
        ];

        let isAllSelected = false;
        const selectedModal = filterData.options.find((option) => option.filter.value === optionValue);

        if (child) {
            preFilters.push({
                ...child,
                parentValue: value[0],
                operator: filterData.subOperator
            });
            isAllSelected = child.value.length === selectedModal?.children.length;
        } else if (!child && isNestedFilter(category, source) && selectedModal) {
            const { filter: { key: subKey } } = selectedModal?.children?.[0];
            preFilters.push({
                key: subKey,
                value: selectedModal.children.map((subOption) => subOption.filter.value),
                parentValue: value[0],
                operator: filterData.subOperator
            });
            isAllSelected = true;
        }

        return { filters: preFilters, isAllSelected };
    });

    return {
        filterOptions,
        conditionalOperator: filterData.conditionalOperator
    };
};

export const getPreAppliedFilter = (appliedFilters, filters, source) => {
    return appliedFilters.reduce((acc, appliedFiltersKey) => {
        const filterData = createFilters(appliedFiltersKey, filters, source);
        if (filterData) {
            const { filterOptions, conditionalOperator } = filterData;
            acc[appliedFiltersKey.category] = {
                selectedFilters: filterOptions.filter(x => !!x),
                conditionalOperator
            };
        }
        return acc;
    }, {});
};
