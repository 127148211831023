import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";

class ReducerManager {
    constructor() {
        this.store = {};
        this.reducers = {};
        this.persistor = null;
        this.persistConfig = {
            storage,
            version: 1
        };
    }

    getFilteredState(initialState) {
        return Object.keys(this.reducers).reduce((prev, element) => {
            if (initialState[element]) {
                return {
                    ...prev,
                    [element]: initialState[element]
                };
            }
            return prev;
        }, {});
    }

    getReducersToInject(asyncReducers) {
        return Object.keys(asyncReducers).reduce((prev, element) => {
            if (!this.reducers[element]) {
                return {
                    ...prev,
                    [element]: asyncReducers[element]
                };
            }
            return prev;
        }, {});
    }

    injectReducer(asyncReducers = {}) {
        const reducerToInject = this.getReducersToInject(asyncReducers);
        if (typeof window === "object" && reducerToInject && Object.keys(reducerToInject).length) {
            Object.assign(this.reducers, reducerToInject);
            this.store.replaceReducer(persistReducer(this.persistConfig, combineReducers(this.reducers)));
            this.persistor.persist();
        }
    }

    configureStore ({baseReducer, initialState, enhancer, persistKey, whitelistedKeys, migration}) {
        this.reducers = {
            ...this.reducers,
            ...baseReducer
        };
        this.persistConfig = {
            ...this.persistConfig,
            key: persistKey,
            whitelist: whitelistedKeys,
            migrate: createMigrate(migration)
        };
        const persistedReducer = persistReducer(this.persistConfig, combineReducers(this.reducers));
        this.store = createStore(persistedReducer, this.getFilteredState(initialState), enhancer);
        this.persistor = persistStore(this.store, { manualPersist: true });
        return {
            store: this.store,
            persistor: this.persistor
        };
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new ReducerManager();
        }
        return this.instance;
    }
}

export const reducerManager = ReducerManager.getInstance();
