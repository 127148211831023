import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_CITIES_SUCCESS
    FETCH_CITIES_ERROR
    SEARCH_CITY
    SELECT_CITY
    SET_DATA
    UPDATE_C2C_PROMOTION_FLAG
    UPDATE_CREDR_FLAG
    SET_CITY_AVAILABILITY
`,
    {
        prefix: "cities/"
    }
);
