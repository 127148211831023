export default function seoContentParser(data) {
    if (!Object.keys(data).length) {
        return {};
    }
    const meta = {
        og: { title: data.og_title, description: data.og_descriptions },
        default: { title: data.page_title, description: data.meta_descriptions }
    };
    const genericContent = {
        heading: data.h2,
        subHeading: data.below_h1,
        content: data.content,
        imageContent: data.content1,
        secondaryContent: data.content2,
        jsonContent: data.jsonContent ? JSON.parse(data.jsonContent) : {}
    };
    const cityImage = { web: data.background_image, mobile: data.background_image_mobile };
    const mainHeading = data.h1;
    const templateType = data.template_type;
    const identifiers = {
        make: data.fk_make_id,
        model: data.fk_model_id, city: data.fk_city_id, location: data.fk_location_id
    };

    return {
        meta,
        genericContent,
        cityImage,
        mainHeading,
        templateType,
        identifiers
    };
}
