
export const reducerConstants = {
    RTO_INFO: "rtoInfo",
    REG_NO: "registrationNo",
    LEAD_DATA: "leadData",
    SELL_ONLINE_PRICE: "sellOnlinePrice",
    CONFIG: "config",
    USER: "user",
    EXPERIMENTS: "experiments",
    CITIES: "cities",
    FILTERS: "filters",
    LOCATION: "location",
    BUY_CAR_PINCODE_CONFIG: "buyCarPincodeConfig",
    CALLBACK_DETAILS: "callBackDetails",
    WISHLIST_LOGIN: "wishlistLogin",
    WISHLIST: "wishlist",
    CONSUMER_FINANCE: "consumerFinance",
    APPT_LOCATION_PICKER: "appointmentLocationPicker",
    LOCATION_PICKER: "locationPicker",
    CAR_LIST: "carList",
    RECENTLY_VIEWED: "recentlyViewed",
    CHAT_ICON: "chatIcon",
    META: "meta",
    SETTINGS: "settings",
    BRANDS: "brands",
    FOOTER: "footer",
    TESTIMONIALS: "testimonials",
    TOAST: "toast",
    BANK_OFFER_COUPONS: "bankOfferCoupons",
    EXPERTS: "experts",
    RATED_TESTIMONIALS: "ratedTestimonials",
    YEARS: "years",
    MODELS: "models",
    VARIANTS: "variants",
    REGISTRATION_STATES: "registrationStates",
    RTO: "leadRTO",
    SELL_ONLINE_OFFER_PRICE: "sellOnlinePrice",
    KILOMETERS_DRIVEN: "kilometersDriven",
    TRUECALLER: "truecaller",
    CF_POPUP: "cfPopup",
    BUY_USED_CAR_HOME: "buyUsedCarHome",
    NEED_HELP: "needHelpPopup",
    BUY_CAR_TESTIMONIAL: "buyCarTestimonials",
    CAR_DETAILS: "carDetails",
    CAR_COMPARE: "compareCars",
    HEADER: "header",
    CHECKOUT: "checkout",
    MY_BOOKING: "myBookings",
    APPOINTMENT_REVAMP_DETAILS: "appointmentRevampDetails",
    APPOINTMENT_LOCATION: "appointmentLocation",
    APPOINTMENT_DETAILS: "appointmentDetails",
    APPOINTMENT_RESCHEDULE: "reschedule",
    CONFIRMATION_DETAILS: "confirmationDetails",
    POST_INSPECTION_DIY: "postInspectionDiy",
    SIMILAR_CARS: "similarCars",
    REFER: "refer",
    SELL_USED_CAR_SEO: "sellUsedCar",
    RECENTLY_INSPECTED: "recentlyInspected",
    SELL_ONLINE_LEAD_DATA: "sellOnlineLeadData",
    KILOMETER_DRIVEN: "kilometersDriven",
    CONTENT_HOOK: "contentHook",
    FRANCHISE_FORM: "franchiseForm",
    LOAN_CALCULATOR: "loanCalculatorData",
    FASTER_CHECKOUT: "fasterCheckout",
    SELLER_WIDGET: "widgetData",
    DEAL_PAYMENT_INFO: "dealPaymentInfo",
    RC_TRANSFER_DETAILS: "rcTransferDetails",
    SUPPORT_DETAILS: "supportDetails",
    BUYER_SELLER_DETAILS_DATA: "buyerSellerDetailsData",
    FAQS: "faqs",
    INVESTORS: "investors",
    MY_BOOKING_SUMMARY: "myBookingSummary",
    SEO_MODELS: "seoModels",
    LOAN_FORM: "loanForm",
    FEATURED_CARS: "featuredCars",
    LEAD_PROGRESS_BAR: "leadProgressBar",
    POPULAR_CAR_LIST: "popularCarList",
    EXPLORE_USED_CARS: "exploreUsedCars",
    DSA: "dsa",
    REVIEW: "review",
    REVIEW_SUBMISSION: "reviewSubmission",
    SEO_TESTIMONIALS: "seoTestimonials",
    PARTNER_FORM: "partnerForm",
    LEGACY_LEAD_FORM: "legacyLeadForm",
    SCRIPT_LOADER: "scriptLoaderReducer",
    SEO_CAR_OVERVIEW: "seoCarOverview",
    REVIEW_DETAILS: "reviewDetails",
    NEARBY_CITIES: "nearbyCities",
    SELLER_LOCATION_PICKER: "sellerLocationPicker",
    SELL_ONLINE_CITIES: "sellOnlineCities",
    RECOMMENDED_CARS_FOR_YOU: "recommendedCarsForYou",
    E_CHALLAN: "eChallan",
    MY_CHALLANS: "myChallans",
    SCRIPTS_CONFIG: "scriptsConfig",
    SCRAP_CARS: "scrapCars",
    CAR_SERVICE_HISTORY_INFO: "carServiceHistoryInfo",
    MY_ORDER_SUMMARY: "myOrderSummary",
    FESTIVAL_OFFERS: "festivalOffers",
    FACEBOOK_LANDING: "facebookLanding",
    USER_DROP_WIDGET: "userDropWidget",
    REPEAT_USER_TRANSITION: "repeatUserTransition",
    INTERNAL_CITY_SEO: "internalCitySeo",
    APP_NUDGE: "appNudge",
    RTD_LISTENER: "rtdListener",
    PRE_DELIVERY_INSPECTION: "preDeliveryInspection",
    PRE_DELIVERY_INSPECTION_LEAD_DATA: "preDeliveryInspectionLeadData",
    FRANCHISE: "franchise",
    BEST_MATCHES_RAIL: "bestMatchesRail",
    BEST_MATCHES_FOR_YOU: "bestMatchesForYou",
    HOME_CATEGORY_PAGE: "homeCategoryPage",
    SUPER_APP: "superApp",
    LUXURY_CARS: "luxuryCars",
    CATEGORY_PAGE_TEMPLATES: "categoryPageTemplates",
    STATSIG_DYNAMIC_CONFIG: "statsigDynamicConfig",
    LOGIN_PANEL: "loginPanel",
    GET_APP_BOTTOMSHEET: "getAppBottomSheet",
    SF_LANDING: "sfLandingPageData",
    SELLER_OFFER: "sellerOffer",
    APP_NUDGE_FLOATING_BANNER: "appNudgeFloatingBanner",
    GA_SOURCE: "gaSource",
    PRE_PRICE_QUESTION: "prePriceQuestion",
    PL_FORM: "plFormData",
    AQI_DATA: "aqiData"
};
