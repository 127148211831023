import parseCookie from "./parse-cookie";

export const getCookiesValueObjectFromKeys = (keysArray) => {
    if (!keysArray?.length) {
        return {};
    }
    const result = {};
    keysArray.forEach(key => {
        result[key] = parseCookie(key);
    });
    return result;
};
