export const getGaEventDimensionObject = ({
    isLoggedIn,
    cityId,
    cityName,
    userId,
    pageURL = ""
}) => {
    return {
        ...(pageURL ? { dimension16: pageURL } : {}),
        dimension112: isLoggedIn ? "yes" : "no",
        dimension140: cityId ? cityId : "N/A",
        dimension144: cityName ? cityName : "N/A",
        dimension148: userId ? userId : "N/A"
    };
};
