import convertCharacter from "../helpers/convert-character";

export default (data) => {
    const _data = [];
    Object.keys(data.concernList).forEach(item => {
        _data.push({
            value: item,
            label: convertCharacter(data.concernList[item])
        });
    });
    return { ...data, list: _data };
};
