import { init, createReduxEnhancer } from "@sentry/react";
import { HTTP_CODES } from "../../constants/app-constants";

const _dsn = process.env.SENTRY_DSN_REACT;
const blockReduxActionTypes = [
    "configuration/GET_PROFILE_DETAILS_SUCCESS"
];

const API_STATUS_CODES = [
    HTTP_CODES.NOT_FOUND,
    HTTP_CODES.BAD_REQUEST,
    HTTP_CODES.UNAUTHORIZED
];

const IGNORE_APIS = [
    { endpoint: `appointment-widget`, status: API_STATUS_CODES },
    { endpoint: `rc-summary`, status: API_STATUS_CODES },
    { endpoint: `get-user-appointments`, status: API_STATUS_CODES },
    { endpoint: `otp-verify`, status: API_STATUS_CODES },
    { endpoint: `locations-by-city`, status: API_STATUS_CODES },
    { endpoint: `city-banner-list`, status: API_STATUS_CODES },
    { endpoint: `get-autosuggest`, status: API_STATUS_CODES },
    { endpoint: `pp/ums/users`, status: API_STATUS_CODES }
];

const IGNORE_THIRD_PARTY_FAILURE = [
    `DhPixel`,
    `gtag`,
    `ChunkLoadError`
];

const IGNORE_ERRORS = [
    "gtag",
    "DhPixel",
    "Loading chunk",
    "InvalidTokenError",
    "Invalid token specified",
    "unknown",
    "pause",
    "resume",
    "Illegal invocation",
    "@derivedConstructor",
    /Request failed with status code (400|401|404|0)/
];

const initializeSentry = () => {
    if (process.env.HOST_ENV === "PRODUCTION") {
        init({
            dsn: _dsn,
            environment: process.env.HOST_ENV,
            ignoreErrors: IGNORE_ERRORS,
            release: process.env.SENTRY_RELEASE,
            integrations: [],
            tracesSampleRate: 0.1,
            sampleRate: 0.1,
            beforeSend(event, hint) {
                const err = hint.originalException;
                if ((err && err.config && err.request && err.config.url &&
                IGNORE_APIS.filter(item => (err.config.url.indexOf(item.endpoint) >= 0 && item.status.filter(statusCode => err.request.status === statusCode).length) || (err.path && err.path.indexOf(item.endpoint) >= 0)).length)
                || err.errorCode) {
                    return null;
                }
                if (err && (err.stack && IGNORE_THIRD_PARTY_FAILURE.filter(item => err.stack.indexOf(item) >= 0).length) || (err.stack === undefined)) {
                    return null;
                }
                return event;
            }
        });
    }
};

const sentryReduxEnhancer = createReduxEnhancer({
    actionTransformer: action => {
        if (blockReduxActionTypes.includes(action.type)) {
            return null;
        }
        return action;
    },
    stateTransformer: state => {
        if (state.user.mobile || state.user.secureToken || (state.checkout && state.checkout.personalDetails)) {
            return null;
        }
        return state;
    }
});

export {
    initializeSentry,
    sentryReduxEnhancer
};
