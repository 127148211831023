import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const ListTab = ({
    className,
    data,
    labelKey,
    onClick,
    selectedKey = null,
    dataKey,
    color,
    refLabel = null,
    newCardActive = false,
    children,
    isRevamped = false
}) => {
    const getStyles = () => {
        let style = `styles.tab ${newCardActive ? "styles.newCard" : ""}`;
        if (color && data[dataKey] === selectedKey) {
            style = `${style} styles.${color} styles.active${color}`;
        } else if (data[dataKey] === selectedKey) {
            style = `${style} styles.active`;
        } else if (dataKey === selectedKey) {
            style = `${style} styles.active`;
        } else if (color) {
            style = `${style} styles.${color}`;
        }

        if (isRevamped && data.status === "NA") style = `${style} styles.disableTimeSlotForDesktop`;

        return style;
    };
    return (
        <div className={className} styleName={`styles.column`}>
            <div className={`card`} styleName={getStyles()} onClick={onClick} ref={refLabel}>
                {!children && data[labelKey]}
                {children && children}
            </div>
        </div>
    );
};

ListTab.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
    labelKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func.isRequired,
    selectedKey: PropTypes.any,
    color: PropTypes.string,
    children: PropTypes.string,
    dataKey: PropTypes.string.isRequired,
    refLabel: PropTypes.any,
    newCardActive: PropTypes.any,
    isRevamped: PropTypes.bool
};

export default ListTab;
