import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    modalStatus: false
};

export const setModalStatus = (state = INITIAL_STATE, { modalStatus }) => {
    return {
        ...state,
        modalStatus
    };
};

export const HANDLERS = {
    [Types.SET_MODAL_SOURCE]: setModalStatus
};

export default createReducer(INITIAL_STATE, HANDLERS);
