import { useEffect, useState } from "react";
import { loadClevertap } from "../components/configuration/dynamic-scripts";

const useCleverTap = () => {
    const [isCleverTapLoaded, setIsCleverTapLoaded] = useState(false);

    useEffect(() => {
        const loadAndSetStatus = () => {
            if (!window?.clevertap) {
                loadClevertap(() => setIsCleverTapLoaded(true)); return;
            }
            setIsCleverTapLoaded(false);
        };

        loadAndSetStatus();
    }, []);

    return isCleverTapLoaded;
};

export default useCleverTap;
