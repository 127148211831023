import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Configuration from "../../../components/configuration";
import { getBookingCount, getCfExperimentVariants, setCfUserData } from "../../../components/configuration/actions";
import { checkCfOffer } from "../../../components/desktop/interest-form/actions";

export default function withDesktopConfiguration(Component) {
    const WrapperComponent = (props) => {

        const {
            userId,
            secureToken,
            getBookingCountConnect = () => {},
            getCfExperimentVariantsConnect = () => {},
            checkCfOfferConnect = () => {},
            setCfUserDataConnect = () => {}
        } = props || {};

        useEffect(() => {
            const fetchUserCfData = async () => {
                try {
                    if (userId && secureToken) {
                        getBookingCountConnect(secureToken);
                        getCfExperimentVariantsConnect(userId);
                        const data = await checkCfOfferConnect(userId);
                        setCfUserDataConnect(data);
                    }

                } catch (_) {
                    //
                }
            };

            fetchUserCfData();
            /* eslint-disable react-hooks/exhaustive-deps */
        }, [userId, secureToken]);

        return (
            <Configuration type={"desktop"}>
                <Component {...props}/>
            </Configuration>
        );
    };

    WrapperComponent.propTypes = {
        userId: PropTypes.string,
        secureToken: PropTypes.string,
        getBookingCountConnect: PropTypes.func,
        getCfExperimentVariantsConnect: PropTypes.func,
        checkCfOfferConnect: PropTypes.func,
        setCfUserDataConnect: PropTypes.func
    };

    const mapStateToProps = ({
        user: {
            userId,
            secureToken
        }
    }) => ({
        userId,
        secureToken
    });

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        getBookingCountConnect: getBookingCount,
        getCfExperimentVariantsConnect: getCfExperimentVariants,
        checkCfOfferConnect: checkCfOffer,
        setCfUserDataConnect: setCfUserData
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(WrapperComponent);
}
