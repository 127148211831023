export default (name) => {
    if (typeof document === undefined || typeof document === "undefined") {
        return null;
    }
    const cookies = document.cookie.split(";");
    let returnValue = null;
    cookies.forEach(item => {
        const items = item.trim().split("=");
        if (items[0].includes(name) && items[0].length === name.length) {
            returnValue = item.split("=")[1];
        }
    });
    return returnValue;
};
