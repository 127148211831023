import { eventNames } from "./constants";
import leadCreateParser from "./parser/leads";
import appointmentParser from "./parser/appointment";
import confirmationParser from "./parser/confirmation";
import rescheduleParser from "./parser/reschedule";
import leadDetails from "./parser/lead-details";

export default {
    leadCreate: {
        name: eventNames.LEAD_CREATE,
        parser: leadCreateParser
    },
    leadEdit: {
        name: eventNames.LEAD_EDITED,
        parser: leadCreateParser
    },
    appointmentCreate: {
        name: eventNames.APPOINTMENT_BOOKED,
        parser: appointmentParser
    },
    appointmentEdit: {
        name: eventNames.APPOINTMENT_EDITED,
        parser: appointmentParser
    },
    appointmentConfirm: {
        name: eventNames.APPOINTMENT_CONFIRMED,
        parser: confirmationParser
    },
    appointmentReschedule: {
        name: eventNames.APPOINTMENT_RESCHEDULED,
        parser: rescheduleParser
    },
    leadCreateFromDetails: {
        name: eventNames.LEAD_CREATE,
        parser: leadDetails
    },
    leadEditedDetails: {
        name: eventNames.LEAD_EDITED,
        parser: leadDetails
    }
};
