import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

const catalogUserProfile = (api) => {
    const transferRecommendations = ({ token, clientId }) => {
        return api.post(`api/v1/login`, {}, {
            headers: {
                ...(token && {Authorization: `Bearer ${token}`}),
                ...(clientId && { clientId })
            }

        });
    };

    return {
        transferRecommendations
    };
};

export const CatalogUserProfileService = catalogUserProfile(middlewareConfig(MIDDLEWARE_ENUMS.CATALOG_USER_PROFILE));
