import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    UPDATE_FILTER_OPTION_DATA
    FETCH_FILTERS
    FETCH_FILTERS_SUCCESS
    FETCH_FILTERS_FAILURE
    UPDATE_SELECTED_ON_SCREEN_FILTER
    UPDATE_APPLIED_FILTERS
    UPDATE_SSR_APPLIED_FILTER
    CLEAR_APPLIED_FILTER_CATEGORY
    CLEAR_SELECTED_FILTER_CATEGORY
    COPY_APPLIED_TO_SELECTED_FILTER
    UPDATE_FILTER_SSR
    RESET_FILTERS
    SET_INDIA_PAGE
    SET_INDIA_PAGE_URL
    UPDATE_FILTER_TAB_TYPE
    UPDATE_FILTER_STATE
    SET_FETCHED_FILTER_CITY_ID
    CLEAR_BULK_FILTER
    UPDATE_SELECTED_FILTERS
    RESET_APPLIED_FILTERS
    UPDATE_FILTER_APPLIED_FROM_FILTER
`,
    {
        prefix: "filters/"
    }
);
