export const makeModalSelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_brand_filter",
    eventAction: "filter_page | brandModel | select"
};

export const KmsSelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_KM_filter",
    eventAction: "filter_page | KM Tab | select"
};

export const seatSelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_feature_filter",
    eventAction: "filter_page | seat | select"
};

export const featureSelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_feature_filter",
    eventAction: "filter_page | feature | select"
};

export const hubSelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_hub_filter",
    eventAction: "filter_page | Cars24_hub | select"
};

export const bodySelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_bodytype_filter",
    eventAction: "filter_page | bodyType | select"
};

export const rtoSelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_rto_filter",
    eventAction: "filter_page | registrationCity | select"
};

export const ownerSelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_owner_filter",
    eventAction: "filter_page | ownerNumber | select"
};

export const transmissionSelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_transmission_filter",
    eventAction: "filter_page | transmission | select"
};

export const fuelSelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_fueltype_filter",
    eventAction: "filter_page | fuelType | select"
};

export const yearSelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_year_filter",
    eventAction: "filter_page | Year Tab | select"
};

//-----------------DESKTOP: filter dropdown event---------------//

export const yearTabDropDown = {
    event: "TabFilter",
    eventCategory: "b2c_year_filter",
    eventAction: "filter_page | Year Tab | expand"
};
export const  kmTabDropDown = {
    event: "TabFilter",
    eventCategory: "b2c_KM_filter",
    eventAction: "filter_page | KM Tab | expand"
};
export const  fuelTabDropDown =  {
    event: "TabFilter",
    eventCategory: "b2c_fueltype_filter",
    eventAction: "filter_page | fuelType | expand"
};
export const  transmissionTabDropDown = {
    event: "TabFilter",
    eventCategory: "b2c_transmission_filter",
    eventAction: "filter_page | transmission | expand"
};
export const  BodyTabDropDown =  {
    event: "TabFilter",
    eventCategory: "b2c_bodytype_filter",
    eventAction: "filter_page | bodyType | expand"
};
export const  discountDropDown = {
    event: "TabFilter",
    eventCategory: "b2c_discount_filter",
    eventAction: "filter_page | Discount | expand"
};
export const  hubDropDown =  {
    event: "TabFilter",
    eventCategory: "b2c_hub_filter",
    eventAction: "filter_page | Cars24_hub | expand"
};
export const  ownerTabDropDown =  {
    event: "TabFilter",
    eventCategory: "b2c_owner_filter",
    eventAction: "filter_page | ownerNumber | expand"
};
export const  rtoTabDropDown = {
    event: "TabFilter",
    eventCategory: "b2c_rto_filter",
    eventAction: "filter_page | registrationCity | expand"
};
export const  featureDropDown = {
    event: "TabFilter",
    eventCategory: "b2c_feature_filter",
    eventAction: "filter_page | feature | expand"
};

export const colorSelectionOnClick = {
    event: "TabFilter",
    eventCategory: "b2c_colour_filter",
    eventAction: "filter_page | colour | select"
};
export const colorSelectionDropDown = {
    event: "TabFilter",
    eventCategory: "b2c_colour_filter",
    eventAction: "filter_page | colour | expand"
};
