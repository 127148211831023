/* eslint-disable max-statements */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import FlexColumnLayout from "../../shared/flex-column-layout/component";
import ListTab from "../../shared/list-tab";
import styles from "./styles.css";
import { sendCustomGaEvents } from "../../../tracking";
import { appUrl } from "../../../constants/url-constants";
import { NUMBER } from "../../../constants/app-constants";
import {InView} from "react-intersection-observer";
import { FILTER_CATEGORY } from "../../../utils/filters-v3/constant";
import { getGaEventDimensionObject } from "../../../utils/helpers/get-ga-event-object";
import sliderArrow from "./images/slider-arrow.svg";
import { onClickProgressFilter, progressFilterOnView } from "./tracking-constants";

const InterlinkingFilter = ({
    filterIndex,
    interLinkingUrls,
    isLoggedIn,
    cityId,
    cityName,
    userId,
    seo,
    appliedFilters

}) => {
    const [containerInView, setIsContainerInview] = useState(false);
    const [nextArrowDisabled, setNextArrowDisabled] = useState(false);
    const [prevArrowDisabled, setPrevArrowDisabled] = useState(true);

    const getCustomIndex = () => {
        let customIndex = filterIndex;
        const appliedMake = seo && appliedFilters[FILTER_CATEGORY.MAKE_MODEL];
        if (appliedMake) {
            const modal = appliedMake.selectedFilters[0]?.filters[0] && appliedMake.selectedFilters[0]?.isAllSelected;
            if (modal) {
                const popularModalIndex = interLinkingUrls.map(e => e.category).indexOf("POPULAR_MODELS");
                const popularBrandIndex = interLinkingUrls.map(e => e.category).indexOf("POPULAR_BRANDS");
                if (popularModalIndex > -1) {
                    if (filterIndex === popularBrandIndex) { customIndex = popularModalIndex; } else if (filterIndex === popularModalIndex) { customIndex = popularBrandIndex; } else { customIndex = filterIndex; }

                }
            }
        }
        return customIndex;
    };

    const filterItem = (interLinkingUrls && interLinkingUrls[getCustomIndex()]) || null;
    const filterList = filterItem ? filterItem.links : [];
    const sliderRef = useRef(null);
    const getUrl = (item) => {
        const url = `${appUrl()}/${item.url}`;
        return url;
    };
    const dimensionObject = getGaEventDimensionObject({
        isLoggedIn,
        cityId,
        cityName,
        userId
    });
    const onClickHandler = (item) => {
        window.location.href = getUrl(item);
        sendCustomGaEvents({
            ...onClickProgressFilter,
            eventLabel: `${filterItem.title}|${filterItem.heading}`,
            ...dimensionObject
        });
    };
    if (containerInView) {
        sendCustomGaEvents({
            ...progressFilterOnView,
            eventLabel: filterItem.heading,
            ...dimensionObject,
            nonInteraction: true
        });
    }

    const scroll = (scrollOffset) => {
        if (sliderRef && sliderRef.current) {
            sliderRef.current.scrollLeft += scrollOffset;
            setPrevArrowDisabled(!(sliderRef.current.scrollLeft));
            setNextArrowDisabled(sliderRef.current.scrollLeft >= (sliderRef.current.scrollWidth - sliderRef.current.offsetWidth));
        }
    };
    const onHorizontalScroll = () => {
        setPrevArrowDisabled(!(sliderRef.current.scrollLeft));
        setNextArrowDisabled(sliderRef.current.scrollLeft >= (sliderRef.current.scrollWidth - sliderRef.current.offsetWidth));
    };

    return (
        filterItem ?
            <InView as="div" styleName={"styles.listingFilter"}
                onChange={
                    (inView) => {
                        setIsContainerInview(inView);
                    }}
            >
                <div styleName={"styles.header"}>
                    <h2>{filterItem.heading}</h2>
                    {sliderRef && sliderRef.current && (sliderRef.current.offsetWidth !== sliderRef.current.scrollWidth) && <div styleName={"styles.sliderArrow"}>
                        <button onClick={() => scroll(-NUMBER.HUNDRED)} styleName={`styles.prev ${prevArrowDisabled ? "styles.disabled" : ""}`}>
                            <img src={sliderArrow} alt="Previous" />
                        </button>
                        <button styleName={`${nextArrowDisabled ? "styles.disabled" : ""}`} onClick={() => scroll(NUMBER.HUNDRED)}>
                            <img src={sliderArrow} alt="Next" />
                        </button>
                    </div>}
                </div>
                <div onScroll={onHorizontalScroll} ref={sliderRef} styleName={"styles.wrap"}>
                    <FlexColumnLayout type="start">
                        {
                            filterList.map((item, index) => {
                                return (
                                    <a key={`listTab_${index}`}
                                        href={getUrl(item)}
                                        title={item.title}
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <ListTab
                                            className="col-12"
                                            data={item}
                                            labelKey={"title"}
                                            onClick={() => onClickHandler(item)}
                                        />
                                    </a>
                                );
                            }
                            )
                        }
                    </FlexColumnLayout>
                </div>
            </InView> : <></>
    );
};

InterlinkingFilter.propTypes = {
    filterIndex: PropTypes.number,
    interLinkingUrls: PropTypes.array,
    cityName: PropTypes.string,
    reloadCarListConnect: PropTypes.func,
    history: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    cityId: PropTypes.bool,
    userId: PropTypes.bool,
    appliedFilters: PropTypes.object,
    seo: PropTypes.bool,
    setIndiaPageUrlConnect: PropTypes.func
};

export default InterlinkingFilter;
