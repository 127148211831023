import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        TOGGLE_LOCATION_PICKER
        TOGGLE_LOCATION_PICKER_V2
        SELECT_CITY
        LOCATION_SELECTETION_TYPE
        LOCATION_PAGE_TYPE
        IS_CTA_CLICKED_WITHOUT_PINCODE
    `,
    {
        prefix: "locationpickerpopup/"
    }
);
