import { usedCarLeadInfo, locationPicker, leadData, sellOnlineLeadData } from "./constants.desktop";

export const migrationsDesktop = {
    1: (state) => {
        const updatedLeadData = { ...state.leadData };
        delete updatedLeadData[undefined];
        return {
            ...state,
            leadData: {
                ...updatedLeadData,
                flowName: leadData.flowName
            },
            usedCarLeadInfo: {
                ...state.usedCarLeadInfo,
                isHomePageCtaFlow: usedCarLeadInfo.isHomePageCtaFlow
            }
        };
    },
    2: (state) => {
        return {
            ...state,
            usedCarLeadInfo: {
                ...state.usedCarLeadInfo,
                isHomePageCtaFlow: usedCarLeadInfo.isHomePageCtaFlow
            }
        };
    },
    3: (state) => {
        const fallBack = {
            id: "",
            displayText: ""
        };
        return {
            ...state,
            leadData: {
                ...state.leadData,
                mobile: leadData.mobile || fallBack
            }
        };
    },
    4: (state) => {
        return {
            ...state,
            sellOnlineLeadData: {
                ...state.sellOnlineLeadData,
                basicDetailCompleteWithLocation: sellOnlineLeadData.basicDetailCompleteWithLocation || {}
            }
        };
    },
    5: (state) => {
        return {
            ...state,
            locationPicker: {
                ...state.locationPicker,
                isCTAClickedWithoutPincode: locationPicker.isCTAClickedWithoutPincode
            }
        };
    },
    6: (state) => {
        return {
            ...state,
            leadData: {
                ...state.leadData,
                activateFirstStep: leadData.activateFirstStep,
                askUserIntent: leadData.askUserIntent,
                userIntent: leadData.userIntent,
                ownerName: leadData.ownerName,
                carRegistrationNo: leadData.carRegistrationNo,
                isAllStepFill: leadData.isAllStepFill,
                showWidget: leadData.showWidget,
                currentStep: leadData.currentStep
            }
        };
    }
};
