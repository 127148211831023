import React from "react";
import styles from "./styles.css";
import H2Regular from "../../mobile/h2-regular/component";
// import TransparentWhiteCard from "../transparent-white-card/component";
import PropTypes from "prop-types";

const AdCardContent = ({
    headingText,
    details,
    buttonText,
    smalltext,
    onClickCallback = () => { },
    icon,
    titleIcon
}) => {
    const Icon = icon;
    const TitleIcon = titleIcon;
    return (
        <div onClick={onClickCallback}>
            <div className={"headWrap"} styleName={"styles.headWrap"}>
                { titleIcon && <TitleIcon /> }
                <H2Regular isHtml={true} text={headingText} />
            </div>
            <p styleName={"styles.detail"}>
                {details}
            </p>
            <div className={"container"}>
                <div className={"row verticalAlign"} styleName="styles.verticalAlign">
                    <div className={"col-8"}>
                        { buttonText && <button>{buttonText}</button> }
                    </div>
                    {icon &&
                        <div className={"col-4"}>
                            <Icon/>
                        </div>
                    }
                </div>
                <p styleName={"styles.automatically"}>
                    {smalltext}
                </p>
            </div>
        </div>
    );
};

AdCardContent.propTypes = {
    headingText: PropTypes.string,
    details: PropTypes.string,
    buttonText: PropTypes.string,
    smalltext: PropTypes.string,
    onClickCallback: PropTypes.func,
    icon: PropTypes.func,
    titleIcon: PropTypes.func
};
export default AdCardContent;
