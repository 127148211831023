/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
const NeedCarLoanBanner = loadable(() => import("../need-car-loan-banner"));
const PreApprovedLoanBanner = loadable(() => import("../pre-approved-loan-banner"));
import { CF_STATUS } from "../../../constants/cf-constants";

const CfListingCard = ({
    preApproved,
    cfStatus
}) => {
    if (cfStatus === CF_STATUS.DISCARDED) return null;
    return (<React.Fragment>
        {(preApproved && cfStatus !== CF_STATUS.DISCARDED) ? <PreApprovedLoanBanner/> : <NeedCarLoanBanner />}
    </React.Fragment>);
};

CfListingCard.propTypes = {
    preApproved: PropTypes.bool,
    cfStatus: PropTypes.string
};

export default CfListingCard;
