export default (data) => {
    return data.map(city => {
        const {
            cms_uri: city_slug = "",
            title: city_name = ""
        } = city || {};
        return {
            city_slug,
            city_name,
            ...city
        };
    });
};
