import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        FETCH_FOOTER_DATA
        FETCH_FOOTER_DATA_SUCCESS
        FETCH_FOOTER_DATA_FAILURE
        UPDATE_SSR_STATUS
    `,
    {
        prefix: "footer/"
    }
);
