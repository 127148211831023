/* eslint-disable no-undef */
/* eslint-disable no-console */
import axios from "axios";
import { getC2bProductApiKey, getPrivateProductApiKey } from "../constants/url-constants";

const DEBUG = process.env.NODE_ENV === "development2";

let BASE_API_URL = "https://pvt-product.qac24svc.dev/";
switch (process.env.HOST_ENV) {
case "QA":
    BASE_API_URL = "https://pvt-product.qac24svc.dev";
    break;
case "LNOD":
    BASE_API_URL = "https://pvt-product-stage.cars24.team/";
    break;
case "STAGING":
    BASE_API_URL = "https://stage-pvt-product.qac24svc.dev/";
    break;
case "PRODUCTION":
    BASE_API_URL = "https://pvt-product.cars24.com/";
    break;
default: break;
}

/* Create axios instance */
const api = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        "Content-Type": "application/json",
        "x-api-key": getPrivateProductApiKey()
    },
    withCredentials: true
});

api.interceptors.request.use((config) => {
    /** In dev, intercepts request and logs it into console for dev */
    if (DEBUG) { console.info("✉️ ", config); }
    if (config.c2bApiKey) {
        config.headers["x-api-key"] = getC2bProductApiKey();
    }
    return config;
}, (error) => {
    if (DEBUG) { console.error("✉️ ", error); }
    return Promise.reject(error);
});

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use((response) => {
    if (DEBUG) { console.info("📩 ", response); }
    return response;
}, (error) => {
    if (error.response && error.response.data) {
        if (DEBUG) { console.error("Error: ", error.response.data); }
        return Promise.reject(error.response.data);
    }
    if (DEBUG) { console.error("📩 ", error); }
    return Promise.reject(error.message);
});

export default api;
