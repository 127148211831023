import { getDeviceType } from "../helpers/device-type";
import { MOBILE_NESTED_FILTERS, NESTED_FILTERS } from "./constant";

export const isNestedFilter = (key, source = null) => {
    if (NESTED_FILTERS.includes(key)) {
        return true;
    } else if ((getDeviceType().source === "mSite" || source === "mSite") && MOBILE_NESTED_FILTERS.includes(key)) {
        return true;
    } else {
        return false;
    }
};
