export const SORT_TYPES = {
    bestMatch: {
        items: {
            default: {
                label: "Best Match",
                payload: "P"
            }
        },
        isDesktop: true
    },
    // bestDeal: {
    //     items: {
    //         default: {
    //             label: "Best Deal First",
    //             payload: "BDHL"
    //         }
    //     },
    //     experiments: ["dealRating"]
    // },
    recentlyAdded: {
        items: {
            default: {
                label: "Recently Added",
                payload: "RAHL"
            }
        },
        isDesktop: true
    },
    distance: {
        items: {
            default: {
                label: "Near Me",
                payload: "D"
            }
        },
        isDesktop: false
    },
    price: {
        label: "Price",
        items: {
            lowToHigh: {
                label: "Low to High",
                payload: "LH"
            },
            highToLow: {
                label: "High to Low",
                payload: "HL"
            }
        },
        isDesktop: true
    },
    kms: {
        label: "Kms Driven",
        items: {
            lowToHigh: {
                label: "Low to High",
                payload: "KMLH"
            },
            highToLow: {
                label: "High to Low",
                payload: "KMHL"
            }
        },
        isDesktop: true
    },
    age: {
        label: "Car's age",
        items: {
            lowToHigh: {
                label: "New to Old",
                payload: "NWO"
            },
            highToLow: {
                label: "Old to New",
                payload: "ONW"
            }
        },
        isDesktop: true
    }
};

export const SORT_TYPES_V2 = {
    bestMatch: {
        items: {
            default: {
                label: "Best Match",
                payload: "bestmatch"
            }
        },
        isDesktop: true
    },
    // bestDeal: {
    //     items: {
    //         default: {
    //             label: "Best Deal First",
    //             payload: "BDHL"
    //         }
    //     },
    //     experiments: ["dealRating"]
    // },
    recentlyAdded: {
        items: {
            default: {
                label: "Recently Added",
                payload: "lhl"
            }
        },
        isDesktop: true
    },
    distance: {
        items: {
            default: {
                label: "Near Me",
                payload: "dist"
            }
        },
        isDesktop: false
    },
    price: {
        label: "Price",
        items: {
            lowToHigh: {
                label: "Low to High",
                payload: "plh"
            },
            highToLow: {
                label: "High to Low",
                payload: "phl"
            }
        },
        isDesktop: true
    },
    kms: {
        label: "Kms Driven",
        items: {
            lowToHigh: {
                label: "Low to High",
                payload: "olh"
            },
            highToLow: {
                label: "High to Low",
                payload: "ohl"
            }
        },
        isDesktop: true
    },
    age: {
        label: "Cars age",
        items: {
            lowToHigh: {
                label: "New to Old",
                payload: "alh"
            },
            highToLow: {
                label: "Old to New",
                payload: "ahl"
            }
        },
        isDesktop: true
    }
};

export const FILTER_TAG_SORT_V2 = {
    bestmatch: "Best Match",
    lhl: "Recently Added",
    plh: "Price: Low to High",
    phl: "Price: High to Low",
    olh: "KM: Low to High",
    ohl: "KM: High to Low",
    alh: "Age: New to Old",
    ahl: "Age: Old to New",
    dist: "Near Me"
};
