import { NAME_ERROR } from "../../../constants/cf-constants";

export const FORM_SOURCE = {
    LISTING_PAGE: "LISTING_PAGE",
    DETAIL_PAGE: "DETAIL_PAGE",
    HOME_PAGE: "HOME_PAGE",
    DEEPLINK: "DEEPLINK",
    DEALER: "Dealer",
    CARS24_HELP: "CARS24_HELP",
    LOAN_PAGE: "LOAN_PAGE",
    SUPER_APP_HOME: "SUPER_APP_HOME"
};

export const FORM_STEPS = {
    PERSONAL: "PERSONAL",
    ADDRESS: "ADDRESS",
    INCOME: "INCOME"
};

export const NAME_ERROR_TEXT = {
    [NAME_ERROR.NAME_FORMAT_ERROR]: "Please avoid using special characters and numbers",
    [NAME_ERROR.FULL_NAME_ERROR]: "Enter full name as per PAN"
};

export const HOUSE_TYPE = {
    OWNED: "OWNED",
    RENTED: "RENTED"
};

export const EMPLOYMENT_TYPE = {
    SALARIED: "SALARIED",
    SELF_EMPLOYED: "SELF_EMPLOYED"
};

