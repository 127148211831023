export const updateSimilarCarsContent = (state, updateSimilarCar, similarCars) => {
    if (updateSimilarCar) {
        if (similarCars && similarCars.content) {
            if (state.similarCars && state.similarCars.content) {
                return { ...similarCars, content: [...state.similarCars.content, ...similarCars.content], cities: {...state.similarCars.cities, ...similarCars.cities},  hubs: {...state.similarCars.hubs, ...similarCars.hubs} };
            }
        } else {
            return state.similarCars;
        }
    } else {
        return similarCars;
    }
    return "";
};
