import EventHandler from "eventhandler";

class CustomEventHandler {

    constructor() {
        this.eventHandler = new EventHandler();
    }

    emitEvent(eventName, data) {
        this.eventHandler.emit(eventName, data);
        return this;
    }

    subscribeEvent(eventName, callback) {
        this.eventHandler.on(eventName, (response) => {
            callback(response);
        });
    }

    removeListeners(key) {
        this.eventHandler.removeAllEventListeners(key);
    }

}

export default CustomEventHandler;
