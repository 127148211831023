
import { createReducer } from "../../../utils/redux/create-reducer";
import { stringConstants } from "../../../constants/app-constants";
import Types from "./types";

export const INITIAL_STATE = {
    isSSR: false,
    all: [],
    popular: [],
    error: null,
    isLoading: true
};

export const fetchDataSuccess = (state = INITIAL_STATE, payload) => {
    const { data: { all, popular, luxury = [] } } = payload;
    return { ...state, all, popular, luxury, error: null, isLoading: false };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, all: [], popular: [], isLoading: false };
};

export const updateLeadBrand = (state = INITIAL_STATE, { data }) => {
    const oldBrands = [...state.popular];
    const isNonPopularAlreadyExist = oldBrands.find(brand => brand.is_popular === "no");
    if (data.is_popular === stringConstants.YES) {
        if (isNonPopularAlreadyExist) {
            oldBrands.splice(0, 1);
        }
    } else {
        if (isNonPopularAlreadyExist) {
            oldBrands.splice(0, 1);
        }
        oldBrands.unshift(data);
    }
    return { ...state,  popular: oldBrands };
};
export const setIsLoading = (state = INITIAL_STATE, { isLoading }) => {
    return { ...state,  isLoading };
};
export const HANDLERS = {
    [Types.FETCH_LEAD_BRAND_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_LEAD_BRAND_FAILURE]: fetchDataFailure,
    [Types.UPDATE_LEAD_BRAND]: updateLeadBrand,
    [Types.SET_IS_LOADING]: setIsLoading

};

export default createReducer(INITIAL_STATE, HANDLERS);

