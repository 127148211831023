import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_META_TAGS_SEO
    FETCH_META_TAGS_SEO_SUCCESS
    FETCH_META_TAGS_SEO_FAILURE
    UPDATE_BG_WEB
    UPDATE_BG_MOBILE
    STORE_TWITTER_TESTIMONIALS
    STORE_SOCIAL_MEDIA_CONTENT
    UPDATE_SSR_STATUS
    SET_BANNER_IMAGE
    RESET_META
`,
    {
        prefix: "metatags/"
    }
);
