/* eslint-disable max-params */

import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

/* eslint-disable max-statements */
const usedCarApi = (api) => {

    const getGlobalCarsSearch = (params) => {
        return api.get("/global-cars-search", { params });
    };

    const fetchCars = (params, clientId) => {
        return api.get("/cars", {
            headers: {
                mediaSource: "HELLO_AR",
                ...(clientId && { clientId })
            },
            params
        });
    };

    const fetchCarsFromPathName = ({ clientId, payload, pincode, userId, lat, lng, source }) => {
        return api.get(`/${payload}`, {
            headers: {
                ...(pincode && { pincode }),
                ...(userId && { userId }),
                ...(lat && { lat }),
                ...(lng && { lng }),
                ...(source && { SOURCE: source }),
                ...(clientId && { clientId }),
                mediaSource: "HELLO_AR"
            }
        });
    };

    const fetchOtherHubsInCity = (cityId) => {
        return api.get(`/parking-yard/${cityId}`);
    };

    const fetchOtherYardCarsList = (params) => {
        return api.get(`/buy-used-car?${params}`);
    };

    // eslint-disable-next-line max-params

    const fetchDynamicFilters = (params) => {
        return api.get(`/filters`, { params });
    };

    const fetchDynamicFilterV2 = (params) => {
        return api.get(`/app-filters`, { params });
    };

    const fetchCarDetail = (carId, pincode, params, userId, clientId) => {
        return api.get(`/v4/cars/${carId}`, {
            headers: {
                ...(pincode && { pincode }),
                ...(userId && { userId }),
                mediaSource: "HELLO_AR",
                ...(clientId && { clientId })
            },
            params
        });
    };

    const fetchSimilarCarComparisonDetails = (appointmentId, similarCarAppointmentId, params = {}) => {
        return api.get(`/compare/cars/similar?appId=${appointmentId}&similarCarAppId=${similarCarAppointmentId}`, {
            headers: {
                mediaSource: "HELLO_AR"
            },
            params
        });
    };

    const getSimilarCarsCount = (appointmentId) => {
        return api.get(`v2/similar-cars/count/${appointmentId}`);
    };

    const fetchSimilarCars = (params, userId, pincode, clientId) => {
        return api.post("/similar-cars", params, {
            headers: {
                ...(clientId && { clientId }),
                mediaSource: "HELLO_AR",
                ...(userId && { userId }),
                ...(pincode && { pincode })
            }
        });
    };

    const fetchSimilarCarsById = (appointmentId, params) => {
        return api.get(`/similar-cars/${appointmentId}`, { params });
    };

    const submitRecentlyViewedCars = (params) => {
        return api.post("/recently-viewed-cars", params);
    };

    const getRecentlyViewedCars = (params, userId) => {
        return api.get("/recently-viewed-cars", {
            headers: {
                mediaSource: "HELLO_AR",
                ...(userId && { userId })
            },
            params
        });
    };

    const sendOTP = (params, queryString) => {
        return api.post(`/get-phone-verified${queryString ? `?${queryString}` : ""}`, params);
    };

    const verifyOtp = (params) => {
        return api.get("/verify-otp", { params });
    };

    const getPriceRange = (params) => {
        return api.get("/get-price-range", { params });
    };

    const submitUserOfferPrice = (params, queryString) => {
        return api.post(`/save-offer-price${queryString ? `?${queryString}` : ""}`, params);
    };

    const getTestDriveBranchLocation = (params) => {
        return api.get("/parking-yard/location", { params });
    };

    const getTestDriveBranchDirection = (params) => {
        return api.get("/parking-yard/direction", { params });
    };

    const hasOfferMade = (mobile, appointmentId) => {
        return api.get(`/isOfferExists/mobile/${mobile}/appId/${appointmentId}`);
    };

    const sendOTPForNoCars = (params, trackingParams) => {
        return api.post(`/no-result-found/send-otp${trackingParams ? `?${trackingParams}` : ""}`,
            params);
    };

    const verifyOTPForNoCars = (params, trackingParams) => {
        return api.post(`/no-result-found/verify-otp${trackingParams ? `?${trackingParams}` : ""}`,
            params);
    };

    const fetchCarCounts = (params, allInventory = false) => {
        return api.get(`inventory/count?${params}`, allInventory && {
            headers: { allInventory }
        });
    };

    const submitLeads = (params) => {
        return api.post(`/leads?${params}`);
    };

    const getWhatsappConsent = (mobileNumber) => {
        return api.get(`chat-flow/opt-in/status/${mobileNumber}`);
    };

    const saveWhatsappConsent = (params) => {
        return api.post(`chat-flow/opt-in/init`, params);
    };

    const fetchUserConsent = (mobileNumber) => {
        // return api.get(`chat-flow/opt-in/status/${mobileNumber}`);
        return api.get(`/user/${mobileNumber}/optIn/all`);
    };

    const saveUserConsent = (mobileNumber, params) => {
        return api.post(`/user/${mobileNumber}/optIn`, params);
    };

    const createBookNowLead = (data, params) => {
        return api.post(`inventory/book-now`, data, { params });
    };

    const bookNowSendOtp = (data, params) => {
        return api.put(`inventory/book-now/send-otp`, data, { params });
    };

    const bookNowVerifyOtp = (data, params) => {
        return api.put(`inventory/book-now/verify-otp`, data, { params });
    };

    const fetchListingInfo = (token) => {
        return api.get(`/seller/car/uniqueIdentifier/${token}`);
    };

    const fetchDelistReasons = (type) => {
        return api.get(`/seller/car/delistReason/${type}`);
    };

    const updateInventory = ({ hashCode, isInterested, remarks, statusCode, listingPrice }) => {
        return api.post("/inventory-delist", { ...{ identifier: hashCode, isInterested, ...(remarks && { remarks }), ...(statusCode && { statusCode }), ...(listingPrice && { listingPrice }) } });
    };

    const updateInventoryPrice = ({ hashCode, listingPrice }) => {
        return api.put(`/inventory/priceRevision/uniqueIdentifier/${hashCode}`, { listingPrice });
    };

    const submitVerifiedLead = (data, params) => {
        return api.post(`/verified-lead`, data, { params });
    };

    const fetchLoanCalculationData = (appointmentId) => {
        return api.get(`/car/${appointmentId}/emi-calculation-detail/?displayPreference=emi`);
    };

    const unsubscribeCommunication = (data) => {
        return api.post(`/user/unsubscribe`, data);
    };

    const sendWhatsappReport = (data) => {
        return api.post(`/inspection-report/send`, data);
    };

    const fetchCitiesInCoordinates = (params) => {
        return api.get("/geolocation", { params });
    };

    const submitBuyerIntent = (data) => {
        return api.post(`/user`, data);
    };

    const bookPayWallSendOtp = (data, params) => {
        return api.post(`/paywall/buynow/otp`, data, { params });
    };

    const bookPayWallVerifyOtp = (data, params) => {
        return api.put(`/paywall/buynow/otp/verify`, data, { params });
    };

    const fetchOfferScore = (mobile, appointmentId) => {
        return api.get(`/offer-profile/mobile/${mobile}/appId/${appointmentId}`);
    };

    const gsBookSendOtp = (data, params) => {
        return api.post(`/otp/send`, data, { params });
    };

    const gsBookVerifyOtp = (data, params) => {
        return api.put(`/otp/verify`, data, { params });
    };

    const getParkingYards = (appointmentId) => {
        return api.get(`/parking-yard/appointmentId/${appointmentId}`);
    };

    const getFinanceQuestions = () => {
        return api.get(`/simpler/questions`);
    };

    const submitFinanceAnswers = (data, params) => {
        return api.post(`/simpler/submit`, data, { params });
    };

    const cfOfferDetails = (mobile, source = "mSite") => {
        return api.get(`/simpler/cf-offer/${mobile}?source=${source}`);
    };

    const checkCfOffer = (mobile, source = "mSite") => {
        return api.get(`/simpler/has-filled-cf-form/${mobile}?source=${source}`);
    };

    const getNearByParkingYards = (data) => {
        return api.post(`/parking-yard/near-buyer`, data);
    };

    const fetchPdfLink = (carId) => {
        return api.get(`/inventory/${carId}`);
    };

    const fetchPincodeConfig = (pincode) => {
        return api.get(`/v1/ci/pincode/${pincode}`);
    };

    const fetchCarInspectionReport = (appId) => {
        return api.get(`v1/inspection-report/${appId}`);
    };

    const fetchBannerCarCounts = (pincode, allInventory = false) => {
        const url = `inventory/count${allInventory ? "" : `?pinId=${pincode}`}`;
        return api.get(url, allInventory && {
            headers: { allInventory }
        });
    };

    const fetchMoreCarsForYou = (appId) => {
        return api.get(`v1/similar/banner/${appId}`);
    };
    const getCarsSearch = (params) => {
        return api.get("v2/global-cars-search", { params });
    };

    const getNotifyme = (appointmentId, mobileNumber) => {
        return api.get(`/notifyme/status/${appointmentId}/${mobileNumber}`);
    };

    const postNotifyme = (params, clientId) => {
        return api.post("notifyme", params, {
            headers: {
                ...(
                    {
                        clientId: clientId || ""
                    }
                )
            }
        });
    };

    const getCarsDetailsByAppointmentId = (params) => {
        return api.get("/v3/cars", {
            params,
            headers: {
                mediaSource: "HELLO_AR"
            }
        });
    };

    const postUserDownloadLinkUrl = (mobile) => {
        return api.post(`send/app-link?mobile=${mobile}`);
    };

    const fetchBuyerAbTest = (userSelectedPinCode, userId, clientId) => {
        return api.get("buyer/abTest", {
            headers: {
                pincode: userSelectedPinCode,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                userId: userId || "",
                clientId: clientId || ""
            }
        });
    };

    const fetchCarComparisonDetails = ({ clientId, userId, appointmentIds, params = {} }) => {
        return api.get(`/compare/cars?appointmentIds=${appointmentIds}`, {
            headers: {
                mediaSource: "HELLO_AR",
                ...(userId && { userId }),
                ...(clientId && { clientId })
            },
            params
        });
    };

    const buyCarTestimonials = (params) => {
        return api.get("/testimonials", { params });
    };

    return {
        getGlobalCarsSearch,
        fetchCars,
        fetchDynamicFilters,
        fetchDynamicFilterV2,
        fetchCarDetail,
        getSimilarCarsCount,
        fetchSimilarCars,
        submitRecentlyViewedCars,
        getRecentlyViewedCars,
        sendOTP,
        verifyOtp,
        getPriceRange,
        submitUserOfferPrice,
        getTestDriveBranchLocation,
        hasOfferMade,
        sendOTPForNoCars,
        verifyOTPForNoCars,
        fetchCarsFromPathName,
        fetchCarCounts,
        submitLeads,
        getWhatsappConsent,
        saveWhatsappConsent,
        fetchUserConsent,
        saveUserConsent,
        createBookNowLead,
        bookNowSendOtp,
        bookNowVerifyOtp,
        fetchListingInfo,
        updateInventory,
        fetchDelistReasons,
        updateInventoryPrice,
        fetchSimilarCarsById,
        submitVerifiedLead,
        fetchLoanCalculationData,
        unsubscribeCommunication,
        sendWhatsappReport,
        fetchCitiesInCoordinates,
        submitBuyerIntent,
        bookPayWallSendOtp,
        bookPayWallVerifyOtp,
        fetchOfferScore,
        getTestDriveBranchDirection,
        gsBookSendOtp,
        gsBookVerifyOtp,
        getParkingYards,
        getFinanceQuestions,
        submitFinanceAnswers,
        cfOfferDetails,
        checkCfOffer,
        getNearByParkingYards,
        fetchPdfLink,
        fetchPincodeConfig,
        fetchCarInspectionReport,
        fetchBannerCarCounts,
        fetchMoreCarsForYou,
        getCarsSearch,
        getNotifyme,
        postNotifyme,
        getCarsDetailsByAppointmentId,
        postUserDownloadLinkUrl,
        fetchBuyerAbTest,
        fetchCarComparisonDetails,
        buyCarTestimonials,
        fetchSimilarCarComparisonDetails,
        fetchOtherYardCarsList,
        fetchOtherHubsInCity
    };
};

export const UsedCarService = usedCarApi(middlewareConfig(MIDDLEWARE_ENUMS.USED_CAR_CONST));
