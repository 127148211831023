/* eslint-disable camelcase */
import Types from "./types";
import { createReducer } from "../../utils/redux/create-reducer";
// import { findByKey } from "../../utils/helpers/find-by-key";
// import { VIRUTAL_CITY } from "../../constants/app-constants";
import { PAYMENT_MODES } from "../../constants/payment-constants";
import { cityParser } from "../../utils/helpers/city-parser";

// const addVirtualCity = (cities, c2cConfig) => {
//     const selection = findByKey(cities, "city_id", VIRUTAL_CITY.isReplicaOfCity);
//     return [
//         ...cities,
//         {
//             ...selection,
//             city_id: VIRUTAL_CITY.city_id,
//             city_name: VIRUTAL_CITY.city_name,
//             city_slug: VIRUTAL_CITY.city_slug,
//             is_virtual_city: true,
//             ...(c2cConfig && { c2cConfig })
//         }
//     ];
// };

export const INITIAL_STATE = {
    map: "googlemap",
    cityList: [],
    storeCount: 206,
    cityCount: 75,
    currentHighlightedABTestTab: "sell",
    paymentGateway: PAYMENT_MODES.PAYTM,
    newTab: null,
    firstClickPath: null,
    activeHeaderTab: "",
    offlineTrasactionCityIds: []
};

export const setConfig = (state = INITIAL_STATE, {
    config,
    newTab,
    storeCount,
    cityCount,
    t0_homepage_config,
    paymentGateway,
    sellerFinancingCities,
    serviceHistoryRefSourceList,
    offlineTrasactionCityIds
}) => {
    return {
        ...state,
        ...config,
        storeCount,
        newTab,
        cityCount,
        currentHighlightedABTestTab: t0_homepage_config,
        paymentGateway,
        sellerFinancingCities: sellerFinancingCities?.split(","),
        serviceHistoryRefSourceList,
        offlineTrasactionCityIds: offlineTrasactionCityIds && offlineTrasactionCityIds.split(",")
    };
};

export const updateCityListSuccess = (state = INITIAL_STATE, { cityList }) => {
    return { ...state, cityList: cityParser(cityList) };
};

export const updateCityListFailure = (state = INITIAL_STATE) => {
    return { ...state, cityList: [] };
};

export const HANDLERS = {
    [Types.SET_CONFIG]: setConfig,
    [Types.UPDATE_CITY_LIST_SUCCESS]: updateCityListSuccess,
    [Types.UPDATE_CITY_LIST_FAILURE]: updateCityListFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
