import Types from "./types";

const setExperiment = (experimentType, experimentData, flattened) => ({
    type: Types.SET_EXPERIMENT,
    experimentType,
    experimentData,
    flattened
});

const setBanners = (experimentType, city, banners) => ({
    type: Types.SET_BANNERS,
    experimentType,
    city,
    banners
});

const setAlternateSellFlow = (alternateSellCarFlow) => ({
    type: Types.SET_ALTERNATE_SELL_CAR_FLOW,
    alternateSellCarFlow
});

const setAlternateRTOFlow = (alternateRTOFlow) => ({
    type: Types.SET_ALTERNATE_RTO_FLOW,
    alternateRTOFlow
});

const updateStatsigConfig = ({statsigInitialized, statsigClientIdUpdated, statsigCallbackMsg}) => ({
    type: Types.UPDATE_STATSIG_CONFIG,
    statsigInitialized,
    statsigClientIdUpdated,
    statsigCallbackMsg
});

const clearAllExperiments = () => ({
    type: Types.CLEAR_ALL_EXPERIMENTS
});

const updateStatsigTimeStamp = () => ({
    type: Types.UPDATE_STATSIG_TIMESTAMP
});

export {
    setExperiment,
    setBanners,
    setAlternateSellFlow,
    setAlternateRTOFlow,
    updateStatsigConfig,
    clearAllExperiments,
    updateStatsigTimeStamp
};
