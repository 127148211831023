const allowedCategories = ["POPULAR_BRANDS", "POPULAR_BODY_TYPE", "POPULAR_FUEL_TYPE", "POPULAR_BUDGET", "POPULAR_MODELS"];
export const getOrderWisePopularLinks = (links = []) => {
    const filteredLinks = links.filter((link) => allowedCategories.includes(link.category));
    const sortedOrderOfLinks = filteredLinks.sort((a, b) => {
        const indexA = allowedCategories.indexOf(a.category);
        const indexB = allowedCategories.indexOf(b.category);
        return indexA - indexB;
    });
    return sortedOrderOfLinks;

};
