import { createReducer } from "../../../utils/redux/create-reducer";
// import storage from "redux-persist/lib/storage";
// import { persistReducer } from "redux-persist";
import Types from "./types";

export const INITIAL_STATE = {
    popularCars: [],
    carModelsByBrands: {},
    error: null,
    isSSR: false,
    popularBudgets: [],
    popularBrands: [],
    recentCarsList: {
        data: [],
        baseUrl: "",
        cities: {},
        hubs: {}
    },
    offers: null,
    showSelectedBankOffer: {
        isOpen: false,
        offerData: "",
        pageType: ""
    },
    motivationStories: [],
    homePageBanners: [],
    isSRRHomePageBanner: false,
    showPopularCitiesOnHomePage: false,
    isLoading: false,
    isError: false,
    isUsedCarHomeTemplateSSR: false,
    usedCarHomeTemplateData: [],
    cmsData: {},
    isCmsDataError: false
};

// const persistConfig = {
//     key: "showPopularCitiesDesktop",
//     storage,
//     whitelist: ["showPopularCitiesOnHomePage"]
// };

export const fetchBuyUsedCarModelsByBrands = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

export const fetchBuyUsedCarModelsByBrandsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        carModelsByBrands: data,
        isLoading: false
    };
};

export const fetchBuyUsedCarModelsByBrandsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error
    };
};

export const fetchMotivationalStories = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const fetchMotivationalStoriesSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        motivationStories: data.tweets ? data.tweets : [],
        isLoading: false
    };
};

export const fetchMotivationalStoriesFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error,
        isLoading: false
    };
};

export const fetchPresetFiltersSuccess = (state = INITIAL_STATE, { data }) => {
    const {
        popularCars,
        trendingSearch,
        popularBrands,
        curatedPicks,
        fuelType,
        transmission,
        bodyType,
        popularBudgets
    } = data;
    return {
        ...state,
        popularCars,
        trendingSearch,
        popularBrands,
        curatedPicks,
        fuelType,
        transmission,
        bodyType,
        popularBudgets
    };
};

export const fetchRecentCarsListFailure = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

export const fetchRecentCarsList = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

export const fetchRecentCarsListSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        recentCarsList: {
            data: data.content,
            baseUrl: data.baseUrl,
            cities: data.cities,
            hubs: data.hubs
        }
    };
};

export const fetchBankOfferCouponsSuccess = (state = INITIAL_STATE, { offers }) => {
    return {
        ...state,
        offers,
        error: null
    };
};

export const fetchBankOfferCouponsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        offers: null,
        error
    };
};

export const setShowSelectBankOffer = (state = INITIAL_STATE, { showSelectedBankOfferData }) => {
    return {
        ...state,
        showSelectedBankOffer: {
            ...state.showSelectedBankOffer,
            ...showSelectedBankOfferData
        }
    };
};

export const setShowPopularCitiesOnHomepage = (state = INITIAL_STATE, { value }) => {
    return { ...state, showPopularCitiesOnHomePage: value };
};

export const updateHomePageSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const fetchHomePageBanners = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

export const fetchHomePageBannersSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        homePageBanners: data
    };
};

export const updateHomePageBannersSSR = (state = INITIAL_STATE, { isSSR }) => {
    return {
        ...state,
        isSRRHomePageBanner: isSSR
    };
};

export const fetchHomePageBannersFailure = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

export const fetchBuyUsedCarHomeTemplate = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true,
    isError: false
});

export const fetchBuyUsedCarHomeTemplateSuccess = (state = INITIAL_STATE, {data}) => ({
    ...state,
    isLoading: false,
    isError: false,
    usedCarHomeTemplateData: [...data]
});

export const fetchBuyUsedCarHomeTemplateFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    isError: true,
    usedCarHomeTemplateData: []
});

export const updateUsedCarHomeTemplateSSRStatus = (state = INITIAL_STATE, { flag}) => ({
    ...state,
    isUsedCarHomeTemplateSSR: flag
});

export const setBannerPopupContent = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        bannerContent: {
            ...state.bannerContent,
            ...data
        }
    };
};

const setCMSData = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        cmsData: {...data}
    };
};

const setCmsDataError = (state = INITIAL_STATE) => ({
    ...state,
    cmsData: {},
    isCmsDataError: true
});

export const HANDLERS = {
    [Types.FETCH_BUY_USED_CAR_MODELS_BY_BRANDS_FAILURE]: fetchBuyUsedCarModelsByBrandsFailure,
    [Types.FETCH_BUY_USED_CAR_MODELS_BY_BRANDS_SUCCESS]: fetchBuyUsedCarModelsByBrandsSuccess,
    [Types.FETCH_BUY_USED_CAR_MODELS_BY_BRANDS]: fetchBuyUsedCarModelsByBrands,
    [Types.FETCH_PRESET_FILTERS_SUCCESS]: fetchPresetFiltersSuccess,
    [Types.FETCH_RECENT_CARS_LIST]: fetchRecentCarsList,
    [Types.FETCH_RECENT_CARS_LIST_SUCCESS]: fetchRecentCarsListSuccess,
    [Types.FETCH_RECENT_CARS_LIST_FAILURE]: fetchRecentCarsListFailure,
    [Types.FETCH_BANK_OFFER_COUPONS_SUCCESS]: fetchBankOfferCouponsSuccess,
    [Types.FETCH_BANK_OFFER_COUPONS_FAILURE]: fetchBankOfferCouponsFailure,
    [Types.SET_SHOW_SELECTED_BANK_OFFER]: setShowSelectBankOffer,
    [Types.SET_SHOW_POPULAR_CITIES_ON_HOMEPAGE]: setShowPopularCitiesOnHomepage,
    [Types.FETCH_MOTIVATIONAL_STORIES_FAILURE]: fetchMotivationalStoriesFailure,
    [Types.FETCH_MOTIVATIONAL_STORIES_SUCCESS]: fetchMotivationalStoriesSuccess,
    [Types.FETCH_MOTIVATIONAL_STORIES]: fetchMotivationalStories,
    [Types.FETCH_HOME_PAGE_BANNERS_FAILURE]: fetchHomePageBannersFailure,
    [Types.FETCH_HOME_PAGE_BANNERS_SUCCESS]: fetchHomePageBannersSuccess,
    [Types.FETCH_HOME_PAGE_BANNERS]: fetchHomePageBanners,
    [Types.UPDATE_SSR_STATUS]: updateHomePageSSRStatus,
    [Types.UPDATE_HOME_PAGE_BANNERS_SSR]: updateHomePageBannersSSR,
    [Types.FETCH_BUY_USED_CAR_HOME_TEMPLATE]: fetchBuyUsedCarHomeTemplate,
    [Types.FETCH_BUY_USED_CAR_HOME_TEMPLATE_SUCCESS]: fetchBuyUsedCarHomeTemplateSuccess,
    [Types.FETCH_BUY_USED_CAR_HOME_TEMPLATE_FAILURE]: fetchBuyUsedCarHomeTemplateFailure,
    [Types.UPDATE_SSR]: updateUsedCarHomeTemplateSSRStatus,
    [Types.SET_BANNER_POPUP_CONTENT]: setBannerPopupContent,
    [Types.SET_CMS_DATA]: setCMSData,
    [Types.SET_CMS_DATA_ERROR]: setCmsDataError
};

// export default persistReducer(persistConfig, createReducer(INITIAL_STATE, HANDLERS));
export default createReducer(INITIAL_STATE, HANDLERS);
