import React from "react";
import PropTypes from "prop-types";
import OrangeCard from "../../shared/orange-card";
import AdCardContent from "../../shared/ad-card-content/component";
import AdRupeeIcon from "../../shared/ad-rupee-icon";
import IconThunder from "../../shared/icon-thunder";
import { CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";
import { sendCustomGaEvents } from "../../../tracking";
import { adInstantLoan } from "./tracking-constants";

const AdFinanceAvailable = ({
    addAndApplyFilterConnect,
    changeListNameConnect
}) => {

    const filterValue = {
        displayText: "Finance Available",
        value: true
    };
    const onClickHandler = () => {
        addAndApplyFilterConnect({ key: "isCFEnabled", value: filterValue });
        changeListNameConnect(CAR_LIST_EVENT_NAMES.FINANCE_AVAILABLE);
        window.scrollTo(0, 0);

        sendCustomGaEvents(adInstantLoan);
    };

    return (
        <OrangeCard>
            <AdCardContent
                onClickCallback={onClickHandler}
                headingText="GET ON SPOT LOAN APPROVAL"
                details="Enjoy the most flexible financing
                options for used cars! Get same day loan approval
                and complete paperwork assistance."
                buttonText="VIEW ALL CARS"
                smalltext="Filter will be applied automatically"
                icon={AdRupeeIcon}
                titleIcon={IconThunder}
            />
        </OrangeCard>
    );
};

AdFinanceAvailable.propTypes = {
    addAndApplyFilterConnect: PropTypes.func,
    changeListNameConnect: PropTypes.func
};

export default AdFinanceAvailable;
