import React from "react";

const IconSpinner = () => {
    return (
        <div>
            <i className="spinner spinnerOrange" />
        </div>
    );
};

export default IconSpinner;
