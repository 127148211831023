import { createReducer } from "../../../utils/redux/create-reducer";
import parseNearbyCity from "../../../utils/helpers/parse-nearby-city";
import Types from "./types";

export const INITIAL_STATE = {
    isServiceable: true,
    isServiceableLoading: false,
    isNearbyCityLoading: false,
    isNearByCityAvailable: false,
    nearbyCarsList: [],
    isSSrServiceable: false,
    error: null,
    selectedCity: "",
    selectedNearbyCity: {}
};

export const getServiceabilityCheckFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        error,
        isServiceableLoading: false
    };
};

export const getServiceabilityCheck = (state = INITIAL_STATE) => {
    return {
        ...state,
        isSSrServiceable: false,
        isServiceableLoading: true
    };
};

export const getServiceabilityCheckSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isServiceable: data.serviceable,
        selectedCity: data.city,
        isNearByCityAvailable: data.serviceable ? false : state.isNearByCityAvailable,
        nearbyCarsList: data.serviceable ? [] : state.nearbyCarsList,
        selectedNearbyCity: data.serviceable ? {} : state.selectedNearbyCity,
        isServiceableLoading: false
    };
};
export const fetchNearByCarsListFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        error,
        isNearbyCityLoading: false
    };
};

export const fetchNearByCarsList = (state = INITIAL_STATE) => {
    return {
        ...state,
        isNearbyCityLoading: true
    };
};

export const fetchNearByCarsListSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        nearbyCarsList: data.nearbyCities,
        isNearByCityAvailable: data.nearbyCities.length > 0,
        selectedNearbyCity: data && data.nearbyCities && data.nearbyCities.length && parseNearbyCity(data.nearbyCities[0]),
        isNearbyCityLoading: false
    };
};
export const setSelectedNearByCity = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedNearbyCity: parseNearbyCity(data)
    };
};
export const setIsServiceableLoading = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        isServiceableLoading: data.isServiceableLoading
    };
};
export const updateSSRStatus = (state = INITIAL_STATE, { isSSrServiceable }) => {
    return { ...state, isSSrServiceable };
};

export const resetNearByCitiesData = () => (INITIAL_STATE);

export const HANDLERS = {
    [Types.FETCH_SERVICEABILITY_CHECK]: getServiceabilityCheck,
    [Types.FETCH_SERVICEABILITY_CHECK_SUCCESS]: getServiceabilityCheckSuccess,
    [Types.FETCH_SERVICEABILITY_CHECK_FAILURE]: getServiceabilityCheckFailure,
    [Types.FETCH_NEAR_BY_CARS_LIST]: fetchNearByCarsList,
    [Types.FETCH_NEAR_BY_CARS_LIST_SUCCESS]: fetchNearByCarsListSuccess,
    [Types.FETCH_NEAR_BY_CARS_LIST_FAILURE]: fetchNearByCarsListFailure,
    [Types.SET_SELECTED_NEAR_BY_CITY]: setSelectedNearByCity,
    [Types.SET_IS_SERVICEABLE_LOADING]: setIsServiceableLoading,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.RESET_NEAR_BY_CITIES]: resetNearByCitiesData

};

export default createReducer(INITIAL_STATE, HANDLERS);
