import {
    makeModalSelectionOnClick,
    KmsSelectionOnClick,
    seatSelectionOnClick,
    featureSelectionOnClick,
    hubSelectionOnClick,
    bodySelectionOnClick,
    rtoSelectionOnClick,
    ownerSelectionOnClick,
    transmissionSelectionOnClick,
    fuelSelectionOnClick,
    yearSelectionOnClick,
    featureDropDown,
    discountDropDown,
    hubDropDown,
    BodyTabDropDown,
    rtoTabDropDown,
    ownerTabDropDown,
    transmissionTabDropDown,
    fuelTabDropDown,
    yearTabDropDown,
    kmTabDropDown,
    colorSelectionOnClick,
    colorSelectionDropDown
} from "../../constants/tracking-constants/ga-key-name";

export const VIEW_TYPE = {
    CHECKED: "CHECKED",
    SLIDER: "SLIDER",
    RADIO: "RADIO",
    SORT: "SORT",
    NESTED_BLOCK: "NESTED_BLOCK",
    MULTI_WIDGET: "MULTI_WIDGET"
};

export const FILTER_CATEGORY = {
    BUDGET: "BUDGET",
    YEAR: "YEAR",
    ODOMETER: "ODOMETER",
    DISCOUNT: "SLOT_DISCOUNT",
    MAKE_MODEL: "MAKE_MODEL",
    RTO: "CAR_RTO",
    OWNERS: "OWNERS",
    BODY_TYPE: "BODY_TYPE",
    FUEL_TYPE: "FUEL",
    TRANSMISSION: "TRANSMISSION",
    CARS24HUB: "CARS24HUB",
    FEATURES: "FEATURES",
    SEATS: "SEATS",
    COLOR: "COLOR",
    SORT: "SORT",
    SAFETY: "SAFETY",
    SAFETY_FEATURES: "SAFETY_FEATURES",
    FEATURES_V2: "FEATURES_V2"
};

export const COMPLEX_FILTERS = [
    FILTER_CATEGORY.DISCOUNT
];

export const NESTED_FILTERS = [
    FILTER_CATEGORY.MAKE_MODEL,
    FILTER_CATEGORY.RTO
];

export const MOBILE_NESTED_FILTERS = [
    FILTER_CATEGORY.TRANSMISSION
];

export const RANGE_FILTERS = [
    FILTER_CATEGORY.BUDGET,
    FILTER_CATEGORY.YEAR,
    FILTER_CATEGORY.ODOMETER
];

export const MULTI_FILTERS = [
    FILTER_CATEGORY.SAFETY,
    FILTER_CATEGORY.SAFETY_FEATURES
];

export const NESTED_BLOCK_FILTERS = [
    FILTER_CATEGORY.TRANSMISSION
];

export const GROUP_BY_FILTERS = [
    FILTER_CATEGORY.FEATURES_V2
];

export const FILTER_OPERATOR = {
    EQUAL_TO: ":=:",
    IN: ":in:",
    OR: ":OR:",
    NIN: ":nin:"
};
export const EVENT_KEY_MAP = {
    [FILTER_CATEGORY.MAKE_MODEL]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_brand_filter",
        eventAction: "filter_page | brandModel | open"
    },
    [FILTER_CATEGORY.BUDGET]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_price_filter",
        eventAction: "filter_page | Price Tab | open"
    },
    [FILTER_CATEGORY.FUEL_TYPE]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_fueltype_filter",
        eventAction: "filter_page | fuelType | open"
    },
    [FILTER_CATEGORY.YEAR]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_year_filter",
        eventAction: "filter_page | Year Tab | open"
    },
    [FILTER_CATEGORY.RTO]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_rto_filter",
        eventAction: "filter_page | registrationCity | open"
    },
    [FILTER_CATEGORY.BODY_TYPE]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_bodytype_filter",
        eventAction: "filter_page | bodyType | open"
    },
    [FILTER_CATEGORY.TRANSMISSION]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_transmission_filter",
        eventAction: "filter_page | transmission | open"
    },
    [FILTER_CATEGORY.OWNERS]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_owner_filter",
        eventAction: "filter_page | ownerNumber | open"
    },
    [FILTER_CATEGORY.ODOMETER]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_KM_filter",
        eventAction: "filter_page | KM Tab | open"
    },
    [FILTER_CATEGORY.CARS24HUB]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_hub_filter",
        eventAction: "filter_page | Cars24_hub | open"
    },
    [FILTER_CATEGORY.DISCOUNT]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_discount_filter",
        eventAction: "filter_page | DiscountTab | open"
    },
    [FILTER_CATEGORY.FEATURES]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_feature_filter",
        eventAction: "filter_page | feature | open"
    },
    [FILTER_CATEGORY.FEATURES_V2]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_feature_filter",
        eventAction: "filter_page | feature | open"
    },
    [FILTER_CATEGORY.SEATS]: {
        event: "TabFilter",
        eventLabel: "dropdown",
        eventCategory: "b2c_feature_filter",
        eventAction: "filter_page | seat | open"
    },
    [FILTER_CATEGORY.COLOR]: {
        event: "TabFilter",
        eventCategory: "b2c_colour_filter",
        eventAction: "filter_page | colour | open"
    },
    [FILTER_CATEGORY.SORT]: {
        event: "TabFilter",
        eventCategory: "b2c_sort_filter",
        eventAction: "filter_page | sort | open"
    }
};

export const DIMENSION_MAP_V2 = {
    [FILTER_CATEGORY.BUDGET]: "dimension65",
    [FILTER_CATEGORY.MAKE_MODEL]: "dimension67",
    [FILTER_CATEGORY.ODOMETER]: "dimension68",
    [FILTER_CATEGORY.YEAR]: "dimension69",
    [FILTER_CATEGORY.FUEL_TYPE]: "dimension70",
    [FILTER_CATEGORY.TRANSMISSION]: "dimension71",
    [FILTER_CATEGORY.OWNERS]: "dimension72",
    [FILTER_CATEGORY.RTO]: "dimension73",
    [FILTER_CATEGORY.BODY_TYPE]: "dimension83",
    [FILTER_CATEGORY.FEATURES]: "dimension160",
    [FILTER_CATEGORY.FEATURES_V2]: "dimension160"
};

export const GA_KEY_NAME = {
    [FILTER_CATEGORY.MAKE_MODEL]: makeModalSelectionOnClick,
    [FILTER_CATEGORY.ODOMETER]: KmsSelectionOnClick,
    [FILTER_CATEGORY.YEAR]: yearSelectionOnClick,
    [FILTER_CATEGORY.FUEL_TYPE]: fuelSelectionOnClick,
    [FILTER_CATEGORY.TRANSMISSION]: transmissionSelectionOnClick,
    [FILTER_CATEGORY.OWNERS]: ownerSelectionOnClick,
    [FILTER_CATEGORY.RTO]: rtoSelectionOnClick,
    [FILTER_CATEGORY.BODY_TYPE]: bodySelectionOnClick,
    [FILTER_CATEGORY.CARS24HUB]: hubSelectionOnClick,
    [FILTER_CATEGORY.FEATURES]: featureSelectionOnClick,
    [FILTER_CATEGORY.FEATURES_V2]: featureSelectionOnClick,
    [FILTER_CATEGORY.SEATS]: seatSelectionOnClick,
    [FILTER_CATEGORY.COLOR]: colorSelectionOnClick
};

export const FILTER_TAB_TYPES = {
    FILTER: "FILTER",
    SORT: "SORT"
};

export const GA_DROPDOWN_KEY_NAME = {
    [FILTER_CATEGORY.ODOMETER]: kmTabDropDown,
    [FILTER_CATEGORY.YEAR]: yearTabDropDown,
    [FILTER_CATEGORY.FUEL_TYPE]: fuelTabDropDown,
    [FILTER_CATEGORY.TRANSMISSION]: transmissionTabDropDown,
    [FILTER_CATEGORY.OWNERS]: ownerTabDropDown,
    [FILTER_CATEGORY.RTO]: rtoTabDropDown,
    [FILTER_CATEGORY.BODY_TYPE]: BodyTabDropDown,
    [FILTER_CATEGORY.CARS24HUB]: hubDropDown,
    [FILTER_CATEGORY.DISCOUNT]: discountDropDown,
    [FILTER_CATEGORY.FEATURES]: featureDropDown,
    [FILTER_CATEGORY.FEATURES_V2]: featureDropDown,
    [FILTER_CATEGORY.SEATS]: featureSelectionOnClick,
    [FILTER_CATEGORY.COLOR]: colorSelectionDropDown
};

export const DISCOUNT_FILTER_KEYS = {
    PRICE: "priceDiscount2",
    EMI: "roiDiscount2"
};
