import Types from "./types";
import { createReducer } from "../../utils/redux/create-reducer";

export const INITIAL_STATE = {
    isSSR: false,
    data: null,
    isPincodeSSR: false
};

export const fetchBuyCarPincodeConfigSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, data };
};

export const fetchBuyCarPincodeConfigFailure = (state = INITIAL_STATE) => {
    return { ...state, data: null };
};

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const updatePincodeSSRStatus = (state = INITIAL_STATE, { isPincodeSSR }) => {
    return { ...state, isPincodeSSR };
};

export const HANDLERS = {
    [Types.FETCH_BUY_CAR_PINCODE_CONFIG_SUCCESS]: fetchBuyCarPincodeConfigSuccess,
    [Types.FETCH_BUY_CAR_PINCODE_CONFIG_FAILURE]: fetchBuyCarPincodeConfigFailure,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.UPDATE_PINCODE_SSR_STATUS]: updatePincodeSSRStatus
};

export default createReducer(INITIAL_STATE, HANDLERS);
