export default (payload, extras) => {
    return {
        UserId: payload.user_id,
        Page: "Appointment",
        ShaId: payload.sha256_md5_id,
        Md5Id: payload.md5_id,
        email: payload.email_hash,
        make: extras.brand.displayText,
        model: extras.model.displayText,
        variant: extras.variant.displayText,
        EmailHash: payload.email_hash,
        PhoneHash: payload.phone_hash,
        year: extras.year.displayText,
        reg_city: "",
        km: extras.kilometersDriven.displayText,
        ...(extras.customDimension163 && { customDimension163: extras.customDimension163}),
        ecommerce: {
            currencyCode: "INR",
            checkout: {
                actionField: {
                    step: 1,
                    option: ""
                },
                products: [{
                    name: extras.model.displayText,
                    id: extras.token,
                    price: 0,
                    brand: extras.brand.displayText,
                    category: `${extras.year.displayText}/${extras.kilometersDriven.displayText}`,
                    variant: extras.variant.displayText,
                    quantity: 1
                }]
            }
        }
    };
};
