import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_ICON_PROPERTY
`,
    {
        prefix: "chaticon/"
    }
);
