import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    applicableCityForSellOnline: [],
    completeSellOnlineCities: [],
    isSellOnline: false,
    isSsr: false
};

export const setIsSellOnline = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isSellOnline: flag };
};

export const updateSSR = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isSsr: flag };
};

export const HANDLERS = {
    [Types.SET_IS_SELL_ONLINE_CITY]: setIsSellOnline,
    [Types.SET_SSR]: updateSSR
};

export default createReducer(INITIAL_STATE, HANDLERS);
