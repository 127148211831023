import CustomEventHandler from "./custom-event-handler";

const EventHandler = (function () {
    let instance = null;

    return {
        getInstance() {
            if (!instance) {
                instance = new CustomEventHandler();
            }
            return instance;
        }
    };
})();

export default EventHandler;
