import React, { Fragment } from "react";
import thunder from "./images/thunder.svg";

const IconThunder = () => {
    return (
        <Fragment>
            <img src={thunder} alt="thunder icon"/>
        </Fragment>
    );
};

export default IconThunder;
