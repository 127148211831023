import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    content: [],
    error: null,
    setCarError: null
};

export const fetchRecentlyViewedCarsSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, content: data };

};

export const fetchRecentlyViewedCarsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error };
};

export const setRecentlyViewedCarSuccess = (state = INITIAL_STATE) => {
    return { ...state };
};

export const setRecentlyViewedCarFailure = (state = INITIAL_STATE, { setCarError }) => {
    return { ...state, setCarError };
};

export const HANDLERS = {
    [Types.FETCH_RECENTLY_VIEWED_CARS_LIST_SUCCESS]: fetchRecentlyViewedCarsSuccess,
    [Types.FETCH_RECENTLY_VIEWED_CARS_LIST_FAILURE]: fetchRecentlyViewedCarsFailure,
    [Types.SET_RECENTLY_VIEWED_CAR_SUCCESS]: setRecentlyViewedCarSuccess,
    [Types.SET_RECENTLY_VIEWED_CAR_FAILURE]: setRecentlyViewedCarFailure

};

export default createReducer(INITIAL_STATE, HANDLERS);
