import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    CHECK_CF_OFFER_SUCCESS
    CHECK_CF_OFFER_FAILURE

    UPDATE_ACTIVE_FORM

    SET_FINANCE_PERSONAL_DETAILS
    SET_FINANCE_ADDRESS_DETAILS
    SET_FINANCE_INCOME_DETAILS

    GET_FINANCE_FORM_DATA_SUCCESS
    SUMBIT_FINANCE_ANSWERS_SUCCESS
    SUMBIT_FINANCE_ANSWERS_FAILURE
    CLEAR_FORM_DATA
    SET_CF_STATUS

    SET_USER_SELECTED_TENURE
    SET_USER_SELECTED_DP
    TOGGLE_CF_TENURE_POPUP
`,
    {
        prefix: "interestform/"
    }
);
