import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdFinanceAvailable from "./component";
import { addAndApplyFilter, changeListName } from "../car-list/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addAndApplyFilterConnect: addAndApplyFilter,
    changeListNameConnect: changeListName
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdFinanceAvailable);
