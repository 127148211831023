import Statsig from "statsig-js";
import parseCookie from "../helpers/parse-cookie";
import statsigEnv from "./get-env";
import { getDeviceType } from "../helpers/device-type";

export const statsigInitialization = async ({city, statsigId = null, clientId = "", updateUser = false, successCallback = () => {}, updateResponse = () => {}}) => {
    const userId = parseCookie("userId");
    const statsigStableId = parseCookie("statsigStableId");
    const finalUserId = !statsigStableId ? statsigId : statsigStableId;
    const { source } = getDeviceType() || {};

    const statsigUser = {
        userID: userId,
        customIDs: {
            stableID: finalUserId,
            uniqueCacheId: finalUserId + city,
            gaId: clientId || ""
        },
        custom: {
            userCityId: (city || "").toString(),
            source
        }
    };

    if (updateUser) {
        await Statsig.updateUser(statsigUser);
    } else {
        await Statsig.initialize(
            process.env.STATSIG_SECRET_CSR_KEY,
            statsigUser,
            { environment: { tier: statsigEnv() },
                overrideStableID: finalUserId,
                initCompletionCallback: (_, success, message) => {
                    if (success) {
                        successCallback();
                    }
                    updateResponse(message);
                }
            } // optional, pass options here if needed
        );
    }
};
