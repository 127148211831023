/* eslint-disable max-len */
import recentlyInspected from "./recently-inspected";
import filterPopularItems from "./popular-brands";
import seoContentParser from "./seo-content";
import { normalizeKeys } from "./normalize-keys";
import parseCityDetails from "./city-details";
import parseCustomerReviews from "./customer-reviews";

const KEY_MAKE_LIST = "make_list";
const KEY_MAKES = "makes";
const KEY_RECENTLY_INSPECTED = "recently_inspected";
const KEY_RECENT_INSPECTIONS = "recentInspections";
const KEY_SEO_CONTENT = "seo_content";
const KEY_SEO = "seo";
const KEY_MODEL_DATA = "model_data";
const KEY_MODELS = "models";
const CITY = "city_list";
const KEY_CUSTOMER_REVIEW_LIST = "customer_review_list";
const KEY_RATED_TESTIMONIALS = "ratedTestimonials";

const DEFAULT_CMS_OBJECT = {
    makes: { all: [], popular: [] },
    seo: {},
    models: [],
    recently_inspected: [],
    blog_list: [],
    testimonial_list: [],
    city_list: [],
    experts_list: [],
    footer_links: {},
    ratedTestimonials: { reviewData: [] }
};

// eslint-disable-next-line max-statements
export default ({ detail }) => {
    const data = { ...detail };
    let parsedResponse = { ...DEFAULT_CMS_OBJECT };
    if (Object.keys(data).length) {
        if (data[KEY_SEO_CONTENT]) {
            parsedResponse[KEY_SEO] = seoContentParser(data[KEY_SEO_CONTENT]);
            delete data[KEY_SEO_CONTENT];
        }
        if (data[KEY_RECENTLY_INSPECTED]) {
            parsedResponse[KEY_RECENT_INSPECTIONS] =
                recentlyInspected(data[KEY_RECENTLY_INSPECTED]);
            delete data[KEY_RECENTLY_INSPECTED];
        }
        if (data[KEY_MAKE_LIST]) {
            parsedResponse[KEY_MAKES] =
                filterPopularItems(normalizeKeys(data[KEY_MAKE_LIST], "make"));
            delete data[KEY_MAKE_LIST];
        }
        if (data[KEY_MODEL_DATA]) {
            parsedResponse[KEY_MODELS] =
                filterPopularItems(normalizeKeys(data[KEY_MODEL_DATA], "model"));
            delete data[KEY_MODEL_DATA];
        }
        if (data[CITY]) {
            parsedResponse[CITY] = parseCityDetails(data[CITY]);
        }
        if (data[KEY_CUSTOMER_REVIEW_LIST]) {
            parsedResponse[KEY_RATED_TESTIMONIALS] = parseCustomerReviews(data[KEY_CUSTOMER_REVIEW_LIST]);
        }
        parsedResponse = { ...parsedResponse, ...data };
    }
    return parsedResponse;
};
