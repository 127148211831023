/* eslint-disable new-cap */
import Types from "./types";
import { UsedCarService } from "../../../service/used-car-service";
import ConsumerFinanceService from "../../../service/consumer-finance-service";
import { OmsAggregatorService } from "../../../service/oms-aggregator-service";

const checkCfOfferSuccess = (data) => ({
    type: Types.CHECK_CF_OFFER_SUCCESS,
    data
});

const checkCfOfferFailure = (error) => ({
    type: Types.CHECK_CF_OFFER_FAILURE,
    error
});

const getFinanceFormDataSuccess = (data) => ({
    type: Types.GET_FINANCE_FORM_DATA_SUCCESS,
    data
});

const setCfStatus = (data) => ({
    type: Types.SET_CF_STATUS,
    data
});

const checkCfOffer = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { user } = getState() || {};
        const { secureToken } = user || {};
        OmsAggregatorService.checkCfOffer({token: secureToken}).then((response) => {
            dispatch(checkCfOfferSuccess(response.data));
            dispatch(getFinanceFormDataSuccess(response.data.cfUserData));
            dispatch(setCfStatus(response.data.status));
            resolve(response.data);
        }).catch((error) => {
            dispatch(checkCfOfferFailure(error));
            reject(error);
        });
    });
};

const updateActiveForm = (activeForm) => ({
    type: Types.UPDATE_ACTIVE_FORM,
    activeForm
});

const setFinancePersonalDetails = (data) => ({
    type: Types.SET_FINANCE_PERSONAL_DETAILS,
    data
});

const setFinanceAddressDetails = (data) => ({
    type: Types.SET_FINANCE_ADDRESS_DETAILS,
    data
});

const setFinanceIncomeDetails = (data) => ({
    type: Types.SET_FINANCE_INCOME_DETAILS,
    data
});

const validatePan = (params) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().validatePan(params).then((response) => {
            resolve(response.data.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const fetchPincodeCity = (pincode) => dispatch => {
    return new Promise((resolve, reject) => {
        UsedCarService.fetchPincodeConfig(pincode)
            .then(response => {
                const { stateName, cityName } = response.data.data;
                dispatch(setFinanceAddressDetails({
                    pincode,
                    state: stateName,
                    city: cityName
                }));
                resolve(response.data.data);
            }).catch((err) => {
                reject(err);
            });

    });
};

const getFinanceFormData = (userId) => (dispatch) => {
    ConsumerFinanceService().getFinanceFormData(userId).then((response) => {
        dispatch(getFinanceFormDataSuccess(response.data.data));
    });
};

const submitFinanceAnswersSuccess = (data) => ({
    type: Types.SUMBIT_FINANCE_ANSWERS_SUCCESS,
    data
});

const submitFinanceAnswersFailure = (error) => ({
    type: Types.SUMBIT_FINANCE_ANSWERS_FAILURE,
    error
});

const submitFinanceAnswers = (data, params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().submitFinanceAnswers(data, params).then((response) => {
            dispatch(submitFinanceAnswersSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(submitFinanceAnswersFailure(error));
            reject(error);
        });
    });
};

const submitUserCfDetails = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().submitUserCfDetails(data).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            dispatch(submitFinanceAnswersFailure(error));
            reject(error);
        });
    });
};

const submitUserFormDetails = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().submitUserFormDetails(data).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            dispatch(submitFinanceAnswersFailure(error));
            reject(error);
        });
    });
};

const clearFormData = () => ({
    type: Types.CLEAR_FORM_DATA
});

const saveUserPersonalData = (payload) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().saveUserPersonalData(payload).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setUserSelectedDp = (userSelectedDp) => ({
    type: Types.SET_USER_SELECTED_DP,
    userSelectedDp
});

const setUserSelectedTenure = (userSelectedTenure) => ({
    type: Types.SET_USER_SELECTED_TENURE,
    userSelectedTenure
});
const getCibilReportUrl = (userId) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getCibilReportUrl(userId).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const toggleCfTenurePopup = (data) => ({
    type: Types.TOGGLE_CF_TENURE_POPUP,
    data
});

export {
    checkCfOffer,
    updateActiveForm,
    setFinancePersonalDetails,
    setFinanceAddressDetails,
    setFinanceIncomeDetails,
    validatePan,
    fetchPincodeCity,
    getFinanceFormData,
    submitFinanceAnswers,
    clearFormData,
    saveUserPersonalData,
    submitUserCfDetails,
    setUserSelectedDp,
    setUserSelectedTenure,
    getCibilReportUrl,
    toggleCfTenurePopup,
    submitUserFormDetails
};
