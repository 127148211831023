import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const OrangeCard = ({ children }) => {
    return (
        <div styleName={"styles.orangeCardOuter"}>
            {children}
        </div>
    );
};
OrangeCard.propTypes = {
    children: PropTypes.any
};

export default OrangeCard;
