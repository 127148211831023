
export default (response) => {
    let parsedResponse = {
        ...response
    };
    if (response.reviewData) {
        const responseCopy = {...response.reviewData};
        const updatedObj = [];
        Object.keys(responseCopy).forEach((item) => {
            updatedObj.push({
                type: item,
                ...parsedResponse.reviewData[item]
            });
        });
        parsedResponse = {
            ...response,
            reviewData: updatedObj
        };
    }
    return parsedResponse;
};
