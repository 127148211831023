/* eslint-disable complexity */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { AD_TYPE } from "../../../constants/app-constants";
import AdFinanceAvailable from "../ad-finance-available";
import AdWarrantyCard from "../ad-warranty-card";
import CfListingCard from "../cf-listing-card";

const CarListingAdCard = ({
    adType = "",
    showWarranty,
    onClickWarranty
}) => {

    return (
        <Fragment>
            {adType === AD_TYPE.CHECK_EMI && <CfListingCard />}
            {adType === AD_TYPE.FINANCE_AVAILABLE && <AdFinanceAvailable />}
            {(adType === AD_TYPE.WARRANTY_AVAILABLE && showWarranty) && <AdWarrantyCard onClickWarranty={onClickWarranty} /> }
        </Fragment>
    );
};

CarListingAdCard.propTypes = {
    history: PropTypes.object,
    updateLeadSourceConnect: PropTypes.func,
    adType: PropTypes.string,
    showWarranty: PropTypes.bool,
    onClickWarranty: PropTypes.func
};

export default CarListingAdCard;
