export const FILTER_ENTITIES = {
    CITY: "city",
    BUDGET: "listingPrice",
    BODY_TYPE: "bodyType",
    FUEL_TYPE: "fuelType",
    CAR_NAME: "carName",
    PARKING_YARD: "parkingYardId",
    OWNERS: "ownerNumber",
    ASSURED: "isC24Assured",
    ODOMETER_READING: "odometerReading",
    TRANSMISSION: "transmission",
    REGISTRATION_CITY: "registrationCity",
    HOT_DEAL: "hotDeal",
    TOP_SELLING: "isTopSelling",
    YEAR: "year",
    FINANCE: "isCFEnabled",
    CAR_SOURCE: "carSource",
    WARRANTY_AVAILABLE: "isWarrantyAvailable",
    THREE_SIXTY_VIEW: "threeSixtyViewAvailable",
    DISCOUNT: "discount"
};

export const FILTER_PRIORITY = [
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.CAR_NAME,
    FILTER_ENTITIES.BUDGET,
    FILTER_ENTITIES.OWNERS
];

export const PRIMARY_FILTER_PRIORITY = [
    FILTER_ENTITIES.BUDGET,
    FILTER_ENTITIES.CAR_NAME,
    FILTER_ENTITIES.ASSURED,
    FILTER_ENTITIES.PARKING_YARD,
    FILTER_ENTITIES.FINANCE,
    FILTER_ENTITIES.ODOMETER_READING,
    FILTER_ENTITIES.YEAR,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.TRANSMISSION,
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.OWNERS,
    FILTER_ENTITIES.REGISTRATION_CITY
];

export const RESETTABLE_FILTER_ENTITIES = [
    FILTER_ENTITIES.PARKING_YARD,
    FILTER_ENTITIES.REGISTRATION_CITY
];
