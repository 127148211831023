export const onClickProgressFilter = {
    event: "visual_filter_car_listing_page",
    eventCategory: "Buy_used_cars_B2C_Car_Listing_Page",
    eventAction: "visual_filter_click"
};

export const progressFilterOnView = {
    event: "visual_filter_car_listing_page",
    eventCategory: "Buy_used_cars_B2C_Car_Listing_Page",
    eventAction: "visual_filter_view"
};
