import { getMonth, getWeekDay } from "../helpers/get-day-from-date";

export default (response) => {
    const data = [];
    Object.keys(response).map((item) => {

        // if (date[item].length  > 0) {
        const time = [];
        const dateValue = `${getWeekDay(new Date(item), true)}, ${new Date(item).getDate()}-${getMonth(new Date(item), true)}`;
        response[item].map((detail) => time.push({
            slotKey: detail.slotKey,
            value: detail.from,
            time: detail.from,
            time_range: `${detail.from}-${detail.to}`
        }));
        data.push({
            date: item,
            value: dateValue,
            time,
            mobile_value: `${getWeekDay(new Date(item), true)}, ${new Date(item).getDate()} ${getMonth(new Date(item), true)}`
        });

        // }

    });
    return data;
};
