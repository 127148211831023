export default (data) => {
    const carMake = [...data];
    const subModel = [];
    carMake.forEach(make => {
        make.models.forEach(model => {
            const custonMake = {
                ...model,
                displayText: `${make.displayText} ${model.displayText}`,
                value: `${make.value} ${model.value}`
            };
            subModel.push(custonMake);
        });
    });
    return [...carMake, ...subModel];
};

// {displayText: "Maruti", value: "MARUTI"}
