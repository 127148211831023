import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarListingAdCard from "./component";

const mapStateToProps = ({
    carList: {
        showWarranty
    }
}) => ({
    showWarranty
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarListingAdCard);
