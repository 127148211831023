import React, { Fragment } from "react";
import ShieldIcon from "./images/shield-line-icon.svg";

const AdShieldLineIcon = () => {
    return (
        <Fragment>
            <img src={ShieldIcon} alt="shield icon"/>
        </Fragment>
    );
};

export default AdShieldLineIcon;
