import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    confirmation: {},
    isCitySelectionSkipped: false,
    leadSource: "original",
    dealerCode: ""
};

export const setConfirmationFlag = (state = INITIAL_STATE, { token }) => {
    return { ...state, confirmation: { [token]: true } };
};

export const skipCitySelection = (state = INITIAL_STATE, { isCitySelectionSkipped }) => {
    return { ...state, isCitySelectionSkipped };
};

export const updateLeadSource = (state = INITIAL_STATE, { leadSource }) => {
    return { ...state, leadSource };
};

export const setDealerCode = (state = INITIAL_STATE, { dealerCode }) => {
    return { ...state, dealerCode };
};

export const HANDLERS = {
    [Types.SET_CONFIRMATION_FLAG]: setConfirmationFlag,
    [Types.SKIP_CITY_SELECTION]: skipCitySelection,
    [Types.UPDATE_LEAD_SOURCE]: updateLeadSource,
    [Types.SET_DEALER_CODE]: setDealerCode

};

export default createReducer(INITIAL_STATE, HANDLERS);
