export default (payload, extras) => ({
    UserId: payload.lead_details.email_hash,
    Page: "ThankYou",
    AppointmentCity: payload.lead_details.cityName,
    AppointmentStore: extras.storeName,
    AppointmentDate: extras.selectedInspectionDate,
    AppointmentTime: extras.selectedInspectionTime,
    CustomerMobile: payload.lead_details.phone_hash,
    EmailHash: payload.lead_details.email_hash,
    PhoneHash: payload.lead_details.phone_hash,
    ShaId: payload.lead_details.sha256_md5_id,
    Md5Id: payload.lead_details.md5_id,
    CustomerName: extras.name,
    leadScore: extras.leadScore  || payload.lead_details.leadScore,
    ecommerce: {
        currencyCode: "INR",
        purchase: {
            actionField: {
                id: payload.appointment_id,
                affiliation: "Online Store",
                revenue: 0,
                tax: "",
                shipping: "",
                coupon: ""
            },
            products: [{
                name: payload.lead_details.modelName,
                id: payload.lead_details.token,
                price: 0,
                brand: payload.lead_details.makeName,
                category: `${payload.lead_details.year}/${payload.lead_details.kms}`,
                variant: payload.lead_details.variantName,
                quantity: 1,
                coupon: ""
            }]
        }
    }

});
