export default (payload) => ({
    UserId: payload.user_id,
    Page: "ThankYou",
    AppointmentCity: payload.city_name,
    AppointmentStore: payload.location,
    AppointmentDate: payload.date,
    AppointmentTime: payload.time,
    CustomerMobile: payload.phone_hash,
    EmailHash: payload.email_hash,
    PhoneHash: payload.phone_hash,
    ShaId: payload.sha256_md5_id,
    Md5Id: payload.md5_id,
    CustomerName: payload.fullname,
    leadScore: payload.leadScore,
    eventCategory: "AppointmentConfirmed",
    eventAction: "AppointmentConfirmed",
    eventLabel: payload.pub_appt_id,
    ecommerce: {
        currencyCode: "INR",
        purchase: {
            actionField: {
                id: payload.pub_appt_id,
                affiliation: "Online Store",
                revenue: 0,
                tax: "",
                shipping: "",
                coupon: ""
            },
            products: [{
                name: payload.model,
                id: payload.token,
                price: 0,
                brand: payload.make,
                category: `${payload.year}/${payload.km}`,
                variant: payload.variant,
                quantity: 1,
                coupon: ""
            }]
        }
    }

});
