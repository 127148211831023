
export const leadData = {
    brand: { displayText: "", id: null },
    model: { displayText: "", id: null },
    year: { displayText: "", id: null, year_id: null },
    fuelType: { displayText: "" },
    transmission: { displayText: "" },
    registrationState: { displayText: "", id: null },
    variant: { displayText: "", id: null, variantType: null },
    kilometersDriven: { displayText: "", id: null },
    rto: { displayText: "", id: null },
    branch: { displayText: "", id: null, selectionType: "city" },
    currentStep: "brand",
    currentProgressStep: "",
    currentStepIndex: 0,
    currentTabIndex: 0,
    prevTabIndex: 0,
    proceedCTA: false,
    token: null,
    error: null,
    activateLastStep: false,
    priceRange: {},
    source: null,
    isLeadFormChanged: false,
    flowName: "default",
    ctaLocation: null,
    variantActiveScreen: "fueltype",
    mobile: { displayText: "", id: null },
    allStepCompleted: false,
    leadScore: null,
    activateFirstStep: true,
    askUserIntent: false,
    userIntent: "",
    ownerName: "",
    carRegistrationNo: "",
    isAllStepFill: false,
    showWidget: false
};

export const usedCarLeadInfo = {
    customerName: "",
    customerPhoneNumber: "",
    whatsappNotifications: false,
    isFinanced: null,
    isRedirected: false,
    leadFlow: "MAKE_AN_OFFER",
    isHomePageCtaFlow: false
};

export const sellOnlineLeadData = {
    brand: { displayText: "", id: null },
    model: { displayText: "", id: null },
    year: { displayText: "", id: null, year_id: null },
    fuelType: { displayText: "" },
    transmission: { displayText: "" },
    registrationState: { displayText: "", id: null },
    variant: { displayText: "", id: null },
    kilometersDriven: { displayText: "", id: null },
    rto: { displayText: "", id: null },
    branch: { displayText: "", id: null, selectionType: "city" },
    currentStep: "basicdetails",
    currentProgressStep: "",
    currentStepIndex: 1,
    currentTabIndex: 0,
    prevTabIndex: 0,
    proceedCTA: false,
    token: null,
    error: null,
    activateLastStep: false,
    priceRange: {},
    source: null,
    isLeadFormChanged: false,
    flowName: "default",
    ctaLocation: null,
    basicDetailCompleteWithLocation: {},
    carConditionQuestions: [],
    currentQuestionIndex: 0,
    userResponse: {},
    selectedInspectionDate: null,
    selectedInspectionTime: null,
    locationName: ""
};

export const locationPicker = {
    showLocationPicker: false,
    locationSelectionType: "",
    showLocationPickerV2: false,
    pageType: "",
    isCTAClickedWithoutPincode: {
        type: "",
        meta: {}
    }
};
