import { resettableReducer } from "../utils/redux/resettable-reducer";
import { reducerConstants } from "../constants/reducer-constant";
import configReducer from "../components/config-ssr/reducers";
import experimentReducer from "../components/shared/ab-experiment/reducers";
import userReducer from "../components/configuration/reducers";
import sellOnlineCitiesReducer from "../components/desktop/sell-online-cities/reducers";

//below reducers are used in configuration component hence just adding here for now
import locationReducer from "../components/location/reducers";
import buyCarPincodeConfigReducer from "../components/buy-car-pincode-config/reducers";
import citiesReducer from "../components/desktop/cities/reducers";
import filterReducer from "../components/desktop/filter-v2/reducer";
import wishlistReducer from "../components/desktop/wishlist/reducers";

//below reducers are used in desktop layout hence adding here for now
import locationPickerModalReducer from "../components/desktop/location-picker-popup/reducers";
import carListReducer from "../components/desktop/car-list/reducers";
import recentlyViewedCarsReducer from "../components/desktop/recently-viewed-car-list/reducers";
import chatIconReducer from "../components/desktop/chat-icon/reducers";
import cfPopupReducer from "../components/desktop/cf-popup/reducers";
import toastMessageReducer from "../components/mobile/toast-message/reducers";

//below reducers are used in desktop header
import metaTagReducer from "../components/shared/meta-tags/reducers";
import settingsReducer from "../components/desktop/settings/reducers";
import brandsReducer from "../components/desktop/lead-brands/reducers";

//below reducers are used in footer
import footerReducer from "../components/desktop/footer/reducers";

import buyUsedCarHomeReducer from "../components/desktop/buy-used-car/reducers";
import nearbyCitiesReducer from "../components/desktop/nearby-cities/reducers";
import needHelpPopupReducer from "../components/desktop/help-support-modal/reducer";
import consumerFinanceReducer from "../components/desktop/interest-form/reducers";
import headerReducer from "../components/desktop/header-v2/reducers";

export default {
    [reducerConstants.CONFIG]: resettableReducer(reducerConstants.CONFIG)(configReducer),
    [reducerConstants.EXPERIMENTS]: resettableReducer(reducerConstants.EXPERIMENTS)(experimentReducer),
    [reducerConstants.USER]: resettableReducer(reducerConstants.USER)(userReducer),
    [reducerConstants.CITIES]: resettableReducer(reducerConstants.CITIES)(citiesReducer),
    [reducerConstants.FILTERS]: resettableReducer(reducerConstants.FILTERS)(filterReducer),
    [reducerConstants.LOCATION]: resettableReducer(reducerConstants.LOCATION)(locationReducer),
    [reducerConstants.BUY_CAR_PINCODE_CONFIG]: resettableReducer(reducerConstants.BUY_CAR_PINCODE_CONFIG)(buyCarPincodeConfigReducer),
    [reducerConstants.LOCATION_PICKER]: resettableReducer(reducerConstants.LOCATION_PICKER)(locationPickerModalReducer),
    [reducerConstants.CAR_LIST]: resettableReducer(reducerConstants.CAR_LIST)(carListReducer),
    [reducerConstants.RECENTLY_VIEWED]: resettableReducer(reducerConstants.RECENTLY_VIEWED)(recentlyViewedCarsReducer),
    [reducerConstants.CHAT_ICON]: resettableReducer(reducerConstants.CHAT_ICON)(chatIconReducer),
    [reducerConstants.SELL_ONLINE_CITIES]: resettableReducer(reducerConstants.SELL_ONLINE_CITIES)(sellOnlineCitiesReducer),
    [reducerConstants.META]: resettableReducer(reducerConstants.META)(metaTagReducer),
    [reducerConstants.SETTINGS]: resettableReducer(reducerConstants.SETTINGS)(settingsReducer),
    [reducerConstants.BRANDS]: resettableReducer(reducerConstants.BRANDS)(brandsReducer),
    [reducerConstants.FOOTER]: resettableReducer(reducerConstants.FOOTER)(footerReducer),
    [reducerConstants.CF_POPUP]: resettableReducer(reducerConstants.CF_POPUP)(cfPopupReducer),
    [reducerConstants.BUY_USED_CAR_HOME]: resettableReducer(reducerConstants.BUY_USED_CAR_HOME)(buyUsedCarHomeReducer),
    [reducerConstants.NEARBY_CITIES]: resettableReducer(reducerConstants.NEARBY_CITIES)(nearbyCitiesReducer),
    [reducerConstants.WISHLIST]: resettableReducer(reducerConstants.WISHLIST)(wishlistReducer),
    [reducerConstants.NEED_HELP]: resettableReducer(reducerConstants.NEED_HELP)(needHelpPopupReducer),
    [reducerConstants.CONSUMER_FINANCE]: resettableReducer(reducerConstants.CONSUMER_FINANCE)(consumerFinanceReducer),
    [reducerConstants.TOAST]: resettableReducer(reducerConstants.TOAST)(toastMessageReducer),
    [reducerConstants.HEADER]: resettableReducer(reducerConstants.HEADER)(headerReducer)

};

