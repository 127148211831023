import createArrayFromObject from "../helpers/create-array-from-object";
import { sanitizeObject } from "../helpers/sanitizers";

const KEY_LOCATIONS = "locations";
const KEY_SLOTS = "slots";
const KEY_ZONE = "zone";
const KEY_DATE_SLOT = "date_slot";
const KEY_TIME = "time";

const parseTimeLabel = (time) => {
    if (time.indexOf("Open") > -1) {
        return time;
    }
    const timestamp = time.toLowerCase().split(":00 ");
    return [parseInt(timestamp[0]), timestamp[1]].join(" ");
};

const sanitizeTimeSlot = (data) => {
    // Because api has nested objects instead of arrays

    const parsedData = [...data];

    Object.keys(data).forEach(slot => {

        const arrayTimeSlots = createArrayFromObject(data[slot][KEY_TIME]);
        const flattenedTimeSlots = [];
        const timeSlots = [];
        arrayTimeSlots.forEach((tSlot) => {
            flattenedTimeSlots.push({
                ...tSlot
            });
        });
        flattenedTimeSlots.forEach((time, index) => {
            const timeSlot = Object.keys(time).map(value => (
                {
                    ...time[value],
                    value,
                    label: parseTimeLabel(time[value].time),
                    isExpressSlot: time[value].isExpressSlot === "true", // converting it to boolean since BE provide it as a string
                    isPremiumSlot: time[value].isPremiumSlot === "true", // converting it to boolean since BE provide it as a string
                    timeType: Object.keys(data[slot][KEY_TIME])[index]
                }
            )
            );
            timeSlots.push(...timeSlot);
        });
        parsedData[slot][KEY_TIME] = timeSlots;
    });
    return parsedData;
};

export default (data) => {

    const parsedResposne = { ...data };
    if (data[KEY_ZONE]) {
        parsedResposne[KEY_ZONE] = sanitizeObject(data[KEY_ZONE]);
    }
    if (data[KEY_LOCATIONS]) {
        const locations = createArrayFromObject(data[KEY_LOCATIONS]);
        parsedResposne[KEY_LOCATIONS] = locations.map(item => (
            { ...sanitizeObject(item), type: "store" }
        ));
    }
    if (data[KEY_LOCATIONS] === null) {
        parsedResposne[KEY_LOCATIONS] = [];
    }
    if (data[KEY_SLOTS]) {
        Object.keys(data[KEY_SLOTS]).forEach((item) => {
            let slots = [];
            if (data[KEY_SLOTS][item][KEY_DATE_SLOT]) {

                slots = createArrayFromObject(data[KEY_SLOTS][item][KEY_DATE_SLOT]);
                slots = sanitizeTimeSlot(slots);
            }
            data[KEY_SLOTS][item][KEY_DATE_SLOT] = slots;
            data[KEY_SLOTS][item] = sanitizeObject(data[KEY_SLOTS][item]);
        });
        parsedResposne[KEY_SLOTS] = data[KEY_SLOTS];
    }
    return parsedResposne;
};
