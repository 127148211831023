import CarListingAdCard from "../car-listing-ad-card";
import InterlinkingFilter from "../interlinking-filter";
import { NUMBER } from "../../../constants/app-constants";
// import CfListingCard from "../cf-listing-card";

export const SCREEN_TYPES = {
    listing: "listing",
    filter: "filter",
    listItems: "listItems"
};
export const TAB_TYPES = {
    sort: "sort",
    filter: "filter",
    changeView: "changeView"
};

export const SHOW_AD_INDEXES = [
    NUMBER.ONE,
    NUMBER.FOUR,
    NUMBER.SEVEN,
    NUMBER.TEN,
    NUMBER.THIRTEEN,
    NUMBER.SIXTEEN,
    NUMBER.TWENTY_TWO,
    NUMBER.TWENTY_EIGHT,
    NUMBER.THIRTY_FOUR,
    NUMBER.FOURTY,
    NUMBER.FOURTY_SIX,
    NUMBER.FIFTY_TWO
];

export const  ShowIntrelinkFilter = [
    NUMBER.ONE,
    NUMBER.FOUR,
    NUMBER.SEVEN,
    NUMBER.TEN,
    NUMBER.THIRTEEN,
    NUMBER.SIXTEEN
];

export const ADDITIONAL_COMPONENTS = {
    [NUMBER.ONE]: {
        list: [
            { Component: CarListingAdCard, props: { isAdCard: true } }
        ]
    },
    [NUMBER.FOUR]: {
        list: [
            { Component: InterlinkingFilter, props: { filterIndex: 0 } }
        ]
    },
    [NUMBER.SEVEN]: {
        list: [
            { Component: InterlinkingFilter, props: { filterIndex: 1 } }
        ]
    },
    [NUMBER.TEN]: {
        list: [
            { Component: InterlinkingFilter, props: { filterIndex: 2 } }
        ]
    },
    [NUMBER.THIRTEEN]: {
        list: [
            { Component: InterlinkingFilter, props: {filterIndex: 3 } }
        ]
    },
    [NUMBER.SIXTEEN]: {
        list: [
            { Component: InterlinkingFilter, props: { filterIndex: 4 } }
        ]
    },
    [NUMBER.TWENTY_TWO]: {
        list: [
            { Component: CarListingAdCard, props: { isAdCard: true } }
        ]
    },
    [NUMBER.TWENTY_EIGHT]: {
        list: [
            { Component: CarListingAdCard, props: { isAdCard: true } }
        ]
    },
    [NUMBER.THIRTY_FOUR]: {
        list: [
            { Component: CarListingAdCard, props: { isAdCard: true } }
        ]
    },
    [NUMBER.FOURTY]: {
        list: [
            { Component: CarListingAdCard, props: { isAdCard: true } }
        ]
    },
    [NUMBER.FOURTY_SIX]: {
        list: [
            { Component: CarListingAdCard, props: { isAdCard: true } }
        ]
    },
    [NUMBER.FIFTY_TWO]: {
        list: [
            { Component: CarListingAdCard, props: { isAdCard: true } }
        ]
    }
};

export const LISTING_PAGE_LANDING_SOURCE = {
    Search_HP: "Search_HP",
    Search_LP: "Search_LP",
    Sort: "Sort",
    TabFilter: "TabFilter",
    ViewAllCars: "ViewAllCars",
    Homepage_Filters: "Homepage_Filters",
    CF_Homepage: "CF_Homepage",
    LoanTab: "LoanTab",
    FilterTags: "FilterTags"
};
