import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_IS_SELL_ONLINE_CITY
    SET_SSR
`,
    {
        prefix: "sellonlinecities/"
    }
);
