const eventNames = {
    LEAD_CREATE: "LeadCreated",
    LEAD_EDITED: "LeadEdited",
    APPOINTMENT_BOOKED: "AppointmentBooked",
    APPOINTMENT_EDITED: "AppointmentEdited",
    APPOINTMENT_CONFIRMED: "AppointmentConfirmed",
    APPOINTMENT_RESCHEDULED: "AppointmentRescheduled"
};

export {
    eventNames
};
