export const budgetBucketFinder = (num) => {
    const ranges = [
        { min: 0, max: 300000, label: "0-300000" },
        { min: 300000, max: 500000, label: "300000-500000" },
        { min: 500000, max: 700000, label: "500000-700000" },
        { min: 700000, max: 1000000, label: "700000-1000000" }
    ];

    for (const range of ranges) {
        if (num >= range.min && num <= range.max) {
            return range.label;
        }
    }

    return "1000000+";
};
