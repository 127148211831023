import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_MODAL_SOURCE
`,
    {
        prefix: "needHelp/"
    }
);
