export const transformKeys = (inputObj) => {
    const newObj = {};

    for (const key in inputObj) {
        if (inputObj.hasOwnProperty(key)) {
            // Replace '-' with '_', convert to uppercase
            const newKey = key.replace(/-/g, "_").toUpperCase();
            // Set the new key-value pair in the new object
            newObj[newKey] = key;
        }
    }

    return newObj;
};
