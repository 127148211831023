import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import InterlinkingFilter from "./component";
import { reloadCarList } from "../car-list/actions";
import { setIndiaPageUrl } from "../filter-v2/action";

const mapStateToProps = ({
    carList: { interLinkingUrls, seo },
    filters: { appliedFilters },
    user: {
        isLoggedIn,
        userId
    },
    location: { cityInfo: { city_name: cityName, city_id: cityId } }

}) => ({
    interLinkingUrls,
    isLoggedIn,
    userId,
    cityName,
    cityId,
    seo,
    appliedFilters
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList,
    setIndiaPageUrlConnect: setIndiaPageUrl
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InterlinkingFilter));
