import eventCollection from "./event-collection";
import mobileEvents from "./mobile-events";
import desktopEvents from "./desktop-events";

const IDENTIFIER = "data-title";

const sendEvent = (data) => {
    if (typeof window !== "undefined") {
        window.dataLayer.push({ ...data });
    }
};

export const trackCustomEvents = ({ platformEvents, category }, extras) => {
    const data = { ...extras, ...platformEvents[category] };
    sendEvent(data);
};

export const trackMobileEvent = ({ e, category, key }, extras = {}) => {
    const value = e.target.getAttribute(IDENTIFIER);
    trackCustomEvents({
        platformEvents: mobileEvents,
        category
    }, { ...extras, [key]: value });
};

export const trackApiEvents = (type, payload, extras) => {
    sendEvent({
        event: eventCollection[type].name,
        ...eventCollection[type].parser(payload, extras)
    });
};

export const trackMobileCustomEvents = (category, extras = {}) => {
    trackCustomEvents({
        platformEvents: mobileEvents, category
    }, extras);
};

export const trackDesktopCustomEvents = (category, extras = {}) => {
    trackCustomEvents({
        platformEvents: desktopEvents, category
    }, extras);
};

export const trackDesktopEvent = ({ e, category, key }, extras = {}) => {
    const value = e.target.getAttribute(IDENTIFIER);
    trackCustomEvents({
        platformEvents: desktopEvents,
        category
    }, { ...extras, [key]: value });
};

export const fireCustomEvent = () => {
    window.setTimeout(() => {
        window.dataLayer.push({ event: "optimize.activate" });
    }, 0);
};

export const sendGAEvent = (data) => {
    window.setTimeout(() => {
        window.dataLayer.push(data);
    }, 0);

};

export const sendCustomGaEvents = (data = {}) => {
    sendEvent(data);
};
