import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import parseTopCities from "../../../utils/parsers/parse-top-cities";

export const INITIAL_STATE = {
    isSSR: false,
    data: {},
    error: null
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data }) => {
    const parsedCitiesData = {...data, top_cities: parseTopCities(data.top_cities || [])};
    return { ...state, data: parsedCitiesData, error: null };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: {} };
};

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const HANDLERS = {
    [Types.FETCH_FOOTER_DATA_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_FOOTER_DATA_FAILURE]: fetchDataFailure,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus
};

export default createReducer(INITIAL_STATE, HANDLERS);
