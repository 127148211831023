import { parseStringToMeaningfulBool } from "./bool-convertor";

export const sanitizeObject = (data) => {
    Object.keys(data).forEach((item) => {
        if (typeof data[item] === "string") {
            if (data[item].toLowerCase() === "yes" || data[item].toLowerCase() === "no") {
                data[item] = parseStringToMeaningfulBool(data[item]);
            }
        }
    });
    return data;
};
