import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
   SET_HEADER_VISIBILITY
   SET_BUY_CARS_HEADER_DATA
   UPDATE_SSR
`,
    {
        prefix: "headerv2/"
    }
);
