import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CfListingCard from "./component";
import { withRouter } from "react-router-dom";

const mapStateToProps = ({
    user: {
        preApproved
    },
    consumerFinance: {
        cfStatus
    }
}) => ({
    preApproved,
    cfStatus
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CfListingCard));
