import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_BUY_USED_CAR_MODELS_BY_BRANDS_FAILURE
    FETCH_BUY_USED_CAR_MODELS_BY_BRANDS_SUCCESS
    FETCH_BUY_USED_CAR_MODELS_BY_BRANDS
    
    UPDATE_SSR_STATUS

    FETCH_PRESET_FILTERS_SUCCESS

    FETCH_RECENT_CARS_LIST
    FETCH_RECENT_CARS_LIST_FAILURE
    FETCH_RECENT_CARS_LIST_SUCCESS

    FETCH_BANK_OFFER_COUPONS_SUCCESS
    FETCH_BANK_OFFER_COUPONS_FAILURE
    FETCH_BANK_OFFER_COUPONS

    SET_SHOW_SELECTED_BANK_OFFER

    SET_SHOW_POPULAR_CITIES_ON_HOMEPAGE

    FETCH_MOTIVATIONAL_STORIES
    FETCH_MOTIVATIONAL_STORIES_FAILURE
    FETCH_MOTIVATIONAL_STORIES_SUCCESS

    FETCH_HOME_PAGE_BANNERS_FAILURE
    FETCH_HOME_PAGE_BANNERS_SUCCESS
    FETCH_HOME_PAGE_BANNERS
    UPDATE_HOME_PAGE_BANNERS_SSR
    
    UPDATE_SSR_STATUS

    FETCH_BUY_USED_CAR_HOME_TEMPLATE
    FETCH_BUY_USED_CAR_HOME_TEMPLATE_SUCCESS
    FETCH_BUY_USED_CAR_HOME_TEMPLATE_FAILURE
    UPDATE_SSR

    SET_BANNER_POPUP_CONTENT

    SET_CMS_DATA
    SET_CMS_DATA_ERROR
    `,
    {
        prefix: "buyUsedCarHome/"
    }
);
