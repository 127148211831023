import { proxy } from "valtio/vanilla";

class UserClientId {
    constructor() {
        this.clientId = "";
        this.callback = null;
        this.userClientId = proxy({ clientId: "" });
        this.checkClientId();
    }

    generateClientId(callback) {
        this.callback = callback;
        this.checkClientId();
    }

    checkClientId() {
        if (typeof window !== "undefined" && window.ga) {
            window.ga((tracker) => {
                const clientId = tracker.get("clientId");
                if (clientId) {
                    this.clientId = clientId;
                    this.userClientId.clientId = clientId;
                    if (this.callback) {
                        this.callback(null, clientId);
                        this.callback = null;
                    }
                } else if (this.callback) {
                    this.callback("Client ID not found");
                    this.callback = null;
                }
            });
        } else if (this.callback) {
            this.callback(null, "");
            this.callback = null;
        }
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new UserClientId();
        }
        return this.instance;
    }
}

export const userClientIdInstance = UserClientId.getInstance();
