import React from "react";
import PropTypes from "prop-types";
import Overlay from "../../shared/overlay";
import IconSpinner from "../../shared/icon-spinner";
import styles from "./styles.css";

const PageLoader = ({
    opacity = 1,
    position = "fixed",
    spinnerLoader = true,
    background = "white"
}) => {
    return (
        <Overlay
            position={position}
            background={background}
            opacity={opacity}>
            {
                spinnerLoader ?
                    <div styleName={"styles.loadingIcon"}>
                        <IconSpinner />
                    </div>
                    :
                    <div styleName={"styles.loaderWrapper"}>
                        <div styleName={"styles.loaderContent"}>
                            <div styleName={"styles.loaderTrack"}>
                                <div styleName={"styles.loaderRail"}>
                            Movable Loader
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </Overlay>
    );
};

PageLoader.propTypes = {
    opacity: PropTypes.number,
    position: PropTypes.symbol,
    spinnerLoader: PropTypes.bool,
    background: PropTypes.string
};

export default PageLoader;
