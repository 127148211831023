import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

/* eslint-disable max-statements */
const couponServiceApi = (api) => {

    const clientMapping = (data, token) => {
        return api.post(`client-mapping`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    X_COUNTRY: "IN",
                    X_VEHICLE_TYPE: "CAR"
                }
            });
    };

    const getCouponForUser = (userId, token) => {
        return api.get(`user-rewards?user-id=${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    X_COUNTRY: "IN",
                    X_VEHICLE_TYPE: "CAR"
                }
            });
    };

    const getOptedCouponForUser = (userId, token) => {
        return api.get(`coupon/opt-in?user-id=${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    X_COUNTRY: "IN",
                    X_VEHICLE_TYPE: "CAR"
                }
            });
    };
    const postOptCouponForUser = (data, token) => {
        return api.post(`coupon/opt-in`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    X_COUNTRY: "IN",
                    X_VEHICLE_TYPE: "CAR"
                }
            });
    };

    const fetchBankOfferCoupons = (clientId, token) => {
        return api.get(`client-rewards/${clientId}?type=OFFER`, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const fetchBankOfferCouponsById = (offerId, token) => {
        return api.get(`coupon/${offerId}/faq`, {
            headers: {
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR",
                ...(token && { Authorization: `Bearer ${token}` })
            },
            version: "v2"
        });
    };

    const fetchUserOffer = (clientId, token, params) => {
        return api.get(`client-rewards/${clientId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            },
            params
        });
    };

    const fetchFestivalOffer = ({ params, clientId, source }) => {
        return api.get(`offers`, {
            headers: {
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR",
                clientId,
                source
            },
            params
        });
    };

    const spinTheWheelStatus = ({ params, clientId, source, token }) => {
        return api.get(`user/spin/state`, {
            headers: {
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR",
                Authorization: `Bearer ${token}`,
                clientId,
                source
            },
            params,
            version: "v3"
        });
    };

    const spinTheWheelTrigger = ({ data, clientId, source, token }) => {
        return api.post(`user/spin/trigger`, data, {
            headers: {
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR",
                Authorization: `Bearer ${token}`,
                clientId,
                source
            },
            version: "v3"
        });
    };

    return {
        clientMapping,
        getCouponForUser,
        getOptedCouponForUser,
        postOptCouponForUser,
        fetchBankOfferCoupons,
        fetchBankOfferCouponsById,
        fetchUserOffer,
        fetchFestivalOffer,
        spinTheWheelStatus,
        spinTheWheelTrigger
    };
};

export const CouponService = couponServiceApi(middlewareConfig(MIDDLEWARE_ENUMS.COUPONS_EXCHANGE_CONST));

