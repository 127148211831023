import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

/* eslint-disable max-statements */
const cmsServiceApi = (api) => {

    const getCmsData = ({ template, slug, params }) => {
        return api.post(`/api/v1/single-type-pages/template/${template}?slug=${slug}`, params);
    };

    return {
        getCmsData
    };
};

export const CmsServiceApi = cmsServiceApi(middlewareConfig(MIDDLEWARE_ENUMS.CMS_SERVICE));
