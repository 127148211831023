import React, { Fragment } from "react";
import styles from "./styles.css";
import ShieldIcon from "./images/shield-icon.svg";

const AdShieldIcon = () => {
    return (
        <Fragment>
            <img styleName={"styles.icon"} src={ShieldIcon} alt="shield icon"/>
        </Fragment>
    );
};

export default AdShieldIcon;
