import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    UPDATE_CF_POPUP_TYPE
    SET_WITHOUT_FINANCE_FLOW
    SET_CURRENT_SCREEN_TYPE
    SET_SHOW_CF_POPUP
`,
    {
        prefix: "cfpopup/"
    }
);
