export default (payload, extras = {}) => {
    return {
        UserId: payload.user_id,
        Page: "Appointment",
        ShaId: payload.sha256_md5_id,
        Md5Id: payload.md5_id,
        email: payload.email_hash,
        make: payload.make_display,
        model: payload.model_display,
        CustomerMobile: payload.phone_hash,
        EmailHash: payload.email_hash,
        PhoneHash: payload.phone_hash,
        variant: payload.variant_display_name,
        year: payload.year,
        lead_type: payload.lead_type,
        reg_city: "",
        ...(extras && extras.isLeadCreated && {
            eventCategory: "LeadCreated",
            eventAction: "LeadCreated",
            eventLabel: payload.token
        }),
        km: payload.kms_range,
        ...(payload.hasOwnProperty("leadScore") && { lead_score: payload.leadScore }),
        ...(extras && extras.mobileNumber && { phone: extras.mobileNumber }),
        ...(extras && extras.customDimension163 && { customDimension163: extras.customDimension163 }),
        ...(extras && extras.leadPrice && { lead_price: extras.leadPrice }),
        ecommerce: {
            currencyCode: "INR",
            checkout: {
                actionField: {
                    step: 1,
                    option: ""
                },
                products: [{
                    name: payload.model_display,
                    id: payload.token,
                    price: 0,
                    brand: payload.make_display,
                    category: `${payload.year}/${payload.kms_range}`,
                    variant: payload.variant_display_name,
                    quantity: 1
                }]
            }
        }
    };
};
