import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_EXPERIMENT
    SET_BANNERS
    SET_ALTERNATE_SELL_CAR_FLOW
    SET_ALTERNATE_RTO_FLOW
    UPDATE_STATSIG_CONFIG
    CLEAR_ALL_EXPERIMENTS

    UPDATE_STATSIG_TIMESTAMP
`,
    {
        prefix: "abexperiment/"
    }
);
