/* eslint-disable no-undef */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-console */
import axios from "axios";
import interceptor from "./interceptor";
import { proxy } from "valtio/vanilla";
import { middlewareConstants } from "./middleware-constants";
import { vehicleApiUrl } from "../constants/url-constants";
import { getDeviceType } from "../utils/helpers/device-type";
import { HTTP_CODES } from "../constants/app-constants";
import { userClientIdInstance } from "../utils/helpers/generate-client-id";
import { getCookiesValueObjectFromKeys } from "../utils/helpers/get-cookie-values-object-from-keys";

const VEHICLE_SERVICE_BASE_API_URL = `${vehicleApiUrl()}/`;

export const tokenState = proxy({
    isTokenValid: true
});

const middlewareConfig = (middlewareKey) => {
    const params = middlewareConstants[middlewareKey];
    const {
        baseApi = "",
        authKey = "",
        customHeaders,
        withCredentials = false,
        isVersion = false,
        isSource = false,
        isTokenValidCheckEnable = false,
        isBearer = false,
        debugEnv = "development2",
        isClientId = false,
        isCityId = false
    } = params;
    const DEBUG = process.env.NODE_ENV === debugEnv;
    const api = axios.create({
        baseURL: baseApi,
        headers: {
            "Content-Type": "application/json",
            ...(authKey && { "Authorization": `${isBearer ? "Bearer" : "Basic"} ${authKey}` }),
            ...(customHeaders && {...customHeaders})
        },
        ...(withCredentials && { withCredentials: true })
    });
    const cookiesKeys = ["statsigStableId", "user_selected_city"];

    // eslint-disable-next-line max-statements
    api.interceptors.request.use((config) => {
        const cookiesValues = getCookiesValueObjectFromKeys(cookiesKeys);
        const {statsigStableId, user_selected_city: cityId} = cookiesValues || {};
        if (isVersion) {
            config.baseURL = `${baseApi}${config.version || "v1"}/`;
        }
        if (config.isMigrated && (process.env.HOST_ENV === "QA" || process.env.HOST_ENV === "STAGING" || process.env.HOST_ENV === "PRODUCTION")) {
            config.baseURL = VEHICLE_SERVICE_BASE_API_URL;
            if (config.headers && config.headers["X-Application-Type"]) {
                delete config.headers["X-Application-Type"];
            }
            config.withCredentials = false;
        }
        if (isSource && getDeviceType().source) {
            config.headers.common.SOURCE = getDeviceType().source;
        }
        if (isClientId) {
            if (userClientIdInstance.userClientId.clientId) {
                config.headers.clientId = userClientIdInstance.userClientId.clientId;
            }
            if (statsigStableId) {
                config.headers.x_experiment_id = statsigStableId;
            }
        }
        if (isCityId) {
            config.headers["x-user-city-id"] = cityId || "";
        }
        /** In dev, intercepts request and logs it into console for dev */
        if (DEBUG) { console.info("✉️ ", config); }
        return config;
    }, (error) => {
        if (DEBUG) { console.error("✉️ ", error); }
        return Promise.reject(error);
    });

    /**
     * Passes response.data to services.
     * In dev, intercepts response and logs it into console for dev
     */
    api.interceptors.response.use((response) => {
        let validResponse = response;
        if (response.config.parse) {
            validResponse = interceptor(response);
        }
        if (DEBUG) { console.info(`📩 ${response.config.url}`, {validResponse, response}); }
        return validResponse;
    }, (error) => {
        if (error.config && error.config.customError) return Promise.reject(error);
        if (error.response && error.response.data) {
            if (DEBUG) { console.error("Error: ", error.response.data); }
            return Promise.reject(error.response.data);
        }
        if (isTokenValidCheckEnable) {
            if (error.response && error.response.status === HTTP_CODES.UNAUTHORIZED) {
                tokenState.isTokenValid = false;
            } else {
                tokenState.isTokenValid = true;
            }
        }
        if (DEBUG) { console.error("📩 ", error); }
        return Promise.reject(error.message);
    });
    return api;
};

export default middlewareConfig;
