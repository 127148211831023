import { createReducer } from "../../../utils/redux/create-reducer";
import { FORM_STEPS } from "./constants";
import Types from "./types";

export const INITIAL_STATE = {
    activeForm: FORM_STEPS.PERSONAL,
    financePersonalDetail: {},
    financeAddressDetail: {},
    financeIncomeDetail: {},
    checkCfOfferError: null,
    submitFinanceAnswersError: null,
    cfStatus: "",
    userSelectedDp: null,
    userSelectedTenure: null,
    isTenurePopupVisible: false,
    cfTenureData: null
};

export const checkCfOfferSuccess = (state = INITIAL_STATE, { data }) => {
    const {
        selectedOffer,
        bankingSkippable,
        bankingSubmitted,
        cfApproved,
        pincodeServiceable,
        preApproved,
        userVariant,
        userDetailsSubmitted,
        consentRequired,
        cibil
    } = data;

    return {
        ...state,
        selectedOffer,
        bankingSkippable,
        bankingSubmitted,
        cfApproved,
        pincodeServiceable,
        preApproved,
        userVariant,
        userDetailsSubmitted,
        consentRequired,
        cibil
    };
};

export const checkCfOfferFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, checkCfOfferError: error };
};

export const updateActiveForm = (state = INITIAL_STATE, { activeForm }) => {
    return {
        ...state,
        activeForm
    };
};

export const setFinancePersonalDetails = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financePersonalDetail: { ...state.financePersonalDetail, ...data }
    };
};

export const setFinanceAddressDetails = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeAddressDetail: { ...state.financeAddressDetail, ...data }
    };
};

export const setFinanceIncomeDetails = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeIncomeDetail: { ...state.financeIncomeDetail, ...data }
    };
};

const getFinanceFormDataSuccess = (state = INITIAL_STATE, { data }) => {
    const {
        name,
        gender,
        pinCode,
        pancard,
        dateOfBirth,
        addressDetails,
        inComeDetails,
        cibilConsent,
        form1SubmittedBy,
        form2SubmittedBy,
        form3SubmittedBy
    } = data || {};

    const {
        houseType,
        pincode,
        houseNo,
        street,
        city,
        state: stateName
    } = addressDetails || {};

    const {
        employmentType,
        income,
        incomeType,
        monthlyIncome,
        incomeProof,
        emiAmount,
        companyName
    } = inComeDetails || {};

    return {
        ...state,
        financePersonalDetail: {
            nameOnPanCard: name,
            dateOfBirth,
            gender,
            pinCode,
            panNumber: pancard,
            cibilConsent,
            ...state.financePersonalDetail
        },
        financeAddressDetail: {
            houseType,
            houseNo,
            street,
            pincode,
            city,
            state: stateName,
            ...state.financeAddressDetail
        },
        financeIncomeDetail: {
            employmentType,
            monthlyIncome,
            incomeProof,
            companyName,
            income,
            incomeType,
            emiAmount,
            ...state.financeIncomeDetail
        },
        financeAddressDetailFetched: addressDetails ? true : false,
        financeIncomeDetailFetched: inComeDetails ? true : false,
        cfForm1Submitted: form1SubmittedBy,
        cfForm2Submitted: form2SubmittedBy,
        cfForm3Submitted: form3SubmittedBy,
        name
    };
};

export const submitFinanceAnswersSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...(
            data.offer && {
                selectedOffer: data.offer.selectedOffer
            }
        )
    };
};

export const submitFinanceAnswersFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, submitFinanceAnswersError: error };
};

export const clearFormData = (state = INITIAL_STATE) => {
    return {...state, financePersonalDetail: {},
        financeAddressDetail: {},
        financeIncomeDetail: {}
    };
};

export const setCfStatus = (state = INITIAL_STATE, {data}) => {
    return {...state, cfStatus: data};
};

export const setUserSelectedDp = (state = INITIAL_STATE, {userSelectedDp}) => {
    return {...state, userSelectedDp};
};

export const setUserSelectedTenure = (state = INITIAL_STATE, {userSelectedTenure}) => {
    return {...state, userSelectedTenure};
};

export const toggleCfTenurePopup = (state = INITIAL_STATE, {data}) => {
    const { isTenurePopupVisible, cfTenureData } = data || {};
    return {
        ...state,
        isTenurePopupVisible,
        cfTenureData
    };
};

export const HANDLERS = {
    [Types.CHECK_CF_OFFER_SUCCESS]: checkCfOfferSuccess,
    [Types.CHECK_CF_OFFER_FAILURE]: checkCfOfferFailure,
    [Types.UPDATE_ACTIVE_FORM]: updateActiveForm,
    [Types.SET_FINANCE_PERSONAL_DETAILS]: setFinancePersonalDetails,
    [Types.SET_FINANCE_ADDRESS_DETAILS]: setFinanceAddressDetails,
    [Types.SET_FINANCE_INCOME_DETAILS]: setFinanceIncomeDetails,
    [Types.GET_FINANCE_FORM_DATA_SUCCESS]: getFinanceFormDataSuccess,
    [Types.SUMBIT_FINANCE_ANSWERS_SUCCESS]: submitFinanceAnswersSuccess,
    [Types.SUMBIT_FINANCE_ANSWERS_FAILURE]: submitFinanceAnswersFailure,
    [Types.CLEAR_FORM_DATA]: clearFormData,
    [Types.SET_CF_STATUS]: setCfStatus,
    [Types.SET_USER_SELECTED_DP]: setUserSelectedDp,
    [Types.SET_USER_SELECTED_TENURE]: setUserSelectedTenure,
    [Types.TOGGLE_CF_TENURE_POPUP]: toggleCfTenurePopup
};

export default createReducer(INITIAL_STATE, HANDLERS);
