import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Skeleton from "../../shared/h1/skeleton";

const H2Regular = (props) => {
    const { text, color, isLoading, isHtml, hidden } = props;
    let heading;
    if (!isHtml) {
        heading = (<h2 styleName={hidden ? "styles.hidden" : "styles.heading"} style={{ color }}>{text}</h2>);
    } else {
        heading = (<h2 styleName={hidden ? "styles.hidden" : "styles.heading"} style={{ color }} dangerouslySetInnerHTML={
            { __html: text }
        } />);
    }
    if (isLoading) {
        heading = <Skeleton />;
    }
    return heading;
};

H2Regular.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    hidden: PropTypes.bool
};

export default H2Regular;
