const allowedCategories = [
    "POPULAR_CITY",
    "POPULAR_BRANDS",
    "POPULAR_BODY_TYPE",
    "POPULAR_FUEL_TYPE",
    "POPULAR_BUDGET",
    "POPULAR_MODELS",
    "POPULAR_TRANSMISSION_TYPE",
    "POPULAR_ADJACENT_MODELS",
    "POPULAR_FEATURES",
    "POPULAR_OWNER",
    "POPULAR_MODEL_YEAR",
    "POPULAR_HUB"
];
export const getOrderForInterlinkingFilters = (links = []) => {
    const filteredLinks = links.filter((link) => allowedCategories.includes(link.category));
    const sortedOrderOfLinks = filteredLinks.sort((a, b) => {
        const indexA = allowedCategories.indexOf(a.category);
        const indexB = allowedCategories.indexOf(b.category);
        return indexA - indexB;
    });
    return sortedOrderOfLinks;

};
