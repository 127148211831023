import React from "react";
import PropTypes from "prop-types";
import style from "./style.css";

const Overlay = ({
    background,
    opacity,
    onClickHandler,
    children,
    position
}) => {
    const styleOb = {
        opacity,
        background,
        position
    };

    let component = (
        <div styleName="style.overlay" className="pageLoaderOverLay" style={styleOb} onClick={onClickHandler}/>
    );

    if (children) {
        component = (
            <div styleName="style.overlay" className="pageLoaderOverLay" style={styleOb} onClick={onClickHandler}>
                {children}
            </div>
        );
    }
    return component;
};

Overlay.propTypes = {
    background: PropTypes.any,
    opacity: PropTypes.any,
    onClickHandler: PropTypes.func,
    children: PropTypes.any,
    position: PropTypes.any
};
export default  Overlay;
