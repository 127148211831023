export default {
    citySelection: {
        event: "ls_city_click_pop_up",
        eventCategory: "homepage",
        eventAction: "cityPopup",
        eventLabel: "citySelection"
    },
    brandSelectionScreen: {
        event: "ls_brand_click_brand_selection_screen",
        eventCategory: "homepage",
        eventAction: "brandSelectionScreen",
        eventLabel: "brandSelection"
    },
    modelSelectionScreen: {
        event: "ls_model_click_model_selection_creen",
        eventCategory: "homepage",
        eventAction: "modelSelectionScreen",
        eventLabel: "modelSelection"
    },
    variantSelection: {
        event: "ls_variant_click_selection_screen",
        eventCategory: "homepage",
        eventAction: "variantSelectionScreen",
        eventLabel: "variantSelection"
    },
    regStateSelectionScreen: {
        event: "ls_registration_state_selection",
        eventCategory: "homepage",
        eventAction: "regStateSelectionScreen",
        eventLabel: "regStateSelection"
    },
    getMyLocation: {
        event: "ls_get_my_location",
        eventCategory: "location_page",
        eventAction: "getMyLocation"
    },

    topSellingBuyUsedCars: {
        event: "topSelling_buyUsedCar",
        eventAction: "top_selling_cars",
        eventCategory: "homepage"
    }

};
