/* eslint-disable complexity */
import { NUMBER } from "../../constants/app-constants";
import jsonStringValidator from "../helpers/json-string-validator";

const parseSuperWebServices = (list) => {
    return (list || []).map(({image, heading, redirectionUrl}, index) => {
        return {
            iconUrl: image,
            label: heading,
            badgeIcon: "",
            asLink: true,
            href: redirectionUrl,
            withBorder: index < NUMBER.FOUR
        };
    });
};

const getRtoCodeDetails = (list) => {
    if (!list?.length) return [];

    const { code, address, state, phone } = list[0];

    return [
        { label: "RTO office code", text: code },
        { label: "Address", text: address },
        { label: "State", text: state },
        { label: "Contacts", text: phone }
    ].reduce((acc, { label, text }) => {
        if (text) acc.push({ label, text });
        return acc;
    }, []);
};

/* eslint-disable camelcase */
export default ({ detail = {} }) => {
    if (detail && detail.seo_content) {
        const {
            content,
            h1,
            h2,
            content1,
            page_title,
            meta_descriptions,
            og_title,
            og_descriptions,
            template_type,
            jsonContent
        } = detail.seo_content;
        const {
            cms_faq_list: faqList = [],
            rtoDetails = {},
            cmsImageGallery
        } = detail || {};
        const {
            rto = "",
            state = "",
            stateUrl = "",
            stateList = [],
            exclusiveServices = [],
            fuelPrice,
            vehicleSellingDetail = {},
            rtoList = [],
            list = []
        } = rtoDetails || {};
        if (Object.keys(detail || {}).length) {
            return {
                pageType: template_type,
                stateList,
                exclusiveServices,
                fuelPrice: fuelPrice || {},
                vehicleSellingDetail,
                rtoList,
                faqList,
                stateUrl,
                state,
                rto,
                list,
                rtoCodeDetails: getRtoCodeDetails(list),
                jsonContent: jsonStringValidator(jsonContent) ? JSON.parse(jsonContent) : {},
                noContent: false,
                cmsImageGallery: {
                    ...(cmsImageGallery || {}),
                    services: parseSuperWebServices((cmsImageGallery || {}).services || []) || []
                },
                cms: {
                    h1,
                    h2,
                    mainContent: content,
                    subContent: content1,
                    meta: {
                        title: page_title,
                        description: meta_descriptions,
                        ogTitle: og_title,
                        ogDescription: og_descriptions
                    }
                }
            };
        }
        return { noContent: true };
    }
    return { noContent: true };
};
