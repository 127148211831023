import { sanitizeObject } from "../../utils/helpers/sanitizers";

export default (cityDetails) => {
    const { city_list: cityList = [], group_city_list: groupCityList = [] } = cityDetails;

    const sanitizedCityList = (cityList || []).map(city => sanitizeObject(city));
    const sanitizedGroupCityList = (groupCityList || []).map(city => sanitizeObject(city));

    return [...sanitizedCityList, ...sanitizedGroupCityList];
};
