import { createTypes } from "../../utils/redux/create-types";

export default createTypes(
    `
    SET_CONFIG

    FETCH_BUY_CAR_USER_CONFIG_SUCCESS
    FETCH_BUY_CAR_USER_CONFIG_FAILURE

    UPDATE_CITY_LIST_SUCCESS
    UPDATE_CITY_LIST_FAILURE
`,
    {
        prefix: "config/"
    }
);
