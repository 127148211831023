import { createTypes } from "../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_BUY_CAR_PINCODE_CONFIG_SUCCESS
    FETCH_BUY_CAR_PINCODE_CONFIG_FAILURE

    UPDATE_SSR_STATUS
    UPDATE_PINCODE_SSR_STATUS
`,
    {
        prefix: "buycarpincodeconfig/"
    }
);
