//only removes item from an array by picking a value. doesn't support array of objects
export default (arr, value) => {
    const tempArr = [...arr];
    for (let i = 0; i < tempArr.length; i++) {
        if (tempArr[i] === value) {
            tempArr.splice(i, 1);
        }
    }
    return tempArr;
};
