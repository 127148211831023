export const CF_STEP_ID = {
    CF_PITCH: "pitch",
    PERSONAL_DETAILS: "personal-details",
    ADDRESS_DETAILS: "address-details",
    INCOME_DETAILS: "income-details",
    PINCODE_UNSERVICEABLE: "pincode-unserviceable",
    LOAN_ERROR: "loan-error",
    LOAN_OFFER: "loan-offer",
    LOAN_DETAILS: "pitch",
    CF_FORM: "personal-details",
    CF_PITCH_PAGE: "pitch-page"
};

export const CF_FORM_SCREEN_TYPES = {
    PERSONAL: "PERSONAL_DETAILS",
    ADDRESS: "ADDRESS_DETAILS",
    INCOME: "INCOME_DETAILS"
};

export const FORM_LABEL = {
    PERSONAL: "form1",
    ADDRESS: "form2",
    INCOME: "form3"
};

export const CF_LOADER_SCREENS = [CF_STEP_ID.LOAN_OFFER, CF_STEP_ID.LOAN_ERROR];

export const CF_FORM_SCREENS = [CF_STEP_ID.PERSONAL_DETAILS, CF_STEP_ID.ADDRESS_DETAILS, CF_STEP_ID.INCOME_DETAILS];

export const MIN_INCOME_LIMIT = 5000;
export const MAX_INCOME_LIMIT = 500000;

export const DEALER_OPTION = {
    CUSTOMER: "customer",
    DEALER: "dealer"
};

export const LOADER_STEPS = {
    CAR_BUY: "CAR_BUY",
    LOAN: "LOAN",
    LOAN_SNAP: "LOAN_SNAP",
    PAPER_WORK: "PAPER_WORK",
    EXPECTED: "EXPECTED",
    WAIT_FOR_OFFER: "WAIT_FOR_OFFER"
};

export const CF_RTD_STATUS = {
    IN_PROGRESS: "IN_PROGRESS"
};

export const CIBIL_CONSENT_TYPE = {
    CIBIL_SOFT_PULL: "CIBIL_SOFT_PULL",
    CIBIL_HARD_PULL: "CIBIL_HARD_PULL"
};

export const CIBIL_FAILURE_TYPE = {
    NO_RECORD: "NO_RECORD",
    NO_HISTORY: "NO_HISTORY"
};

export const CIBIL_FAILURE_CLICK = {
    [CIBIL_FAILURE_TYPE.NO_RECORD]: "know_more_cibil_not_found_cta",
    [CIBIL_FAILURE_TYPE.NO_HISTORY]: "know_more_ntc_cta"
};

export const CIBIL_FAILURE_CROSS = {
    [CIBIL_FAILURE_TYPE.NO_RECORD]: "know_more_cibil_not_found_cross",
    [CIBIL_FAILURE_TYPE.NO_HISTORY]: "know_more_ntc_cross"
};

export const CIBIL_FAILURE_CTA = {
    [CIBIL_FAILURE_TYPE.NO_RECORD]: "know_more_cibil_not_found_got",
    [CIBIL_FAILURE_TYPE.NO_HISTORY]: "know_more_ntc_got"
};
export const CF_STATUS = {
    PENDING: "PENDING",
    PROGRESS: "PROCESSING",
    CANCELLED: "REJECTED",
    OFFER_GENERATED: "APPROVED",
    FREEZE: "FREEZE",
    DISCARDED: "DISCARDED",
    DUPLICATE: "DUPLICATE",
    NEW: "NEW",
    IN_PROGRESS: "IN_PROGRESS",
    CF_CREDIT_DILIGENCE: "CF_CREDIT_DILIGENCE",
    CF_CREDIT_EXECUTIVE: "CF_CREDIT_EXECUTIVE",
    CF_CREDIT_TL: "CF_CREDIT_TL",
    CF_CREDIT_MANAGER: "CF_CREDIT_MANAGER",
    PRE_APPROVED_BY_MANAGER: "PRE_APPROVED_BY_MANAGER",
    IN_RENEGOTIATION: "IN_RENEGOTIATION",
    FINAL_TNC_ACCEPTED: "FINAL_TNC_ACCEPTED",
    APPROVED_BY_CUSTOMER: "APPROVED_BY_CUSTOMER",
    PRE_FCU: "PRE_FCU",
    OPS: "OPS",
    FCU: "FCU",
    RCU: "RCU"
};

export const progressStatusList = [
    CF_STATUS.NEW,
    CF_STATUS.IN_PROGRESS,
    CF_STATUS.CF_CREDIT_DILIGENCE,
    CF_STATUS.CF_CREDIT_EXECUTIVE,
    CF_STATUS.CF_CREDIT_TL
];

export const offerStatusList = [
    CF_STATUS.PRE_APPROVED_BY_MANAGER,
    CF_STATUS.IN_RENEGOTIATION,
    CF_STATUS.FINAL_TNC_ACCEPTED,
    CF_STATUS.APPROVED_BY_CUSTOMER,
    CF_STATUS.CF_CREDIT_MANAGER,
    CF_STATUS.PRE_FCU,
    CF_STATUS.OPS,
    CF_STATUS.FCU,
    CF_STATUS.RCU
];

export const DEFAULT_MAX_DISBURSAL_LTV = 1;

export const CAR_BUDGET_STEP_SIZE = 50000;

export const MIN_CAR_BUDGET = 80000;

export const MAX_CAR_BUDGET = 3200000;

export const CF_SALE_BANNERS = {
    EMI_OFFER_ICON: `https://fastly-production.24c.in/india/homepage/small-banners/EMI-offer.png`,
    ROI_HALF_BANNER: `https://fastly-production.24c.in/india/homepage/EMI_2/Banner2.png?w=157`,
    ROI_FULL_BANNER: `https://fastly-production.24c.in/india/homepage/EMI_2/Banner4.png`,
    ROI_OFFER_POPUP_BANNER: `https://fastly-production.24c.in/india/homepage/EMI_2/Banner3.png`
};

export const dpSliderError = {
    errorMinValue: "Minimum limit",
    errorMaxValue: "Maximum limit"
};

export const NAME_ERROR = {
    NAME_FORMAT_ERROR: "NAME_FORMAT_ERROR",
    FULL_NAME_ERROR: "FULL_NAME_ERROR"
};

export const TU_CIBIL_PDF = "https://fastly-production.24c.in/cars24/docs/finance/DOC_TU_CIBIL_Terms_of_Use_1683193533.pdf";

export const CF_OFFER_CHANNEL = {
    AMBER: "AMBER",
    GREEN: "GREEN",
    RED: "RED"
};

export const SCREEN_TYPES_FOR_ERROR = {
    CONSENT_SCREEN: "aa_consent_screen",
    FIRST_OTP_SCREEN: "aa_user_otp_authorization",
    ACCOUNT_SELECTION_SCREEN: "aa_account_selection",
    SECOND_OTP_SCREEN: "aa_bank_otp_authorization",
    RECEIVING_STATEMENT_SCREEN: "receiving_statement_screen"
};

export const LOAN_STATE = {
    IN_PROGRESS: "IN_PROGRESS",
    REJECTION: "REJECTION",
    DISCARDED: "DISCARDED"
};

export const PAN_VERIFY_STATUS = {
    VALID: "VALID",
    INVALID: "INVALID"
};

export const PAN_UPLOAD_STATUS = {
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETED: "COMPLETED"
};

export const PAN_VALIDATION_ERROR = {
    DUPLICATE_PAN: "DUPLICATE_PAN",
    PAN_DOB_MISMATCH: "PAN_DOB_MISMATCH",
    PAN_NAME_MISMATCH: "PAN_NAME_MISMATCH",
    PAN_VERIFICATION_FAILED: "PAN_VERIFICATION_FAILED"
};

export const PAN_PHOTO_ERROR_TYPE = {
    IMAGE_SIZE_LIMIT_EXCEED: "IMAGE_SIZE_LIMIT_EXCEED",
    PAN_UPLOAD_FAILURE: "PAN_UPLOAD_FAILURE",
    PAN_UPLOAD_RETRY_LIMIT_EXCEED: "PAN_UPLOAD_RETRY_LIMIT_EXCEED"
};

export const PAN_UPLOAD_ERROR_DETAILS = {
    [PAN_PHOTO_ERROR_TYPE.IMAGE_SIZE_LIMIT_EXCEED]: {
        heading: "Oops! Image size is larger than 25MB",
        text: "Please ensure that the size of the uploaded image  is below 25 MB",
        isRetryAllowed: true,
        eventLabel: "size_issue"
    },
    [PAN_PHOTO_ERROR_TYPE.PAN_UPLOAD_FAILURE]: {
        heading: "Oops! PAN card photo couldn’t be submitted",
        text: "Please try again",
        isRetryAllowed: true,
        eventLabel: "not_submitted"
    },
    [PAN_PHOTO_ERROR_TYPE.PAN_UPLOAD_RETRY_LIMIT_EXCEED]: {
        heading: "We are unable to submit the PAN photo at the moment",
        text: "Don’t worry, you can continue to fill the rest of your details. We will contact you to collect the photo",
        isRetryAllowed: false,
        eventLabel: "continue"
    }
};

export const CF_USER_STATE = {
    PA: "PA",
    NON_PA: "NON_PA"
};

export const CF_ENTRY_LABEL = {
    "buy-used-cars": "HOME_PAGE",
    "car-listing": "LISTING_PAGE",
    "car-detail": "DETAIL_PAGE",
    "facebook-landing": "MARKETING_PAGE"
};

export const CF_OVERLAY_ENTRY_POINT = {
    RECENTELY_VIEWED_CARS: "RECENTELY_VIEWED_CARS",
    BODY_TYPE: "BODY_TYPE",
    FEATURED_CARS: "FEATURED_CARS",
    RECOMMENDED_CARS: "RECOMMENDED_CARS",
    POPULAR_CARS: "POPULAR_CARS",
    SIMILAR_CARS: "SIMILAR_CARS",
    OTHER_YARD_CARS: "OTHER_YARD_CARS",
    EXPLORE_MORE: "EXPLORE_MORE",
    STILL_CANT_DECIDE: "STILL_CANT_DECIDE",
    RECENTLY_ADDED_CARS: "RECENTLY_ADDED_CARS",
    BEST_MATCHES_CARS: "BEST_MATCHES_CARS"
};

export const CF_AA_SCREEN_TYPE = {
    BANK_LOGO: "BANK_LOGO",
    BANK_SEARCH: "BANK_SEARCH",
    CHANGE_MOBILE: "CHANGE_MOBILE",
    FINVU_OTP: "FINVU_OTP",
    FINVU_ACCOUNTS: "FINVU_ACCOUNTS",
    BANK_OTP: "BANK_OTP",
    NO_BANK_ACCOUNT: "NO_BANK_ACCOUNT",
    PAGE_LOAD: "PAGE_LOAD"
};

export const EMP_TYPE = {
    SALARIED: "SALARIED"
};

export const numberRegex = /^\d+$/;

export const CIBIL_STATUS = {
    CF_PASSED: "CF_PASSED"
};

