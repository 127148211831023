import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isVisible: false,
    popUpType: "",
    continueWithoutFinance: null,
    currentScreenType: ""
};

export const updateCFPopUpType = (state = INITIAL_STATE, { data }) => {
    return { ...state, popUpType: data };
};

export const setWithoutFinanceFlow = (state = INITIAL_STATE, { data }) => {
    return { ...state, continueWithoutFinance: data };
};

export const setCurrentScreenType = (state = INITIAL_STATE, { data }) => {
    return { ...state, currentScreenType: data };
};

export const setShowCfPopup = (state = INITIAL_STATE, { data }) => {
    return { ...state, isVisible: data};
};

export const HANDLERS = {
    [Types.UPDATE_CF_POPUP_TYPE]: updateCFPopUpType,
    [Types.SET_WITHOUT_FINANCE_FLOW]: setWithoutFinanceFlow,
    [Types.SET_CURRENT_SCREEN_TYPE]: setCurrentScreenType,
    [Types.SET_SHOW_CF_POPUP]: setShowCfPopup
};

export default createReducer(INITIAL_STATE, HANDLERS);
