import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import { FILTER_TAB_TYPES } from "../../../utils/filters-v2-listing/constants";
import { getUpdatedAppliedFilters, cleatSelectedFilterCategory } from "../../../utils/filters-v3/get-updated-applied-filters";
import { getPreAppliedFilter } from "../../../utils/filters-v3/get-pre-applied-filters";
import { FILTER_CATEGORY } from "../../../utils/filters-v3/constant";

export const INITIAL_STATE = {
    isSSR: false,
    isLoading: false,
    isFetched: false,
    data: [],
    selectedOnScreenFilter: "",
    appliedFilters: {},           // Filters which are applied in the listing.
    selectedFilters: {},           // Filters which are selected but not yet applied in the listing
    clearModal: {
        isClearModal: false,
        isAppliedFilter: true
    },
    filterCount: 0,
    lastAppliedFilters: {},
    activeFilterTab: FILTER_TAB_TYPES.FILTER,
    recentSearches: [],
    isIndiaPage: false,
    indiaPageUrl: "",
    filterSearchQuery: null,
    filterCollapsedState: [FILTER_CATEGORY.MAKE_MODEL, FILTER_CATEGORY.BUDGET],
    fetchedFilterCityId: null,
    filterAppliedFromFilterPage: false
};

export const updateFilterOptionData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedFilters: getUpdatedAppliedFilters(data, state.selectedFilters)
    };
};

export const fetchFiltersInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const fetchFiltersSuccess = (state = INITIAL_STATE, { widgets, count }) => {
    return {
        ...state,
        isFetched: true,
        isLoading: false,
        data: widgets,
        filterCount: count
    };
};

export const fetchFiltersFailure = (state) => {
    return {
        ...INITIAL_STATE,
        isFetched: true,
        data: [],
        isLoading: false,
        fetchedFilterCityId: state.fetchedFilterCityId
    };
};

export const updateSelectedOnScreenFilter = (state = INITIAL_STATE, { selectedOnScreenFilter }) => {
    return {
        ...state,
        selectedOnScreenFilter
    };
};

export const updateAppliedFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        appliedFilters: state.selectedFilters,
        selectedFilters: {}
    };
};

export const updateSSRAppliedFilters = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        appliedFilters: getPreAppliedFilter(data, state.data),
        selectedFilters: getPreAppliedFilter(data, state.data)
    };

};

export const clearSelectedFilterCategory = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedFilters: cleatSelectedFilterCategory(data, state.selectedFilters)
    };
};

export const clearAppliedFilterCategory = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        appliedFilters: cleatSelectedFilterCategory(data, state.appliedFilters)
    };
};

export const copyAppliedToSelectedFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        selectedFilters: state.appliedFilters
    };
};

export const updateFilterSSR = (state = INITIAL_STATE, { isSSR }) => {
    return {
        ...state,
        isSSR
    };
};

export const resetFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        data: []
    };
};

export const setIsIndiaPage = (state = INITIAL_STATE, {isIndiaPage}) => {
    return {
        ...state,
        isIndiaPage
    };
};

export const setIndiaPageUrl = (state = INITIAL_STATE, {indiaPageUrl}) => {
    return {
        ...state,
        indiaPageUrl
    };
};

export const updateFilterTabType = (state = INITIAL_STATE, { activeFilterTab }) => {
    return {
        ...state,
        activeFilterTab
    };
};

export const updateFilterState = (state = INITIAL_STATE, { data }) => {
    if (state.filterCollapsedState.includes(data)) {
        const filteredData = state.filterCollapsedState.filter(item => item !== data);
        return {
            ...state,
            filterCollapsedState: filteredData
        };
    } else {
        return {
            ...state,
            filterCollapsedState: [...state.filterCollapsedState, data]
        };
    }
};

export const setFetchedFilterCityId = (state = INITIAL_STATE, { fetchedFilterCityId }) => {
    return {
        ...state,
        fetchedFilterCityId
    };
};
export const clearBulkFilter = (state = INITIAL_STATE) => {
    return {
        ...state,
        selectedFilters: {},
        appliedFilters: {}
    };
};

export const updateSelectedFilters = (state = INITIAL_STATE, { selectedFilters }) => {
    return {
        ...state,
        selectedFilters
    };
};

export const resetAppliedFilters = (state = INITIAL_STATE, { isResetSelectedFilter = true, isResetAppliedFilter = true}) => {
    return {
        ...state,
        ...(isResetSelectedFilter && {selectedFilters: {}}),
        ...(isResetAppliedFilter && {appliedFilters: {}})
    };
};

export const setFilterAppliedFromFilterPage  = (state = INITIAL_STATE, { filterAppliedFromFilterPage }) => {
    return {
        ...state,
        filterAppliedFromFilterPage
    };
};

export const HANDLERS = {
    [Types.UPDATE_FILTER_OPTION_DATA]: updateFilterOptionData,
    [Types.FETCH_FILTERS]: fetchFiltersInit,
    [Types.FETCH_FILTERS_SUCCESS]: fetchFiltersSuccess,
    [Types.FETCH_FILTERS_FAILURE]: fetchFiltersFailure,
    [Types.UPDATE_SELECTED_ON_SCREEN_FILTER]: updateSelectedOnScreenFilter,
    [Types.UPDATE_APPLIED_FILTERS]: updateAppliedFilters,
    [Types.UPDATE_SSR_APPLIED_FILTER]: updateSSRAppliedFilters,
    [Types.COPY_APPLIED_TO_SELECTED_FILTER]: copyAppliedToSelectedFilters,
    [Types.CLEAR_SELECTED_FILTER_CATEGORY]: clearSelectedFilterCategory,
    [Types.CLEAR_APPLIED_FILTER_CATEGORY]: clearAppliedFilterCategory,
    [Types.UPDATE_FILTER_SSR]: updateFilterSSR,
    [Types.RESET_FILTERS]: resetFilters,
    [Types.SET_INDIA_PAGE]: setIsIndiaPage,
    [Types.SET_INDIA_PAGE_URL]: setIndiaPageUrl,
    [Types.UPDATE_FILTER_TAB_TYPE]: updateFilterTabType,
    [Types.UPDATE_FILTER_STATE]: updateFilterState,
    [Types.SET_FETCHED_FILTER_CITY_ID]: setFetchedFilterCityId,
    [Types.CLEAR_BULK_FILTER]: clearBulkFilter,
    [Types.UPDATE_SELECTED_FILTERS]: updateSelectedFilters,
    [Types.RESET_APPLIED_FILTERS]: resetAppliedFilters,
    [Types.UPDATE_FILTER_APPLIED_FROM_FILTER]: setFilterAppliedFromFilterPage
};

export default createReducer(INITIAL_STATE, HANDLERS);
