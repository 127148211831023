import auth from "./authApi";

const authApi = (api) => {
    const whoAmI = () => {
        return api.get("/pp/ums/users");
    };

    const sendOTP = (mobile, xCountryCode = "IN", c2bApiKey) => {
        return api.post("pp/auth/mobile/otp/send/", null, {
            headers: {
                phone_number: mobile,
                X_COUNTRY: xCountryCode
            },
            c2bApiKey
        },

        );
    };

    const verifyOTP = (params, c2bApiKey) => {
        return api.post("/pp/auth/mobile/otp/verify/", params, { c2bApiKey });
    };

    const updateProfile = (params) => {
        return api.put("/pp/ums/users", params);
    };

    const login = (params, c2bApiKey) => {
        return api.get(`/pp/auth/login`, { params, c2bApiKey });
    };

    const updateKycDocument = (token, documentType, attachments) => {
        return api.post("/pp/user/kyc", {
            documentType,
            attachments: {
                BACK: attachments.back,
                FRONT: attachments.front
            }
        }, {
            headers: {
                Authorization: `${token}`
            }}
        );
    };
    const updateDocument = (token, params) => {
        return api.put("/pp/document", params, {
            headers: {
                Authorization: `${token}`,
                "content-type": "multipart/form-data;"
            }
        });
    };

    const getUploadedDocument = (token, documentType) => {
        return api.get("/pp/document", documentType, {headers: {Authorization: `${token}`}});
    };

    const getDocument = (token, documentType) => {
        return api.get(`/pp/user/kyc`, { params: { documentType }, headers: {
            Authorization: `${token}`
        } });
    };

    const uploadFile = (url, data) => {
        return api.post(url, data);
    };

    const autoLogin = (params) => {
        return api.post(`/pp/auth/auto-login`, params);
    };

    const logout = () => {
        return api.post(`/pp/auth/logout`);
    };

    return {
        whoAmI,
        sendOTP,
        verifyOTP,
        updateProfile,
        login,
        updateKycDocument,
        updateDocument,
        getUploadedDocument,
        getDocument,
        uploadFile,
        autoLogin,
        logout
    };
};

export const AuthService = authApi(auth);
