import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import removeItemFromArray from "../../../utils/helpers/remove-item-from-array";

export const INITIAL_STATE = {
    data: [],
    flattenedData: [],
    carouselData: [],
    error: null,
    isFetching: false,
    activeCarId: null,
    wishlistCount: 0,
    addToWishlistCarId: null,
    removeFromWishlistCarId: null,
    wishlistData: {
        content: [],
        cities: {}
    }
};

export const fetchWishlistedCars = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFetching: true
    };
};

export const fetchWishlistedCarsSuccess = (state = INITIAL_STATE, { data }) => {
    const { content } = data || {};
    const wishlistCount = (content || []).length;
    return {
        ...state,
        isFetching: false,
        wishlistData: { ...data },
        wishlistCount
    };
};

export const fetchWishlistedCarsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error,
        isFetching: false,
        data: [],
        flattenedData: []
    };
};

export const addToWishlistSuccess = (state = INITIAL_STATE, { carId }) => {
    return {
        ...state,
        flattenedData: [
            ...state.flattenedData,
            carId
        ]
    };
};

export const addToWishlistCarID = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        addToWishlistCarId: data
    };
};

export const removeFromWishListSuccess = (state = INITIAL_STATE, { carId }) => {
    return {
        ...state,
        flattenedData: removeItemFromArray(state.flattenedData, carId)
    };
};

export const removeFromWishlistCarID = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        removeFromWishlistCarId: data
    };
};

export const setWishlistAppIds = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        flattenedData: [...data]
    };
};

export const HANDLERS = {
    [Types.FETCH_WISHLISTED_CARS]: fetchWishlistedCars,
    [Types.FETCH_WISHLISTED_CARS_SUCCESS]: fetchWishlistedCarsSuccess,
    [Types.FETCH_WISHLISTED_CARS_FAILURE]: fetchWishlistedCarsFailure,
    [Types.ADD_TO_WISHLIST_SUCCESS]: addToWishlistSuccess,
    [Types.REMOVE_FROM_WISHLIST_SUCCESS]: removeFromWishListSuccess,
    [Types.ADD_TO_WISHLIST_CAR_ID]: addToWishlistCarID,
    [Types.REMOVE_FROM_WISHLIST_CAR_ID]: removeFromWishlistCarID,
    [Types.SET_WISHLIST_APP_IDS]: setWishlistAppIds
};

export default createReducer(INITIAL_STATE, HANDLERS);
