import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SHOW_TOAST
    HIDE_TOAST
`,
    {
        prefix: "toastmessage/"
    }
);
