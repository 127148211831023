const getDeviceType = () => {
    if (typeof window === "undefined") {
        return {type: null, source: null};
    } else if (window.navigator.userAgent.match(/mobile|iPad|Android|Touch/i)) {
        return {type: "Mobile", source: "mSite"};
    } else {
        return {type: "Desktop", source: "WebApp"};
    }
};

export {
    getDeviceType
};
