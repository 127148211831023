import oridinalSuffix from "./oridinal-suffix";
import { NUMBER } from "../../constants/app-constants";
import getDateTimeformat from "./get-date-time-format";

const DAY_LIST = [
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
];

const ABBR_DAY_LIST = [
    "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
];

export const getWeekDay = (date = new Date(), abbr = false) => {
    const dayList = abbr ? ABBR_DAY_LIST : DAY_LIST;
    return dayList[date.getDay()];
};

export const getParticularDay = (date = new Date()) => {
    return date.getDate();
};

export const subMonths = (date = new Date(), n = 0) => {
    try {
        const dateCopy = new Date(date);
        dateCopy.setMonth(dateCopy.getMonth() - n);
        return dateCopy;
    } catch (_) {
        return "Invalid date";
    }
};

export const addMonths = (date = new Date(), n = 0) => {
    try {
        const dateCopy = new Date(date);
        dateCopy.setMonth(dateCopy.getMonth() + n);
        return dateCopy;
    } catch (_) {
        return "Invalid date";
    }
};
export const differenceInDays = (dateLater = new Date(), dateEarlier = new Date()) => {
    try {
        const diffTime =  Math.abs(new Date(dateLater) - new Date(dateEarlier));
        return Math.ceil(diffTime / (NUMBER.THOUSAND * NUMBER.SIXTY * NUMBER.SIXTY * NUMBER.TWENTY_FOUR));
    } catch (_) {
        return null;
    }
};

const MONTH_LIST = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const ABBR_MONTH_LIST = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

export const getMonth = (date = new Date(), abbr = false) => {
    const monthList = abbr ? ABBR_MONTH_LIST : MONTH_LIST;
    return monthList[date.getMonth()];
};

export const getDayWithMonth = (date = new Date(), ordinal = true) => {
    return `${ordinal ? oridinalSuffix(date.getDate()) : date.getDate()} ${getMonth(date)}`;
};

export const getAppointmentBookedDate = (date = new Date(), ordinal = true) => {
    return `${ordinal ? oridinalSuffix(date.getDate()) : date.getDate()} ${getMonth(date, true)} ${date.getFullYear()}`;
};

export const getTwelveHourFormat = (date = new Date()) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Check whether AM or PM
    const format = hours >= NUMBER.TWELVE ? "PM" : "AM";

    // Find current hour in AM-PM Format
    hours %= NUMBER.TWELVE;

    // To display "0" as "12"
    hours = hours ? hours : NUMBER.TWELVE;
    minutes = minutes < NUMBER.TEN ? `0${minutes}` : minutes;
    return {
        string: `${hours}:${minutes} ${format}`,
        hours,
        minutes,
        format,
        fullDayHours: date.getHours()
    };
};

export const getDayMonthYear = (date = new Date()) => {
    return ` ${getParticularDay(date)} ${getMonth(date)} ${date.getFullYear()}`;
};

export const getBookingDateAndTime = (date = new Date(), showTime = true) => {
    return ` ${getParticularDay(date)}-${getMonth(date, true)}-${date.getFullYear()}${showTime ? `, ${getTwelveHourFormat(date).string}` : ``}`;
};

export const getDeliveryDate = (date = new Date()) => {
    return ` ${getParticularDay(date)}-${getMonth(date, true)}-${date.getFullYear()}`;
};

export const getCustomDeliveryReturnDate = (date = new Date()) => {
    return `${getWeekDay(date, true)}, ${getParticularDay(date)} ${getMonth(date, true)} ${date.getFullYear()}`;
};
export const getDateWithMonthFormat = (date = new Date()) => {
    return (date instanceof Date && !isNaN(date)) && ` ${getParticularDay(date)} ${getMonth(date, true)}, ${date.getFullYear()}`;
};
export const getReadableDate = (dateString = "") => {
    if (!dateString) return "";
    const parts = dateString.split("-");
    if (parts.length !== NUMBER.THREE) return "";
    const date = new Date(parts[NUMBER.TWO], parts[NUMBER.ZERO] - NUMBER.ONE, parts[NUMBER.ONE]);
    return getDateWithMonthFormat(date);
};
/**
 *
 * @param {String|Object} date This is the date from api
 * @returns {String} Wednesday,12th May, 02:20 PM
 */
export const getDayDateTime = (date = new Date()) => {
    try {
        return getDateTimeformat(new Date(date), {
            weekday: "long",
            month: "long",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
        });
    } catch (error) {
        return "Invalid date";
    }
};

/**
 *
 * @param {Number} n This is the previous month
 * @returns {String} Jan-2021
 */
export const getPrevDate = (n) => {
    return getDateTimeformat(subMonths(new Date(), n), {
        month: "short",
        year: "2-digit"
    }).replace(/\s/g, "-");
};

export const getDate = (date = new Date()) => {
    try {
        return getDateTimeformat(new Date(date), {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }).replace(/\//g, "-").split("-").reverse().join("-");
    } catch (error) {
        return "Invalid date";

    }
};

export const getDateAndTime = (date = new Date()) => {
    const multiplyer = 1000;
    try {
        if (typeof date === "number") {
            if (differenceInDays(new Date(), new Date(date * multiplyer))) {
                return getDateTimeformat(new Date(date * multiplyer), {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                }).replace(",", "");
            } else {
                return `Today ${(new Date(date * multiplyer)).toLocaleTimeString("en-IN", { hour: "2-digit", minute: "2-digit" })}`;
            }
        } else {
            return getDateTimeformat(new Date(date), {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
            }).replace(",", "");
        }
    } catch (error) {
        return "Invalid date";

    }
};

/**
 * @param {Number} n this is number of days
 * @returns {String} 1 year
 */

// eslint-disable-next-line max-statements
export const calculateYears = d => {
    // let months = 0;
    let years = 0;
    // let days = 0;
    // let weeks = 0;
    const year = 365;
    // const month = 30;
    // const week = 7;
    while (d) {
        if (d >= year) {
            years++;
            d -= year;
        }
    }
    return `${years} years`;
};

export const getRepairDateAndTime = (date = new Date(), showTime = true) => {
    return ` ${getParticularDay(date)} ${getMonth(date)} ${showTime ? `| ${getTwelveHourFormat(date).string}` : ``}`;
};

export const getCustomDeliveryRepairDate = (date = new Date()) => {
    return `${getWeekDay(date, true)} - ${getParticularDay(date)} ${getMonth(date, true)}`;
};

/**
 *
 * @param {Date} left this can be the date from which you want difference
 * @param {Date} right this can be current date
 * @returns {Number} 1
 */
export const dateDifferenceInDays = (left, right) => {
    return differenceInDays(new Date(left), new Date(right));
};

// export const getDayWithAbbrMonth = (date = new Date(), ordinal = true) => {
//     return `${ordinal ? oridinalSuffix(date.getDate()) : date.getDate()} ${getMonth(date, true)}`;
// };

export const getDayWithAbbrMonth = (date = new Date(), ordinal = true) => {
    try {
        return `${ordinal ? oridinalSuffix(date.getDate()) : date.getDate()} ${getMonth(date, true)}`;
    } catch (error) {
        return "Invalid date";
    }
};

export const getRepairReturnDate = (date = new Date()) => {
    return ` ${getParticularDay(date)} ${getMonth(date, true)} ${date.getFullYear()}`;
};

export const getTokenExpiryDate = (date = new Date()) => {
    return ` ${getParticularDay(date)} ${getMonth(date, true)} ${date.getFullYear()}`;
};

export const getTestDriveDate = (date = new Date()) => {
    return `${getWeekDay(date)}, ${getParticularDay(date)} ${getMonth(date, true)}`;
};

export const isSameDay = (left, right) => {
    return new Date(left).toDateString() === new Date(right).toDateString();
};

export const getDayAbbrMonthYear = (date = new Date()) => {
    return ` ${getParticularDay(date)} ${getMonth(date, true)}, ${date.getFullYear()}`;
};

export const getPrevDateV2 = (n) => {
    return getDateTimeformat(subMonths(new Date(), n), {
        month: "short"
    });
};

export const getNthMonth = (date = new Date(), abbr = false, n = 0) => {
    const monthList = abbr ? ABBR_MONTH_LIST : MONTH_LIST;
    return monthList[date.getMonth() - n];
};

export const getRefundDateAndTime = (date = new Date(), showTime = true) => {
    return ` ${getParticularDay(date)} ${getMonth(date, true)} ${date.getFullYear()}${showTime ? `, ${getTwelveHourFormat(date).string}` : ``}`;
};

export const getDayMonth = (date = new Date()) => {
    return ` ${getParticularDay(date)} ${getMonth(date)}`;
};
