import React, { Fragment } from "react";
import RupeeIcon from "./images/money.svg";

const AdRupeeIcon = () => {
    return (
        <Fragment>
            <img src={RupeeIcon} alt="thunder icon"/>
        </Fragment>
    );
};

export default AdRupeeIcon;
