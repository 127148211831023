import React from "react";
import PropTypes from "prop-types";
import BlueCard from "../../shared/blue-card";
import AdCardContent from "../../shared/ad-card-content/component";
import AdShieldIcon from "../../shared/ad-shield-icon";
import AdShieldLineIcon from "../../shared/ad-shield-line-icon";
import { sendCustomGaEvents } from "../../../tracking";
import { adWarranty } from "./tracking-constants";

const AdWarrantyCard = ({
    onClickWarranty = () => {}
}) => {
    const onClick = () => {
        sendCustomGaEvents(adWarranty);
        onClickWarranty();
    };

    return (
        <BlueCard>
            <AdCardContent
                onClickCallback={onClick}
                headingText="12-Months Free Warranty!"
                details="Free up to 12-Month warranty plans on all cars to safeguard
                 you from unforeseen repair and maintenance costs"
                buttonText="KNOW MORE"
                smalltext=""
                icon={AdShieldIcon}
                titleIcon={AdShieldLineIcon}
            />
        </BlueCard>
    );
};

AdWarrantyCard.propTypes = {
    onClickWarranty: PropTypes.func
};

export default AdWarrantyCard;
