export default (data) => {
    let payload = {cityId: 0, content: [], baseUrl: "", cityCode: "", totalElements: 0, hubs: {}, cities: {}, cityName: "", pincode: ""};
    const {listing: {content = [], baseUrl = "", cityId = 0, cityCode = "", page: { totalElements = 0} = {}, hubs = {}, cities = {} } = {}, seoLink: {url = ""} = {}} = data || {};
    payload  = {
        cityId,
        content,
        baseUrl,
        cityCode,
        totalElements,
        hubs,
        cities,
        cityName: cities[cityId].name,
        pincode: cities[cityId].pincode,
        seoLinkUrl: url
    };
    return payload;
};
