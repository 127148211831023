import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_RECENTLY_VIEWED_CARS_LIST_SUCCESS
    FETCH_RECENTLY_VIEWED_CARS_LIST_FAILURE

    SET_RECENTLY_VIEWED_CAR_SUCCESS
    SET_RECENTLY_VIEWED_CAR_FAILURE
`,
    {
        prefix: "recentlyviewedcarlist/"
    }
);
