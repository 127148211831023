export const FILTER_ENTITIES = {
    CITY: "city",
    BUDGET: "listingPrice",
    BODY_TYPE: "bodyType",
    FUEL_TYPE: "fuelType",
    CAR_NAME: "carName",
    PARKING_YARD: "parkingYardId",
    OWNERS: "ownerNumber",
    ASSURED: "isC24Assured",
    ODOMETER_READING: "odometerReading",
    TRANSMISSION: "transmission",
    REGISTRATION_CITY: "registrationCity",
    HOT_DEAL: "hotDeal",
    TOP_SELLING: "isTopSelling",
    YEAR: "year",
    FINANCE: "isCFEnabled",
    CAR_SOURCE: "carSource",
    WARRANTY_AVAILABLE: "isWarrantyAvailable",
    THREE_SIXTY_VIEW: "threeSixtyViewAvailable",
    FINANCE_EMI: "emi",
    FINANCE_DOWNPAYMENT: "downPayment",
    DISCOUNT: "discount",
    ROI_DISCOUNT: "roiDiscount"
};

export const DOWN_ARROW_ROTATE_DEGREE = 90;
export const UP_ARROW_ROTATE_DEGREE = -90;

export const FILTER_TAB_TYPES = {
    FILTER: "FILTER",
    SORT: "SORT"
};

export const FILTER_PRIORITY = [
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.CAR_NAME,
    FILTER_ENTITIES.BUDGET,
    FILTER_ENTITIES.OWNERS
];

export const ON_SCREEN_FILTERS = {
    PRICE: "Budget / Finance",
    PRICE_ONLY: "Price",
    YEAR: "Model Year",
    KMS: "KMs Driven",
    MAKE_MODEL: "Make & Model",
    REGISTRATION_CITY: "RTO",
    FUEL_TYPE: "Fuel",
    TRANSMISSION: "Transmission",
    BODY_TYPE: "Body Type",
    OWNERS: "Owners",
    EMI: "EMI",
    DOWNPAYMENT: "Down Payment",
    LOCATION_HUB: "CARS24 Hubs",
    PRICE_DISCOUNT: "Price Discount",
    ROI_DISCOUNT: "EMI Discount"
};

export const OFF_SCREEN_FILTERS = {
    FUEL_TYPE: "Fuel Type",
    OWNERS: "No of Owners",
    TRANSMISSION: "Transmission",
    BODY_TYPE: "Body Type"
};

export const ON_SCREEN_FILTERS_LIST = [
    ON_SCREEN_FILTERS.MAKE_MODEL,
    ON_SCREEN_FILTERS.PRICE,
    ON_SCREEN_FILTERS.FUEL_TYPE,
    ON_SCREEN_FILTERS.YEAR,
    ON_SCREEN_FILTERS.REGISTRATION_CITY,
    ON_SCREEN_FILTERS.BODY_TYPE,
    ON_SCREEN_FILTERS.TRANSMISSION,
    ON_SCREEN_FILTERS.OWNERS,
    ON_SCREEN_FILTERS.KMS,
    ON_SCREEN_FILTERS.LOCATION_HUB,
    ON_SCREEN_FILTERS.PRICE_DISCOUNT,
    ON_SCREEN_FILTERS.ROI_DISCOUNT
];

export const OFF_SCREEN_FILTERS_LIST = [
    OFF_SCREEN_FILTERS.FUEL_TYPE,
    OFF_SCREEN_FILTERS.OWNERS,
    OFF_SCREEN_FILTERS.TRANSMISSION,
    OFF_SCREEN_FILTERS.BODY_TYPE
];

export const ON_SCREEN_FILTERS_LIST_DESKTOP = [
    ON_SCREEN_FILTERS.PRICE_ONLY,
    ON_SCREEN_FILTERS.YEAR,
    ON_SCREEN_FILTERS.KMS,
    ON_SCREEN_FILTERS.MAKE_MODEL,
    ON_SCREEN_FILTERS.REGISTRATION_CITY
];

export const OFF_SCREEN_FILTERS_LIST_DESKTOP = [
    OFF_SCREEN_FILTERS.FUEL_TYPE,
    OFF_SCREEN_FILTERS.OWNERS,
    OFF_SCREEN_FILTERS.TRANSMISSION,
    OFF_SCREEN_FILTERS.BODY_TYPE
];

export const FILTER_V2_MAP = {
    [ON_SCREEN_FILTERS.PRICE]: FILTER_ENTITIES.BUDGET,
    [ON_SCREEN_FILTERS.PRICE_ONLY]: FILTER_ENTITIES.BUDGET,
    [ON_SCREEN_FILTERS.YEAR]: FILTER_ENTITIES.YEAR,
    [ON_SCREEN_FILTERS.KMS]: FILTER_ENTITIES.ODOMETER_READING,
    [ON_SCREEN_FILTERS.MAKE_MODEL]: FILTER_ENTITIES.CAR_NAME,
    [ON_SCREEN_FILTERS.REGISTRATION_CITY]: FILTER_ENTITIES.REGISTRATION_CITY,
    [ON_SCREEN_FILTERS.FUEL_TYPE]: FILTER_ENTITIES.FUEL_TYPE,
    [ON_SCREEN_FILTERS.OWNERS]: FILTER_ENTITIES.OWNERS,
    [ON_SCREEN_FILTERS.TRANSMISSION]: FILTER_ENTITIES.TRANSMISSION,
    [ON_SCREEN_FILTERS.BODY_TYPE]: FILTER_ENTITIES.BODY_TYPE,
    [ON_SCREEN_FILTERS.EMI]: FILTER_ENTITIES.FINANCE_EMI,
    [ON_SCREEN_FILTERS.DOWNPAYMENT]: FILTER_ENTITIES.FINANCE_DOWNPAYMENT,
    [ON_SCREEN_FILTERS.LOCATION_HUB]: FILTER_ENTITIES.PARKING_YARD,
    [ON_SCREEN_FILTERS.PRICE_DISCOUNT]: FILTER_ENTITIES.DISCOUNT,
    [ON_SCREEN_FILTERS.ROI_DISCOUNT]: FILTER_ENTITIES.ROI_DISCOUNT

};

export const FILTER_V2_MAP_REVERSE = {
    [FILTER_ENTITIES.BUDGET]: ON_SCREEN_FILTERS.PRICE,
    [FILTER_ENTITIES.BUDGET]: ON_SCREEN_FILTERS.PRICE_ONLY,
    [FILTER_ENTITIES.YEAR]: ON_SCREEN_FILTERS.YEAR,
    [FILTER_ENTITIES.ODOMETER_READING]: ON_SCREEN_FILTERS.KMS,
    [FILTER_ENTITIES.CAR_NAME]: ON_SCREEN_FILTERS.MAKE_MODEL,
    [FILTER_ENTITIES.REGISTRATION_CITY]: ON_SCREEN_FILTERS.REGISTRATION_CITY,
    [FILTER_ENTITIES.FUEL_TYPE]: ON_SCREEN_FILTERS.FUEL_TYPE,
    [FILTER_ENTITIES.OWNERS]: ON_SCREEN_FILTERS.OWNERS,
    [FILTER_ENTITIES.TRANSMISSION]: ON_SCREEN_FILTERS.TRANSMISSION,
    [FILTER_ENTITIES.BODY_TYPE]: ON_SCREEN_FILTERS.BODY_TYPE,
    [FILTER_ENTITIES.FINANCE_EMI]: ON_SCREEN_FILTERS.EMI,
    [FILTER_ENTITIES.FINANCE_DOWNPAYMENT]: ON_SCREEN_FILTERS.DOWNPAYMENT,
    [FILTER_ENTITIES.PARKING_YARD]: ON_SCREEN_FILTERS.LOCATION_HUB,
    [FILTER_ENTITIES.DISCOUNT]: ON_SCREEN_FILTERS.PRICE_DISCOUNT,
    [FILTER_ENTITIES.ROI_DISCOUNT]: ON_SCREEN_FILTERS.ROI_DISCOUNT
};

export const MAKEORMODEL = {
    MAKE: "make",
    MODEL: "model"
};

export const PRICE_FILTER_TYPE = {
    PRICE: FILTER_ENTITIES.BUDGET,
    EMI: FILTER_ENTITIES.FINANCE_EMI
};

export const ALL_FILTERS_REVAMP = [
    FILTER_ENTITIES.CAR_NAME,
    FILTER_ENTITIES.BUDGET,
    FILTER_ENTITIES.YEAR,
    FILTER_ENTITIES.BODY_TYPE,
    FILTER_ENTITIES.ODOMETER_READING,
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.TRANSMISSION
];

// export const PRIMARY_FILTER_PRIORITY = [
//     FILTER_ENTITIES.BUDGET,
//     FILTER_ENTITIES.CAR_NAME,
//     FILTER_ENTITIES.ASSURED,
//     FILTER_ENTITIES.PARKING_YARD,
//     FILTER_ENTITIES.FINANCE,
//     FILTER_ENTITIES.ODOMETER_READING,
//     FILTER_ENTITIES.YEAR,
//     FILTER_ENTITIES.FUEL_TYPE,
//     FILTER_ENTITIES.TRANSMISSION,
//     FILTER_ENTITIES.BODY_TYPE,
//     FILTER_ENTITIES.OWNERS,
//     FILTER_ENTITIES.REGISTRATION_CITY
// ];

// export const RESETTABLE_FILTER_ENTITIES = [
//     FILTER_ENTITIES.PARKING_YARD,
//     FILTER_ENTITIES.REGISTRATION_CITY
// ];
