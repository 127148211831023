
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    headerVisibility: false,
    buyCarsHeaderData: {},
    isSSR: false
};

export const setHeaderVisibility = (state = INITIAL_STATE, { headerVisibility }) => {
    return { ...state, headerVisibility };
};

export const setBuyCarsHeaderData = (state = INITIAL_STATE, data) => {
    return { ...state, buyCarsHeaderData: data };
};

export const updateSSR = (state = INITIAL_STATE, { isSSR }) => {
    return {...state, isSSR };
};

export const HANDLERS = {
    [Types.SET_HEADER_VISIBILITY]: setHeaderVisibility,
    [Types.SET_BUY_CARS_HEADER_DATA]: setBuyCarsHeaderData,
    [Types.UPDATE_SSR]: updateSSR
};

export default createReducer(INITIAL_STATE, HANDLERS);
