/* eslint-disable max-statements */
import { CLASSIFIED_IMAGE_PAYLOAD_MAP } from "../../constants/classified-constants";

export default (response) => {
    const parsedResponse = {
        ...response
    };
    if (response.data && response.data.detail && response.data.detail.classified) {
        const status = response.data.detail.classified.status;
        parsedResponse.data.detail.classified.status = isNaN(status) ? null :
            parseInt(status);
        const policyValidity = response.data.detail.classified.policy_validity;
        if (policyValidity) {
            const date = new Date(policyValidity);
            parsedResponse.data.detail.classified.insuranceYear = date.getFullYear().toString();
            parsedResponse.data.detail.classified.insuranceMonth = date.getMonth() + 1;
        }
        const media = response.data.detail.classified.media;
        const mediaHost = response.data.detail.classified.media_host;
        if (media) {
            const images = {};
            media.forEach((item) => {
                images[CLASSIFIED_IMAGE_PAYLOAD_MAP[item.title].indentifier] = {
                    title: item.title,
                    url: `${mediaHost}/${item.url}`,
                    name: item.image_name
                };
            });
            parsedResponse.data.detail.classified.media = images;
        }
    }
    return parsedResponse;
};
