import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { staticAssetUrl } from "../../../utils/static-assets-url";

export const OG_IMAGE_TYPES = {
    autopilot: "autopilot",
    buycar: "buycar",
    carloan: "carloan",
    challan: "challan",
    fourdoor: "fourdoor",
    pdi: "pdi",
    rto: "rto",
    scrap: "scrap",
    sellcar: "sellcar",
    servicehistory: "servicehistory",
    superapp: "superapp",
    testimonials: "testimonials"
};

export const imagesMap = {
    autopilot: [
        { path: "thumbnails/1-1/autopilot.png" },
        { path: "thumbnails/1-91-1/autopilot.png" },
        { path: "thumbnails/16-9/autopilot.png" }
    ],
    buycar: [
        { path: "thumbnails/1-1/buy-car.png" },
        { path: "thumbnails/1-91-1/buy-car.png" },
        { path: "thumbnails/16-9/buy-car.png" }
    ],
    carloan: [
        { path: "thumbnails/1-1/carloan.png" },
        { path: "thumbnails/1-91-1/carloan.png" },
        { path: "thumbnails/16-9/carloan.png" }
    ],
    challan: [
        { path: "thumbnails/1-1/challan.png" },
        { path: "thumbnails/1-91-1/challan.png" },
        { path: "thumbnails/16-9/challan.png" }
    ],
    fourdoor: [
        { path: "thumbnails/1-1/fourdoor.png" },
        { path: "thumbnails/1-91-1/fourdoor.png" },
        { path: "thumbnails/16-9/fourdoor.png" }
    ],
    pdi: [
        { path: "thumbnails/1-1/pdi.png" },
        { path: "thumbnails/1-91-1/pdi.png" },
        { path: "thumbnails/16-9/pdi.png" }
    ],
    rto: [
        { path: "thumbnails/1-1/rto.png" },
        { path: "thumbnails/1-91-1/rto.png" },
        { path: "thumbnails/16-9/rto.png" }
    ],
    scrap: [
        { path: "thumbnails/1-1/scrap.png" },
        { path: "thumbnails/1-91-1/scrap.png" },
        { path: "thumbnails/16-9/scrap.png" }
    ],
    sellcar: [
        { path: "thumbnails/1-1/sellcar.png" },
        { path: "thumbnails/1-91-1/sellcar.png" },
        { path: "thumbnails/16-9/sellcar.png" }
    ],
    servicehistory: [
        { path: "thumbnails/1-1/servicehistory.png" },
        { path: "thumbnails/1-91-1/servicehistory.png" },
        { path: "thumbnails/16-9/servicehistory.png" }
    ],
    superapp: [
        { path: "thumbnails/1-1/superapp.png" },
        { path: "thumbnails/1-91-1/superapp.png" },
        { path: "thumbnails/16-9/superapp.png" }
    ],
    testimonials: [
        { path: "thumbnails/1-1/testimonials.png" },
        { path: "thumbnails/1-91-1/testimonials.png" },
        { path: "thumbnails/16-9/testimonials.png" }
    ]
};

export const OGImage = ({ type }) => {
    const thumbnails = imagesMap[type] || [];
    return (
        <Helmet>
            {thumbnails.map((item, key) => (
                <meta
                    key={key}
                    property="og:image"
                    content={staticAssetUrl(item.path)}
                />
            ))}
        </Helmet>
    );
};

OGImage.propTypes = {
    type: PropTypes.string
};
