import { appUrl } from "./url-constants";

const origin = appUrl();

// const origin = "http://localhost:3000";
const PAYMENT_MODES = {
    PAYTM: "PAYTM",
    RAZORPAY: "razorpay",
    JUSPAY: "juspay"
};

const PAYTM = {
    TRANSACTION: {
        URL: process.env.PAYTM_TXN_URL
    },
    CHECKSUM_CONFIG: {
        CALLBACK_URL: `${origin}/payment/confirmation/?token={token}&gateway=${PAYMENT_MODES.PAYTM}&type={type}`,
        MID: process.env.PAYTM_MID,
        WEBSITE: process.env.PAYTM_WEBSITE,
        INDUSTRY_TYPE_ID: process.env.PAYTM_INDUSTRY_TYPE_ID,
        CHANNEL_ID: "WEB"
    }
};

const RAZORPAY = {
    TRANSACTION: {
        URL: process.env.RAZORPAY_CHECKOUT_URL
    },
    CHECKSUM_CONFIG: {
        key_id: process.env.RAZORPAY_KEY_ID,
        callback_url: `${origin}/payment/confirmation/?token={token}&gateway=${PAYMENT_MODES.RAZORPAY}&type={type}&orderId={orderId}`,
        cancel_url: `${origin}/appointment/index/?token={token}`
    }
};
const RAZORPAY_C2C = {
    TRANSACTION: {
        URL: process.env.RAZORPAY_CHECKOUT_URL
        // URL: "https://api.razorpay.com/v1/checkout/embedded"
    },
    CHECKSUM_CONFIG: {
        key_id: process.env.RAZORPAY_C2C_KEY_ID,
        // key_id: "rzp_test_71YTuZEsp5S8xF",
        callback_url: `${origin}/payment/confirmation/?token={token}&gateway=${PAYMENT_MODES.RAZORPAY}&type={type}&orderId={orderId}`,
        cancel_url: `/`
    }
};

const RAZORPAY_CHECKOUT = {
    TRANSACTION: {
        URL: process.env.RAZORPAY_CHECKOUT_URL
        // URL: "https://api.razorpay.com/v1/checkout/embedded"
    },
    CHECKSUM_CONFIG: {
        key_id: process.env.RAZORPAY_KONNECT_KEY_ID,
        // key_id: "rzp_test_71YTuZEsp5S8xF",
        callback_url: `${origin}/payment/confirmation/?token={token}&gateway=${PAYMENT_MODES.RAZORPAY}&type={type}&orderId={orderId}`,
        cancel_url: `/`
    }
};

const RAZORPAY_C2B_VAS = {
    TRANSACTION: {
        URL: process.env.RAZORPAY_CHECKOUT_URL
        // URL: "https://api.razorpay.com/v1/checkout/embedded"
    },
    CHECKSUM_CONFIG: {
        key_id: process.env.RAZORPAY_C2B_VAS_KEY_ID,
        // key_id: "rzp_test_iQ92yj2aV1NIN7",
        callback_url: `${origin}/payment/confirmation/?token={token}&gateway=${PAYMENT_MODES.RAZORPAY}&type={type}&orderId={orderId}`,
        cancel_url: `${origin}/my-challans/{token}/checkout`
    }
};

const RAZORPAY_CAR_WASH = {
    TRANSACTION: {
        URL: process.env.RAZORPAY_CHECKOUT_URL
        // URL: "https://api.razorpay.com/v1/checkout/embedded"
    },
    CHECKSUM_CONFIG: {
        key_id: process.env.RAZORPAY_C2B_VAS_KEY_ID,
        // key_id: "rzp_test_iQ92yj2aV1NIN7",
        callback_url: `${origin}/payment/confirmation/?token={token}&gateway=${PAYMENT_MODES.RAZORPAY}&type={type}&orderId={orderId}`,
        cancel_url: `${origin}/book-car-wash/{token}`
    }
};

const RAZORPAY_SOURCE_MAP = {
    DEFAULT: RAZORPAY,
    C2C: RAZORPAY_C2C,
    KONNECT: RAZORPAY_CHECKOUT,
    C2B_VAS: RAZORPAY_C2B_VAS,
    CAR_WASH: RAZORPAY_CAR_WASH
};

const PAYMENT_STATUS_VALUES = {
    PAYMENT_INITIATED: "PAYMENT_INITIATED",
    PAYMENT_DONE: "PAYMENT_DONE",
    PAYMENT_FAILED: "PAYMENT_FAILED",
    RETRY: "RETRY",
    PAYMENT_COMPLETED: "PAYMENT_COMPLETED",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
    PENDING: "PENDING",
    INITIATED: "INITIATED",
    CANCELLED: "CANCELLED"
};

const PAYMENT_STATUS_CODES = {
    [PAYMENT_STATUS_VALUES.PAYMENT_DONE]: "1",
    [PAYMENT_STATUS_VALUES.PAYMENT_COMPLETED]: "1",
    [PAYMENT_STATUS_VALUES.PAYMENT_INITIATED]: "2",
    [PAYMENT_STATUS_VALUES.PAYMENT_FAILED]: "3",
    [PAYMENT_STATUS_VALUES.RETRY]: "3",
    [PAYMENT_STATUS_VALUES.SUCCESS]: "1",
    [PAYMENT_STATUS_VALUES.PENDING]: "4",
    [PAYMENT_STATUS_VALUES.INITIATED]: "2",
    [PAYMENT_STATUS_VALUES.FAILED]: "3",
    [PAYMENT_STATUS_VALUES.CANCELLED]: "5"
};

const PAYMENT_SOURCE = {
    MAKE_OFFER_PAYWALL: "MAKE_OFFER_PAYWALL",
    BOOK_NOW_PAYWALL: "BOOK_NOW_PAYWALL",
    GS_BOOK_NOW: "GS_BOOK_NOW",
    TOKEN_PAYMENT: "TOKEN_PAYMENT",
    CHECKOUT: "CHECKOUT",
    IN_APP_PAYMENT: "IN_APP_PAYMENT"
};

const RAZORPAY_SCRIPT_URL = "https://checkout.razorpay.com/v1/checkout.js";

const JUSPAY_SCRIPT_URL = "https://payments.juspay.in/web/PIL.js";

const juspaySdk = () => {
    const sdk = {
        LOCAL: "SANDBOX",
        QA: "SANDBOX",
        STAGING: "SANDBOX",
        PRODUCTION: "1"
    };
    return sdk[process.env.HOST_ENV];
};

const RAZORPAY_PAYMENT_GATEWAY_TYPES = {
    STANDARD_CHECKOUT: "STANDARD_CHECKOUT",
    HOSTED_CHECKOUT: "HOSTED_CHECKOUT"
};

export {
    PAYTM,
    PAYMENT_MODES,
    RAZORPAY,
    RAZORPAY_C2C,
    RAZORPAY_SOURCE_MAP,
    PAYMENT_STATUS_VALUES,
    PAYMENT_STATUS_CODES,
    PAYMENT_SOURCE,
    RAZORPAY_CHECKOUT,
    RAZORPAY_SCRIPT_URL,
    JUSPAY_SCRIPT_URL,
    RAZORPAY_PAYMENT_GATEWAY_TYPES,
    juspaySdk
};
