import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

const BlueCard = ({ children }) => {
    return (
        <div styleName={"styles.blueCardOuter"}>
            {children}
        </div>
    );
};
BlueCard.propTypes = {
    children: PropTypes.any
};

export default BlueCard;
