import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_CONFIRMATION_FLAG
    SKIP_CITY_SELECTION
    UPDATE_LEAD_SOURCE
    SET_DEALER_CODE
`,
    {
        prefix: "settings/"
    }
);
