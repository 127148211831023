export const CLASSIFIED_IMAGE_TITLE_MAP = {
    front: "Front Main",
    frontRight: "Front Right Side",
    rear: "Rear Main",
    rearRight: "Rear Right Side",
    rcCopy: "RC Copy Front",
    rcBackCopy: "RC Copy Back",
    frontDashboard: "Front Dashboard",
    centreConsole: "Centre Console"
};

export const CLASSIFIED_IMAGE_PAYLOAD_MAP = {
    [CLASSIFIED_IMAGE_TITLE_MAP.front]: {
        indentifier: "front",
        payload: "front_view_image"
    },
    [CLASSIFIED_IMAGE_TITLE_MAP.frontRight]: {
        indentifier: "frontRight",
        payload: "front_right_image"
    },
    [CLASSIFIED_IMAGE_TITLE_MAP.rear]: {
        indentifier: "rear",
        payload: "rear_image"
    },
    [CLASSIFIED_IMAGE_TITLE_MAP.rearRight]: {
        indentifier: "rearRight",
        payload: "rear_right_image"
    },
    [CLASSIFIED_IMAGE_TITLE_MAP.frontDashboard]: {
        indentifier: "frontDashboard",
        payload: "front_dashboard"
    },
    [CLASSIFIED_IMAGE_TITLE_MAP.centreConsole]: {
        indentifier: "centreConsole",
        payload: "centre_console"
    }
};
