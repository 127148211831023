import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        FETCH_DATA
        FETCH_LEAD_BRAND_SUCCESS
        FETCH_LEAD_BRAND_FAILURE
        UPDATE_LEAD_BRAND
        SET_IS_LOADING
    `,
    {
        prefix: "leadbrands/"
    }
);
